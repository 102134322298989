<template>
<h1>Административные действия</h1>
  <div id="divSpinner"  class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>


  <div class="row mt-2">
    <div class="col-auto">
      <a @click="updateRReportRows" href="#" class="btn btn-primary mx-1">Обновить еженедельные отчеты</a>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <a @click="updateSubjectsWb" href="#" class="btn btn-secondary mx-1">Синхронизовать предметы (категории) ВБ</a>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <a @click="updateWbSearchRegion" href="#" class="btn btn-success mx-1">Обновить регионы, по которым снимаем позиции</a>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <a @click="updateWbWH" href="#" class="btn btn-info mx-1">Заполнить список складов ВБ</a>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <a @click="updateWbPositions" href="#" class="btn btn-warning mx-1">Снять ВСЕ позиции прямо сейчас</a>
    </div>
  </div>

</template>

<script>
import {fetchDrf, showMessageResult, showSpinner, hideSpinner}  from "@/common";

export default {
  name: "WbAdmin",

  data() {
    return {
      divSpinner: null,
      messageResult: "",
      gotError: false,
    }
  },

  methods: {
    fetchDrf,
    showMessageResult,
    showSpinner,
    hideSpinner,

    async updateRReportRows(){
      // this.showSpinner(this)

      console.log("Запускаем задачу")

      let res = await fetchDrf(
         `/wb-own-rreport-row-update/`,
         "POST",
         {},
         "Успешно запустили задачу обновления отчетов"
      )

      console.log("Запустили")

      // this.hideSpinner(this)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError
    },


    async updateSubjectsWb(){
      this.showSpinner(this)

      let res = await fetchDrf(`/wb-subject-parsed/`,
                               "POST",
                               {},
                               "Успешно синхронизировали"
                              )

      this.hideSpinner(this)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError
    },

    async updateWbSearchRegion(){
      this.showSpinner(this)

      let res = await fetchDrf(`/wb-search-region/`,
                               "POST",
                               {},
                               "Успешно обновили регионы"
                              )

      this.hideSpinner(this)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError
    },

    async updateWbPositions(){
      this.showSpinner(this)

      let res = await fetchDrf(`/wb-search-position/`,
                               "POST",
                               {},
                               "Успешно получили позиции"
                              )

      this.hideSpinner(this)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;
    },

    async updateWbWH(){

      let res = await fetchDrf(`/wb-wh/`,
                               "POST",
                               {},
                               "Успешно заполнили список складов"
                              )

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;

      console.log("Заполнили склады")
    },


  },

  created(){
    document.title = "Административные действия"
  },
}
</script>

<style scoped>

</style>