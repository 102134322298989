<template>
  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'WbSearchProductList'}" class="btn btn-info mt-1">← вернуться</router-link>
    </div>
  </div>

  <h5 v-if="id != 0">{{ name }} </h5>

  <div id="divSpinner" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="id == 0" class="row g-3 align-items-center mt-3">
    <div class="col-auto">
      <label for="inpNmId">Артикул ВБ: </label>
    </div>
    <div class="col-auto">
      <input v-model="nm_id" type="text" class="form-control" id="inpNmId">
    </div>
    <div class="col-auto">
      <button @click="addProduct" type="button" class="btn btn-primary">
        Добавить товар
      </button>
    </div>
  </div>

  <div v-if="id != 0" class="row g-3 align-top">
    <div class="col-1">
      <img class="w-100" :src="foto_link"/>
    </div>
    <div class="col-6">
      <span class="fw-bold">Артикул:</span> {{ nm_id }}
      <button v-if="id != 0" @click="updateProduct" type="button" class="btn btn-info btn-sm" title="Если на сайте ВБ вы обновили название этого товара - автоматически обновите название в нашей базе данных">
        ↻ Обновить название
      </button>

      <br>
      <span class="fw-bold">Бренд:</span> {{ brand }}
      <br>
      <span class="fw-bold">Предмет: </span>{{ subject_name }} ({{ subject_wb_id }})
    </div>
  </div>

  <div v-if="id != 0" class="row g-3 align-top">
    <div class="col-3">
      <div class="form-group mt-3">
        <label for="textareaKeywords">Ключи</label>
        <textarea v-model="textAreaKeywords" class="form-control" id="textareaKeywords" rows="5"></textarea>
      </div>

      <div class="row my-3">
        <div class="col-auto">
          <button @click="updateKeywords" type="button" class="btn btn-primary">Обновить ключи</button>
        </div>
        <div class="col-auto">
          <router-link :to="{name: 'WbSearchProductList'}" class="btn btn-warning">Отмена</router-link>
        </div>

        <div class="col-auto">
          <router-link :to="{ name: 'WbSearchProductAnalytics', params: { id: id }}" class="btn btn-info">Аналитика товара</router-link>
        </div>

      </div>

    </div>
  </div>

  <div v-if="messageResult" class="col-5 mt-3 alert " :class="gotError ? 'alert-danger' : 'alert-success'">
    {{ messageResult }}
  </div>


</template>

<script>
import fetchDrf from "@/common";
import showMessageResult from "@/common";
import {Modal} from "bootstrap";

export default {
  name: "WbSearchProductEdit",
  props: ['id'],
  data() {
    return {
      nm_id: "",
      name: "",
      brand: "",
      subject: 0,
      subject_name: "",
      foto_link: "",
      divSpinner: null,
      textAreaKeywords: "",
      messageResult: "",
      gotError: false
    }
  },

  created() {
    if (this.id != 0){
      this.loadProduct();
      this.loadKeywords();
    }
  },

  methods: {
    showSpinner() {
      this.divSpinner = new Modal(document.getElementById('divSpinner'))
      this.divSpinner.show()
    },

    hideSpinner() {
      this.divSpinner.hide()
    },

    async loadProduct() {
      let res = await fetchDrf(`/wb-search-product/${this.id}/`)

      if (!res.gotError) {
        this.nm_id = res.data["nm_id"]
        this.name = res.data["name"]

        this.brand = res.data["brand"]
        this.subject_wb_id = res.data["subject_wb_id"]
        this.subject_name = res.data["subject_name"]
        this.foto_link = res.data["foto_link"]
      }
      else {
        showMessageResult(res, this)
      }
    },

    async addProduct() {
      let jsonBody = {nm_id: this.nm_id
                     }

      this.showSpinner();
      let res = await fetchDrf(
          `/wb-search-product/`,
          "POST",
          jsonBody,
          "Успешно добавили товар"
      );
      this.hideSpinner();

      if (!res.gotError){
        this.nm_id = res.data["nm_id"]
        this.name = res.data["name"]

        this.brand = res.data["brand"]
        this.subject_wb_id = res.data["subject_wb_id"]
        this.subject_name = res.data["subject_name"]
        this.foto_link = res.data["foto_link"]

        this.$router.push({name: 'WbSearchProductEdit', params: {id: res.data["id"]}})
      }
      else {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError
      }
    },


    async updateProduct() {
      this.showSpinner();
      let res = await fetchDrf(
          `/wb-search-product/${this.id}/`,
          "PUT",
          {},
          "Успешно синхронизовали товар"
      );
      this.hideSpinner();

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;

      await this.loadProduct();
    },


    async loadKeywords() {
      let res = await fetchDrf(`/wb-search-product-keywords/${this.id}/`)

      if (!res.gotError) {
        let keywordsArray = res.data["keywords"];
        this.textAreaKeywords = keywordsArray.map(elem => elem["name"]).join("\n");
      } else {
        showMessageResult(res, this)
      }
    },

    async updateKeywords() {
      let jsonBody = {};
      jsonBody.id = this.id;

      let keywordsFromTextArea = this.textAreaKeywords.split(/\n|\r/);
      let keywords = Array();

      for (let i = 0; i < keywordsFromTextArea.length; i++) {
        let new_item = {};
        new_item.name = keywordsFromTextArea[i];
        keywords.push(new_item);
      }

      jsonBody.keywords = keywords;

      let res = await fetchDrf(`/wb-search-product-keywords/${this.id}/`,
          "PUT",
          jsonBody,
          "Успешно обновили ключи"
      );

        if (!res.gotError){
          this.$router.push({name: 'WbSearchProductList'})
        }

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;

      await this.loadKeywords();
    },
  }
}
</script>

<style scoped>

</style>