<template>
 <h1>Еженедельные финансовые отчеты WB</h1>

  <MessageResultDiv :messageResult="messageResult" :gotError="gotError" />
  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div id="divImportWindow"  class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">Импорт файла</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideImportWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <ol>
                <li>Перейдите в список еженедельных отчетов ВБ по <a target="_blank" href="https://seller.wildberries.ru/suppliers-mutual-settlements/reports-implementations/reports-weekly">ссылке</a> </li>
                <li>Щелкните по кнопке "Скачать excel" справа над таблицей чтобы скачать файл со списком отчетов</li>
                <li>Откройте этот файл на компьютере и <b>пересохраните</b> под любым другим именем (у ВБ по умолчанию некорректный формат файла)</li>
                <li>Нажмите на кнопку "Выбрать файл" и найдите скачанный файл</li>
                <li>Нажмите на кнопку "Загрузить"</li>
              </ol>
              Обращаем внимание, что через файл импортируются не сами отчеты, а только вспомогательные данные по стоимости хранения, удержания и т.д.
              Сами отчеты импортируются автоматически по API
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-auto">
              <input type="file" class="form-control" ref="inputFileImport" />
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-primary" @click="onImportFileClick" >Загрузить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-auto mt-2">
      <label for="inputDateStart" class="col-form-label">Дата формирования с </label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control">
    </div>

    <div class="col-sm-auto mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control">
    </div>

    <div class="col-sm-auto mt-2">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Быстрый фильтр по ID"
               aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X
        </button>
      </div>
    </div>

    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedBrand" @change="selectChange" data-bs-toggle="tooltip" title="Фильтр по бренду">
        <option value="" selected="selected">
          Все бренды
        </option>

        <option v-for="brand in brands" :value="brand.id">
          {{ brand.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedSubject" @change="selectChange" data-bs-toggle="tooltip" title="Фильтр по предмету">
        <option value="" selected="selected">
          Все предметы
        </option>
        <option v-for="subject in subjects" :value="subject.id">
          {{ subject.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedProduct" @change="selectChange" data-bs-toggle="tooltip" title="Фильтр по товару">
        <option selected="selected" value="">
          Все товары
        </option>
        <option v-for="product in products" :value="product.id" :title="product.name + ' / '+ product.color">
          {{ product.nm_id }} {{product.name.slice(0,10)}}...
        </option>
      </select>
    </div>


    <div class="col-sm-auto mt-2">
      <button
          @click="showImportWindow"
          type="button"
          class="btn btn-primary"
          title="Стоимость хранения, приемки, прочих удержаний из файла"
          data-bs-toggle="tooltip"
      >Импортировать файл со стоимостью хранения из ВБ
      </button>
    </div>

  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <universal-table
        :items="filteredRows"
        :allHeaders="allColumns"
        :localStorageHeadersName="'WbOwnRReportList_headers'"
        :arrTable="arrTable"
        :showTotalRow="true"
        tableClass="table-striped table-bordered border-light table-sm table-hover"
        @data-updated="loadRReports"
      >
      </universal-table>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <template v-if="!arrTable.length">
        <div v-if="tokensAreFilled" class="alert alert-success" role="alert">
          Немного подождите. Отчеты автоматически подгружаются с ВБ примерно раз в час.
          Вы уже заполнили токены статистики и контента <router-link :to="{ name: 'WbLkList'}" target="_blank">на странице аккаунтов ВБ</router-link>.
          Через некоторое время обновите страницу чтобы увидеть загруженные отчеты
        </div>
      <div v-else class="alert alert-danger" role="alert">
        Добавьте новый аккаунт ВБ и заполните токены статистики и контента для него <router-link :to="{ name: 'WbLkList'}" target="_blank">на странице аккаунтов ВБ</router-link>.
        Отчеты начнут автоматически подгружаться с ВБ примерно раз в час
      </div>

      </template>

    </div>
  </div>


</template>

<script>
  import {
    fetchDrf,
    prepareCell,
  } from "@/common";

  import {
    loadWblksFromDrf,
  } from "@/service_layer";

  import UniversalTable from "@/components/UniversalTable";
  import Toast from "@/components/Toast";
  import MessageResultDiv from "@/components/MessageResultDiv";

  import {Modal, Tooltip} from 'bootstrap'

  // import vSelect from 'vue-select'

export default {
  name: "WbOwnRReportList",

  components: {
    UniversalTable,
    MessageResultDiv,
    Toast,
    // vSelect,
  },

  data() {
    return {
      arrTable:[],
      allColumns: [],
      wblks: [],

      brands: [],
      subjects: [],
      products: [],

      dateStart: "",
      dateEnd: "",

      filterString: "",

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      selectedBrand: "",
      selectedSubject: "",
      selectedProduct: "",

      COL_WB_ID: 'wb_id',

      COL_DATE_FROM: 'date_from',
      COL_DATE_TO: 'date_to',
      COL_DATE_CREATE: 'date_create',

      COL_SALES_AND_COMP_DEFECTS_WITH_DISC: 'sum_sales_and_comp_defects_with_disc',
      COL_RETURNS_WITH_DISC: 'sum_returns_with_disc',
      COL_SALES_FOR_PAY: 'sum_sales_for_pay',
      COL_RETURNS_FOR_PAY: 'sum_returns_for_pay',

      COL_SUM_COMISSION_WB: 'sum_comission_wb',
      COL_PERCENT_COMISSION_WB: 'percent_comission_wb',

      COL_QUANTITY_SALES: 'quantity_sales',
      COL_QUANTITY_RETURNS: 'quantity_returns',

      COL_SUM_COMP_DEFECTS: 'sum_comp_defects',
      COL_SUM_COMP_LOST_PRODUCTS: 'sum_comp_lost_products',
      COL_SUM_COMP_TRANSPORT_COST: 'sum_comp_transport_cost',
      COL_SUM_PAYMENT_FOR_PRODUCTS: 'sum_payment_for_products',
      COL_SUM_SALES_AND_COMP_DEFECTS_MINUS_RETURNS_REAL: 'sum_sales_and_comp_defects_minus_returns_real',

      COL_SUM_PRODUCTS_COST: 'sum_products_cost',
      COL_PERCENT_PRODUCTS_COST: 'percent_products_cost',
      COL_PERCENT_PROFIT: 'percent_profit',
      COL_SUM_COST_PRICE_SALES: 'sum_cost_price_sales',
      COL_SUM_COST_PRICE_RETURNS: 'sum_cost_price_returns',

      COL_SUM_LOGISTICS: 'sum_logistics',
      COL_SUM_PENALTY: 'sum_penalty',
      COL_SUM_ADDITIONAL_PAYMENT: 'sum_additional_payment',
      COL_SUM_GROSS_PROFIT: 'sum_gross_profit',
      COL_SUM_PAYMENT_TOTAL: 'sum_payment_total',

      COL_SUM_TAXES: 'sum_taxes',
      COL_SUM_PROFIT: 'sum_profit',

      COL_PERCENT_ROI: 'percent_roi',
      COL_PERCENT_GROSS_ROI: 'percent_gross_roi',

      SUM_FEE_STORAGE: 'sum_fee_storage',
      SUM_FEE_PAID_RECEIVING: 'sum_fee_paid_receiving',
      SUM_FEE_OTHER: 'sum_fee_other',

      COL_SUM_ADV_COST: 'sum_adv_cost',
      COL_PERCENT_ADV_COST: 'percent_adv_cost',

      messageResult: "",
      gotError: false,
    }
  },
  async created() {
    this.allColumns.push({id: this.COL_WB_ID, name: "ID", type: "wb_own_rreport_id", hint: "ID отчета из WB", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})

    this.allColumns.push({id: this.COL_DATE_FROM, name: "Дата начала", type:"date"})
    this.allColumns.push({id: this.COL_DATE_TO, name: "Дата конца", type:"date"})
    this.allColumns.push({id: this.COL_DATE_CREATE, name: "Дата формирования", type:"date", active: true})

    this.allColumns.push({id: this.COL_SALES_AND_COMP_DEFECTS_WITH_DISC, name: "Продажи до вычета комиссии", type:"int", totalRow: true, active: true}) // type:"float"
    this.allColumns.push({id: this.COL_RETURNS_WITH_DISC, name: "Возвраты до вычета комиссии", type:"int", totalRow: true, active: true})

    this.allColumns.push({id: this.COL_SALES_FOR_PAY, name: "Продажи после вычета комиссии", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_RETURNS_FOR_PAY, name: "Возвраты после вычета комиссии", type:"int", totalRow: true}) // type:"float"

    this.allColumns.push({id: this.COL_SUM_COMISSION_WB, name: "Комиссия", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_PERCENT_COMISSION_WB, name: "% комиссии", type:"int"}) // type:"float"

    this.allColumns.push({id: this.COL_QUANTITY_SALES, name: "Продажи, шт.", type: "int", totalRow: true, active: true})
    this.allColumns.push({id: this.COL_QUANTITY_RETURNS, name: "Возвраты, шт.", type: "int", totalRow: true, active: true})

    this.allColumns.push({id: this.COL_SUM_COMP_DEFECTS, name: "Оплата брака", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_COMP_LOST_PRODUCTS, name: "Оплата потерянного товара", type: "int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_COMP_TRANSPORT_COST, name: "Возмещение издержек по перевозке", type: "int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_PAYMENT_FOR_PRODUCTS, name: "К перечислению за товар", type:"int", totalRow: true, hint: "[Продажи после вычета комиссии] + [Оплата брака] - [Возвраты после вычета комиссии]"}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_SALES_AND_COMP_DEFECTS_MINUS_RETURNS_REAL, name: "Продано ВБ", type:"int", totalRow: true, hint: "Фактическая сумма, которая была списана с карт покупателей", active: true}) // type:"float"

    this.allColumns.push({id: this.COL_SUM_PRODUCTS_COST, name: "Себестоимость товара", type:"int", totalRow: true, hint: "[Себестоимость товара продажи] + [Себестоимость брака] - [Себестоимость товара возвраты]"}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_COST_PRICE_SALES, name: "Себестоимость товара продажи", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_COST_PRICE_RETURNS, name: "Себестоимость товара возвраты", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_PERCENT_PRODUCTS_COST, name: "% себестоимости товара", type:"int", hint: "[Себестоимость товара] / ( [Продажи до вычета комиссии] - [Возвраты до вычета комиссии] )"}) // type:"float"

    this.allColumns.push({id: this.COL_SUM_LOGISTICS, name: "Логистика", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_PENALTY, name: "Штрафы", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_ADDITIONAL_PAYMENT, name: "Доплаты", type:"int", totalRow: true}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_PAYMENT_TOTAL, name: "Итого к оплате", type:"int", totalRow: true, hint: "[К перечислению за товар] - [Логистика] - [Штрафы] + [Доплаты] - [Хранение] - [Платная приемка] - Прочие удержания"}) // type:"float"

    this.allColumns.push({id: this.COL_SUM_GROSS_PROFIT, name: "Валовая прибыль", type:"int", totalRow: true, hint: "[Итого к оплате] - [Себестоимость товара]"}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_TAXES, name: "Налоги", type:"int", totalRow: true, hint: "[К перечислению за товар] * [Налоговая ставка]"}) // type:"float"
    this.allColumns.push({id: this.COL_SUM_PROFIT, name: "Итоговая прибыль", type:"int", totalRow: true, hint: "[Валовая прибыль] - [Налоги]", active: true}) // type:"float"

    this.allColumns.push({id: this.COL_PERCENT_GROSS_ROI, name: "Валовая рентабельность, %", type:"int", hint: "[Валовая прибыль] / [Себестоимость товара]"}) // type:"float"
    this.allColumns.push({id: this.COL_PERCENT_ROI, name: "Рентабельность вложений, %", type:"int", hint: "[Итоговая прибыль] / [Себестоимость товара]", active: true}) // type:"float"
    this.allColumns.push({id: this.COL_PERCENT_PROFIT, name: "Норма прибыли, %", type:"int", hint: "[Итоговая прибыль] / ( [Продажи до вычета комиссии] - [Возвраты до вычета комиссии] )", active: true}) // type:"float"

    this.allColumns.push({id: this.SUM_FEE_STORAGE, name: "Хранение", type:"int", totalRow: true, active: true, need_fill: true, mark_not_null: true, editable_cell_path: "wb-own-rreport"}) // type:"float"
    this.allColumns.push({id: this.SUM_FEE_PAID_RECEIVING, name: "Платная приемка", type:"int", totalRow: true, active: true, need_fill: true, mark_not_null: true, editable_cell_path: "wb-own-rreport"}) // type:"float"
    this.allColumns.push({id: this.SUM_FEE_OTHER, name: "Прочие удержания", type:"int", totalRow: true, active: true, need_fill: true, mark_not_null: true, editable_cell_path: "wb-own-rreport"}) // type:"float"

    this.allColumns.push({id: this.COL_SUM_ADV_COST, name: "Стоимость рекламы", type:"int", hint: "Сколько потрачено на рекламу", active: true, totalRow: true})
    this.allColumns.push({id: this.COL_PERCENT_ADV_COST, name: "ДРР", type:"int", hint: "[Стоимость рекламы] / ( [Продажи до вычета комиссии] - [Возвраты до вычета комиссии] )", active: true})

    await this.restoreOptions();
    await this.loadRReports();
    await this.loadWblks();

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    document.title = "Еженедельные финансовые отчеты WB"
  },

  computed: {
    tokensAreFilled() {
      let res = false

      for (let i = 0; i < this.wblks.length; i++){
        if (this.wblks[i].token_stat && this.wblks[i].token_content){
          res = true
        }
      }
      return res
    },

    filteredRows() {
      return this.arrTable.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        if (!row[this.COL_WB_ID]) return false

        const rreport_id = row[this.COL_WB_ID].toString().toLowerCase();

        let bTextFiltersOk = false

        if ( rreport_id.includes(filterString) ){
          bTextFiltersOk = true
        }

        let bDatesFilterOk = true

        if (this.dateStart && this.dateStart > row["date_create"])  bDatesFilterOk = false
        if (this.dateEnd && this.dateEnd < row["date_create"])  bDatesFilterOk = false

        return bTextFiltersOk && bDatesFilterOk
      });
    }
  },
  methods: {
    fetchDrf,
    prepareCell,
    loadWblksFromDrf,

    showImportWindow(){
      this.divImportWindow = new Modal(document.getElementById('divImportWindow'))
      this.divImportWindow.show()
    },

    hideImportWindow(){
      this.divImportWindow.hide()
    },

    showToast(toastMessage, gotError) {
      this.toastMessage = toastMessage
      this.toastIsShowed = true

      setTimeout(() => {
        this.toastIsShowed = false
      }, this.toastDelay)

      // Toast.methods.showToast()
    },

    importFeesFromExcel(){

    },

    async selectChange(){
      await this.loadRReports()
    },


    prepareTable() {
      for (let i = 0; i < this.arrTable.length; i++) {
        for (var key in this.arrTable[i]["fin_metrics"]) {
          this.arrTable[i][key] = this.arrTable[i]["fin_metrics"][key]
        }
      }

      this.brands = []

      let arrAllBrandsId = []
      for (let i = 0; i < this.arrTable.length; i++) {
        for (let j = 0; j < this.arrTable[i]["brands"].length; j++) {

          if (arrAllBrandsId.includes(this.arrTable[i]["brands"][j]["id"])){
            continue
          }
          else {
            arrAllBrandsId.push(this.arrTable[i]["brands"][j]["id"])
            this.brands.push(this.arrTable[i]["brands"][j])
          }
        }
      }

      this.subjects = []

      let arrAllSubjectsId = []
      for (let i = 0; i < this.arrTable.length; i++) {
        for (let j = 0; j < this.arrTable[i]["subjects"].length; j++) {

          if (arrAllSubjectsId.includes(this.arrTable[i]["subjects"][j]["id"])){
            continue
          }
          else {
            arrAllSubjectsId.push(this.arrTable[i]["subjects"][j]["id"])
            this.subjects.push(this.arrTable[i]["subjects"][j])
          }
        }
      }

      this.products = []

      let arrAllProductsId = []
      for (let i = 0; i < this.arrTable.length; i++) {
        for (let j = 0; j < this.arrTable[i]["products"].length; j++) {
          if (arrAllProductsId.includes(this.arrTable[i]["products"][j]["id"])){
            continue
          }
          else {
            arrAllProductsId.push(this.arrTable[i]["products"][j]["id"])
            this.products.push(this.arrTable[i]["products"][j])
          }
        }
      }


      // Для красивой выгрузки. Хотя, по-хорошему, нужно выгружать только отображаемое на экране
      for (let i = 0; i < this.arrTable.length; i++) {
       delete this.arrTable[i].fin_metrics
       delete this.arrTable[i].general_report
       delete this.arrTable[i].sales_report
       delete this.arrTable[i].returns_report
       delete this.arrTable[i].subjects
       delete this.arrTable[i].brands
      }

    },

    async loadRReports(){
      let searchParams = new URLSearchParams({
          brand: this.selectedBrand,
          subject: this.selectedSubject,
          product: this.selectedProduct,
      })

      let res = await fetchDrf(`/wb-own-rreport/?${searchParams}`)

      if (!res.gotError){
        this.arrTable = res.data;

        this.prepareTable()

      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },


    async loadWblks(){
      let res = await loadWblksFromDrf()

      if (!res.gotError){
        this.wblks = res.data;
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },


    restoreOptions(){
    },


    saveOptions(){
    },


    async onImportFileClick(event){
      const file = this.$refs.inputFileImport.files[0]

      if (!file){
        alert("Выберите файл")
        return
      }

      const formData = new FormData();
      formData.append('file_uploaded', file);

      let res = await fetchDrf(
        `/wb-own-rreport-fee-upload/`,
        "POST",
        formData,
        "Успешно загрузили файл"
        )

      this.hideImportWindow()

      this.showToast(res.messageResult, res.gotError)

      if (res.gotError){
        this.messageResult = res.messageResult;
        this.gotError = res.gotError
      }

      this.loadRReports()
    },

    speedUpLoadRReports(){
      console.log("speedUpLoadRReports")
    },
  },

}
</script>

<style scoped>
</style>