<template >
  <h1>Мои товары</h1>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"
       role="alert">
    {{ messageResult }}
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <label for="inputDateStart" class="col-form-label">Заказы с</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control" @change="changeOrdersDates">
    </div>

    <div class="col-sm-auto mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control" @change="changeOrdersDates">
    </div>

    <!-- -->

    <div class="col-sm-1 mt-2">
    </div>

    <div class="col-auto mt-2">
      <input id="cbShowWithStocksAndOrders" class="form-check-input align-middle me-1" type="checkbox" v-model="bShowWithStocksAndOrders" @change="saveOptions">
      <label for="cbShowWithStocksAndOrders"  class="col-form-label align-middle">С остатками и заказами</label>
    </div>

    <div class="col-auto mt-2">
      <input id="cbHideEmptyWh" class="form-check-input align-middle me-1" type="checkbox" v-model="bHideEmptyWh" @change="saveOptions">
      <label for="cbHideEmptyWh" class="col-form-label align-middle">Скрыть пустые склады</label>

<!--      <button class="btn btn-danger" @click="changeHeaders2"></button>-->

    </div>


    <div class="col-auto">
      <div class="input-group mt-2">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <universal-table
        :items="filteredRows"
        :allHeaders="allHeaders"
        :allHeaders2="allHeaders2"
        localStorageHeadersName="WbOwnProductList_headers"
        localStorageHeadersName2="WbOwnProductList_headers2"
        :arrTable="arrTable"
        :showTotalRow="true"
        tableClass="table-striped table-bordered border-secondary table-hover table-sm"

        ref="refUniversalTable"
      />
    </div>
  </div>

</template>

<script>
import {
  fetchDrf,
  showMessageResult,
  getDaysArray,
  prepareDate,
  prepareNumber,
  getWbUrlByNmId,
  sortAssocByValue,
  TYPE_COL_OWN_PRODUCT_ID,
  TYPE_COL_ORDER_DAY,
  TYPE_COL_CHART_ORDERS,
  TYPE_COL_CHART_ADV,
  TYPE_COL_CHART_WH_STOCKS,
  TYPE_COL_CHART_WH_ORDERS,
  TYPE_COL_BARCHART_WH_ORDERS,
  COL_ORDER_QUANTITY,
  COL_ORDER_SUM,
  COL_ORDER_HINT,
} from "@/common";
import UniversalTable from "@/components/UniversalTable";

export default {
  name: "WbOwnProductList",

  components: {
    UniversalTable
  },

  data() {
    return {
      arrTable: [],
      columns: [],
      allHeaders: [],
      allHeaders2: [],

      wh: [],
      dates: [],

      dateStart: "",
      dateEnd: "",
      arrDates: [],

      filterString: "",
      bShowFavoriteProducts: true,
      bShowEtcProducts: true,
      bShowArchiveProducts: true,
      bShowWithStocksAndOrders: true,
      bHideEmptyWh: true,

      COL_ID: 'id',
      COL_FOTO: 'foto_link',
      COL_WBLK: 'wblk',
      COL_WBLK_NAME: 'wblk_name',
      COL_ARTICLE: 'article',
      COL_ARTICLE_WB: 'nm_id',
      COL_NAME: 'name',
      COL_COLOR: 'color',
      COL_BARCODE: 'barcode',
      COL_SIZE: 'size',

      COL_COST: 'cost',
      COL_DELIVERY_COST: 'delivery_cost_or_default',
      COL_MIN_PRICE: 'min_price',
      COL_FINAL_PRICE: 'final_price',
      COL_PRICE: 'price',
      COL_DISCOUNT: 'discount',
      COL_DAYS_RESERVE: 'days_reserve',

      COL_QUANTITY_ORDERS_7_DAYS: 'quantity_orders_7_days',
      COL_QUANTITY_ORDERS_30_DAYS: 'quantity_orders_30_days',
      COL_QUANTITY_ORDERS_PERIOD: 'quantity_orders_period',

      COL_SUM_ORDERS_7_DAYS: 'sum_orders_7_days',
      COL_SUM_ORDERS_30_DAYS: 'sum_orders_30_days',
      COL_SUM_ORDERS_PERIOD: 'sum_orders_period',

      COL_QUANTITY_STOCKS: 'quantity_stocks',

      COL_FAVORITE: 'favorite',
      COL_HIDDEN: 'hidden',
      COL_ARCHIVE: 'archive',
      COL_PRICE_HINT: 'price_hint',
      COL_WH_STOCKS_HINT: 'wh_stocks_hint',
      COL_WH_ORDERS_PERIOD_HINT: 'wh_orders_period_hint',
      COL_WH: 'wh',

      COL_CHART_ORDERS: 'chart_orders',
      COL_CHART_ADV: 'chart_adv',
      COL_CHART_WH_STOCKS: 'chart_wh_stocks',
      COL_CHART_WH_ORDERS: 'chart_wh_orders',

      messageResult: "",
      gotError: false,
    }
  },


  async created() {
    document.title = "Мои товары"
    this.TYPE_COL_OWN_PRODUCT_ID = TYPE_COL_OWN_PRODUCT_ID
    this.TYPE_COL_ORDER_DAY = TYPE_COL_ORDER_DAY
    this.TYPE_COL_CHART_ORDERS = TYPE_COL_CHART_ORDERS
    this.TYPE_COL_BARCHART_WH_ORDERS = TYPE_COL_BARCHART_WH_ORDERS
    this.TYPE_COL_CHART_ADV = TYPE_COL_CHART_ADV
    this.TYPE_COL_CHART_WH_STOCKS = TYPE_COL_CHART_WH_STOCKS
    this.TYPE_COL_CHART_WH_ORDERS = TYPE_COL_CHART_WH_ORDERS

    this.COL_ORDER_QUANTITY = COL_ORDER_QUANTITY
    this.COL_ORDER_SUM = COL_ORDER_SUM
    this.COL_ORDER_HINT = COL_ORDER_HINT

    this.allHeaders.push({id: this.COL_HIDDEN, name: "Скрытый", type: "checkbox", checkbox_path: "wb-own-barcode", active: true})
    this.allHeaders.push({id: this.COL_FAVORITE, name: "Избр", type: "checkbox", checkbox_path: "wb-own-barcode", active: false})
    this.allHeaders.push({id: this.COL_ARCHIVE, name: "📥" , type: "checkbox", checkbox_path: "wb-own-barcode"})
    this.allHeaders.push({id: this.COL_ID, name: "ID"})
    this.allHeaders.push({id: this.COL_FOTO, name: "Фото", type: "foto", class: "position-sticky start-0 top-0 opacity-100 bg-white", active: true})
    this.allHeaders.push({id: this.COL_WBLK_NAME, name: "ЛК"})
    this.allHeaders.push({id: this.COL_ARTICLE, name: "Артикул", active: true}) //
    this.allHeaders.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", type: this.TYPE_COL_OWN_PRODUCT_ID, active: true})
    this.allHeaders.push({id: this.COL_NAME, name: "Название", active: true, title: "name" })

    this.allHeaders.push({id: this.COL_CHART_ORDERS, name: "График заказов", type: this.TYPE_COL_CHART_ORDERS, active: true})
    this.allHeaders.push({id: this.COL_BARCHART_WH_ORDERS, name: "График заказов по складам", type: this.TYPE_COL_BARCHART_WH_ORDERS, active: true})
    this.allHeaders.push({id: this.COL_CHART_ADV, name: "График показов рекламы", type: this.TYPE_COL_CHART_ADV, active: true})
    this.allHeaders.push({id: this.COL_CHART_WH_STOCKS, name: "Остатки <br/>по складам", type: this.TYPE_COL_CHART_WH_STOCKS, active: true})
    this.allHeaders.push({id: this.COL_CHART_WH_ORDERS, name: "Заказы <br/>по складам", type: this.TYPE_COL_CHART_WH_ORDERS, active: true})

    this.allHeaders.push({id: this.COL_SIZE, name: "Размер", active: true})
    this.allHeaders.push({id: this.COL_COLOR, name: "Цвет", active: true})
    this.allHeaders.push({id: this.COL_BARCODE, name: "Баркод", active: true})
    this.allHeaders.push({id: this.COL_COST, name: "Себестоимость", type: "int", active: true})
    // this.allHeaders.push({id: this.COL_DELIVERY_COST, name: "Логистика ВБ", type: "int"})
    // this.allHeaders.push({id: this.COL_MIN_PRICE, name: "Мин цена", type: "int"})
    this.allHeaders.push({id: this.COL_FINAL_PRICE, name: "Цена", type: "int", title: this.COL_PRICE_HINT})
    this.allHeaders.push({id: this.COL_PRICE, name: "Цена до скидки", type: "int", active: true})
    this.allHeaders.push({id: this.COL_DISCOUNT, name: "Скидка, %", type: "int", active: true})

    this.allHeaders.push({id: this.COL_QUANTITY_ORDERS_PERIOD, name: "Заказы за период", type: "order", totalRow: true, active: true, title: this.COL_WH_ORDERS_PERIOD_HINT})
    this.allHeaders.push({id: this.COL_QUANTITY_ORDERS_7_DAYS, name: "Заказы 7 дней", type: "order", totalRow: true, active: false})
    this.allHeaders.push({id: this.COL_QUANTITY_ORDERS_30_DAYS, name: "Заказы 30 дней", type: "order", totalRow: true, active: true})

    this.allHeaders.push({id: this.COL_SUM_ORDERS_7_DAYS, name: "Сумма 7 дней", type: "int", totalRow: true})
    this.allHeaders.push({id: this.COL_SUM_ORDERS_30_DAYS, name: "Сумма 30 дней", type: "int", totalRow: true})
    this.allHeaders.push({id: this.COL_SUM_ORDERS_PERIOD, name: "Сумма за период", type: "int", totalRow: true})

    this.allHeaders.push({id: this.COL_QUANTITY_STOCKS, name: "Всего на складах", type: "int", totalRow: true, active: true, title: this.COL_WH_STOCKS_HINT})

    this.initDates()
    await this.restoreOptions();

    await this.loadWh()
    await this.loadOrdersAndStocks();

  },

  computed: {
    filteredRows() {

      return this.arrTable.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();////

        const article = (row[this.COL_ARTICLE] || '').toString().toLowerCase();
        const articleWb = (row[this.COL_ARTICLE_WB] || '').toString().toLowerCase();
        const name = (row[this.COL_NAME || '']).toString().toLowerCase();

        const favorite = row[this.COL_FAVORITE];
        const hidden = row[this.COL_HIDDEN];

        const archive = row[this.COL_ARCHIVE];

        let bTextFilterOk = false

        if ( article.includes(filterString) || articleWb.includes(filterString) || name.includes(filterString)  ){
          bTextFilterOk = true
        }

        let bFavoriteFilterOk = false
        if (this.bShowFavoriteProducts && favorite) bFavoriteFilterOk = true
        if (this.bShowEtcProducts && !favorite) bFavoriteFilterOk = true

        let bArchiveFilterOk = false
        if (this.bShowArchiveProducts && archive) bArchiveFilterOk = true
        if (!archive) bArchiveFilterOk = true  // Не архивные показываем в любом случае

        let bShowWithStocksAndOrdersOk = true

        if (this.bShowWithStocksAndOrders){
          bShowWithStocksAndOrdersOk = false

          if (!row["wb_own_stocks"] || !row["wb_own_orders"]){
            bShowWithStocksAndOrdersOk = true
          }
          else{
            if (row["wb_own_stocks"].length != 0 ) {
              bShowWithStocksAndOrdersOk = true
            }

            else {
              for (let key in row["wb_own_orders"]){
                let order_row = row["wb_own_orders"][key]

                if (order_row["quantity"]) {
                  bShowWithStocksAndOrdersOk = true
                  break
                }
              }
            }
          }
        }

        return bTextFilterOk && bFavoriteFilterOk && bArchiveFilterOk && bShowWithStocksAndOrdersOk
      });
    }
  },

  methods: {
    fetchDrf,
    showMessageResult,
    getDaysArray,
    prepareDate,
    prepareNumber,
    getWbUrlByNmId,
    sortAssocByValue,

    restoreOptions(){
      if(localStorage.getItem("WbOwnProductList_bShowFavoriteProducts"))  this.bShowFavoriteProducts = JSON.parse(localStorage.getItem("WbOwnProductList_bShowFavoriteProducts"))
      if(localStorage.getItem("WbOwnProductList_bShowEtcProducts"))  this.bShowEtcProducts = JSON.parse(localStorage.getItem("WbOwnProductList_bShowEtcProducts"))
      if(localStorage.getItem("WbOwnProductList_bShowArchiveProducts"))  this.bShowArchiveProducts = JSON.parse(localStorage.getItem("WbOwnProductList_bShowArchiveProducts"))
      if(localStorage.getItem("WbOwnProductList_bShowWithStocksAndOrders"))  this.bShowWithStocksAndOrders = JSON.parse(localStorage.getItem("WbOwnProductList_bShowWithStocksAndOrders"))
      if(localStorage.getItem("WbOwnProductList_bHideEmptyWh"))  this.bHideEmptyWh = JSON.parse(localStorage.getItem("WbOwnProductList_bHideEmptyWh"))
    },

    saveOptions(){
      localStorage.setItem("WbOwnProductList_bShowFavoriteProducts", JSON.stringify(this.bShowFavoriteProducts));
      localStorage.setItem("WbOwnProductList_bShowEtcProducts", JSON.stringify(this.bShowEtcProducts));
      localStorage.setItem("WbOwnProductList_bShowArchiveProducts", JSON.stringify(this.bShowArchiveProducts));
      localStorage.setItem("WbOwnProductList_bShowWithStocksAndOrders", JSON.stringify(this.bShowWithStocksAndOrders));
      localStorage.setItem("WbOwnProductList_bHideEmptyWh", JSON.stringify(this.bHideEmptyWh));
    },

    prepareTable(){
      this.allHeaders2 = []
      this.dates = []

      // Добавляем склады
      for (let i = 0; i < this.wh.length; i++){
        this.allHeaders2.push({id: `${this.COL_WH}_${this.wh[i].id}`, name: this.wh[i].name, type: "int", totalRow: true, active: true, column: 2, }) // hideSelect для proxy object
      }

      // Добавляем даты в заголовок
      if (this.arrTable.length > 0 && this.arrTable[0].wb_own_orders.length > 0){
        let orders = this.arrTable[0].wb_own_orders

        for (let j = 0; j < orders.length; j++){
          let id_quantity = `${this.COL_ORDER_QUANTITY}_${orders[j]["date"]}`
          let id_sum = `${this.COL_ORDER_SUM}_${orders[j]["date"]}`
          let id_hint = `${this.COL_ORDER_HINT}_${orders[j]["date"]}`
          let name_quantity =  ` ${prepareDate(orders[j]["date"], "DD.MM")}` // Количество
          let name_sum =  `Сумма ${prepareDate(orders[j]["date"], "DD.MM")}`

          this.allHeaders2.push({id: id_quantity, name: name_quantity, active: true, totalRow: true, hideSelect: true, type: "order", column: 2, title: id_hint})
          this.allHeaders2.push({id: id_sum, name: name_sum, active: false, totalRow: true, hideSelect: true, type: "int", column: 2, title: id_hint})
          this.dates.push(orders[j]["date"])
        }
      }

      // Предварительная подготовка - перезаполним остатки на складах как словари
      for (let i = 0; i < this.arrTable.length; i++) {
        let arrTmpStocks = []
        let whStocksHint = ""

        for (let j = 0; j < this.arrTable[i].wb_own_stocks.length; j++){
          let wh_id = this.arrTable[i].wb_own_stocks[j]["wh"]
          let wh_name = this.arrTable[i].wb_own_stocks[j]["wh_name"]
          let wh_color = this.arrTable[i].wb_own_stocks[j]["wh_color"]
          let quantity = this.arrTable[i].wb_own_stocks[j]["quantity"]
          arrTmpStocks[wh_id] = {quantity: quantity, wh_name: wh_name, wh_color: wh_color, }

          whStocksHint += `${wh_name}: ${quantity}\n`
        }
        this.arrTable[i].wb_own_stocks = arrTmpStocks
        this.arrTable[i][this.COL_WH_STOCKS_HINT] = whStocksHint
      }

      // Предварительная подготовка - перезаполним заказы как словари
      for (let i = 0; i < this.arrTable.length; i++) {
        let arrTmpOrders = []

        let arrOrdersByWh = []

        for (let j = 0; j < this.arrTable[i].wb_own_orders.length; j++){
          let date     = this.arrTable[i].wb_own_orders[j]["date"]
          let quantity = this.arrTable[i].wb_own_orders[j]["orders_total_quantity"]
          let adv_quantity = this.arrTable[i].wb_own_orders[j]["adv_quantity"]
          let sum      = this.arrTable[i].wb_own_orders[j]["orders_total_sum"]

          let wh = this.arrTable[i].wb_own_orders[j]["wh"]
          let hint     = ""
          for (let k = 0; k < wh.length; k++){
            let cur_wh_quantity = wh[k]["quantity"]
            let cur_wh_name = wh[k]["wh_name"]

            // hint = hint + `${wh[k]["wh_name"]}: ${cur_wh_quantity}\n`
            hint = hint + `${cur_wh_name}: ${cur_wh_quantity}\n`

            if (arrOrdersByWh[cur_wh_name]) arrOrdersByWh[cur_wh_name] += cur_wh_quantity
            else arrOrdersByWh[cur_wh_name] = cur_wh_quantity
          }
          if (!quantity) quantity = null
          if (!sum) sum = null

          arrTmpOrders[date] = {quantity: quantity, sum: sum, hint: hint, adv_quantity: adv_quantity, wh: wh, }
        }
        this.arrTable[i].wb_own_orders = arrTmpOrders

        arrOrdersByWh = sortAssocByValue(arrOrdersByWh, true)

        let whOrdersPeriodHint = ""

        for (let key in arrOrdersByWh) {
          whOrdersPeriodHint += `${key}: ${arrOrdersByWh[key]} \n`
        }

        this.arrTable[i][this.COL_WH_ORDERS_PERIOD_HINT] = whOrdersPeriodHint
      }

      // Основной цикл
      for (let i = 0; i < this.arrTable.length; i++) {
        this.arrTable[i][this.COL_PRICE_HINT] = `Цена: ${this.arrTable[i][this.COL_PRICE]} \nСкидка: ${this.arrTable[i][this.COL_DISCOUNT]}%`

        for (let j = 0; j < this.dates.length; j++){
          let date = this.dates[j]
          let date_id_quantity = `${this.COL_ORDER_QUANTITY}_${date}`
          // let date_id_adv_quantity = `${this.COL_ORDER_QUANTITY}_${date}`
          let date_id_sum      = `${this.COL_ORDER_SUM}_${date}`
          let date_id_hint     = `${this.COL_ORDER_HINT}_${date}`

          this.arrTable[i][date_id_quantity] = null

          if (this.arrTable[i]["wb_own_orders"][date] ){
            this.arrTable[i][date_id_quantity] = this.arrTable[i]["wb_own_orders"][date].quantity
            // this.arrTable[i][date_id_adv_quantity] = this.arrTable[i]["wb_own_orders"][date].adv_quantity
            this.arrTable[i][date_id_sum]      = this.arrTable[i]["wb_own_orders"][date].sum
            this.arrTable[i][date_id_hint]     = this.arrTable[i]["wb_own_orders"][date].hint
          }
        }

        for (let j = 0; j < this.wh.length; j++){
          let wh_id = this.wh[j].id
          this.arrTable[i][`wh_${wh_id}`] = null
          if (this.arrTable[i]["wb_own_stocks"][wh_id] ){
            this.arrTable[i][`wh_${wh_id}`] = this.arrTable[i]["wb_own_stocks"][wh_id]["quantity"]
          }
        }
      }

      let arrEmptyStocks = []

      // Найдем пустые склады и скроем их
      if (this.bHideEmptyWh){
        for (let j = 0; j < this.wh.length; j++){
          let curWhIsEmpty = true
          let wh_id = this.wh[j].id

          for (let i = 0; i < this.arrTable.length; i++) {
            if (this.arrTable[i][`wh_${wh_id}`] ) {
              curWhIsEmpty = false
              break
            }
          }

          if (curWhIsEmpty) arrEmptyStocks.push(wh_id)
        }
      }

      // Пустые склады делаем неактивными
      for (let i = 0; i < this.wh.length; i++){
        let wh_id = this.wh[i].id
        let cur_active = !arrEmptyStocks.includes(wh_id)

        for (let value of this.allHeaders2.values()) {
          if (value["id"] == `${this.COL_WH}_${wh_id}` ){
            value["active"] = cur_active
            break
          }
        }
      }
    },

    initDates() {
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 14)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },


    // async changeFavoriteHidden(product){
    //   let jsonBody = {
    //     favorite: product[this.COL_FAVORITE],
    //   }
    //
    //   let res = await fetchDrf(`/wb-own-product/${product[this.COL_ID]}/`,
    //       "PUT",
    //       jsonBody
    //   )
    //
    //   if(res.gotError){
    //     this.messageResult = res.messageResult;
    //     this.gotError = res.gotError
    //   }
    // },


    // async changeArchive(product){
    //   let jsonBody = {
    //     archive: product['gd' + this.COLUMN_ARCHIVE],
    //   }
    //
    //   let res = await fetchDrf(`/wb-own-product/${product[this.COL_ID]}/`,
    //       "PUT",
    //       jsonBody
    //   )
    //
    //   if(res.gotError){
    //     this.messageResult = res.messageResult;
    //     this.gotError = res.gotError
    //   }
    // },


    async loadWh(){
      let res = await fetchDrf(`/wb-wh`)

      if (!res.gotError){
        this.wh = res.data;
      }
      else {
        showMessageResult(res, this);
      }

    },

    async loadOrdersAndStocks(){
      let searchParams = new URLSearchParams({
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          getStocks: 1,
          getOrders: 1,
      })

      let res = await fetchDrf(`/wb-own-barcode?${searchParams}`)

      if (!res.gotError){
        this.arrTable = res.data;

        this.prepareTable()
      }
      else {
        showMessageResult(res, this);
      }
    },

    async updateProducts(){
    },

    async changeOrdersDates(){
      await this.loadOrdersAndStocks()
      this.$refs.refUniversalTable.updateColumns()
    },

  }
}
</script>

<style scoped>

</style>