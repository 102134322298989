<template>
  <MessageResultDiv :messageResult="messageResult" :gotError="gotError" />
  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div ref="divModalWindow" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">{{textModalHeader}}</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideModalWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12" >
              <span v-html="textModalContent"></span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

<!--  <a @click="showModalWindow" >ссылка</a>-->

  <div class="row">
    <div class="col-auto d-inline mt-2">
      <select class="form-select"  v-model="selectedWh" @change="selectChange">
        <option value="0">
          Все склады
        </option>

        <option v-for="wh in arrWhs" :value="wh.id">
          {{ wh.name }}
        </option>
      </select>
    </div>

    <div class="col-auto d-inline mt-2">
      <p><router-link :to="{ name: 'WbWhRel'}" target="_blank" class="fw-normal">Настроить склады</router-link>  </p>
    </div>

  </div>

  <div class="row">
    <div class="col-auto d-inline mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control" @change="selectChange">
    </div>

    <div class="col-auto d-inline mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-auto d-inline mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control" @change="selectChange">
    </div>
  </div>

  <h5>Заказы</h5>

  <div class="row text-center">
      <div style="width: 440px; height: 145px" class="mt-2">
        <BarChartUniversal :chartData = "totalChartData"  withYAxis @click-barchart="onClickBarchartBarcodes"/>
      </div>
  </div>


  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRowsBarcodes"
        :allHeaders="allHeadersBarcodes"
        localStorageHeadersName="DashBoardBarcodes_headers"
        :arrTable="arrTableBarcodes"
        tableClass="table-striped  table-hover table-sm"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <p>Скройте ненужные товары в разделе <router-link :to="{ name: 'WbOwnProductList'}" target="_blank" class="fw-normal">"Мои товары"</router-link>  </p>
    </div>
  </div>

  <h5>Реклама</h5>

  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRowsAdv"
        :allHeaders="allHeadersAdv"
        localStorageHeadersName="DashBoardAdv_headers"
        :arrTable="arrTableAdv"
        tableClass="table-striped table-hover table-sm"
      />
    </div>
  </div>


</template>

<script>
import BarChartUniversal from '../components/BarChartUniversal.vue'
import UniversalTable from "@/components/UniversalTable";

import {Modal, Popover} from 'bootstrap'

import {
  fetchDrf,
  TYPE_COL_ADV_OWN_PRODUCTS,
  TYPE_COL_ADV_STATUS,
  TYPE_COL_ORDERS_BARCHART,
  TYPE_COL_ADV_TYPE_NAME_SHORT,
  TYPE_COL_ADV_STATS_BARCHART, prepareNumber, prepareDate,
} from "@/common";
// import {ref} from "vue";

export default {
  name: "DashBoard",

  components: {
    BarChartUniversal,
    UniversalTable,
  },

  data() {
    return {
      dateStart: "",
      dateEnd: "",

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      filterStringProducts: "",

      arrTableBarcodes: [],
      columnsBarcodes: [],
      allHeadersBarcodes: [],

      arrTableAdv: [],
      columnsAdv: [],
      allHeadersAdv: [],

      COL_NAME: 'name',
      COL_STOCK_QUANTITY: 'stock_quantity',
      COL_ORDERS_BARCHART: 'orders_barchart',
      COL_ORDERS_QUANTITY: 'orders_quantity_total',
      COL_ORDERS_SUM: 'orders_sum_total',
      COL_DRR_PERCENT: 'drr_percent',
      COL_ADV_COST: 'adv_cost',

      COL_FOTO: 'foto_link',
      COL_ARTICLE_WB: 'nm_id',
      COL_ARTICLE: 'article',

      COL_WB_ID: 'wb_id',
      COL_NM_IDS: 'nm_ids',
      COL_ADV_TYPE: 'adv_type',
      COL_ADV_TYPE_NAME: 'adv_type_name',
      COL_STATUS: 'status',
      COL_STATUS_NAME: 'status_name',
      COL_BET_CURRENT: 'bet_current',
      COL_ADV_TYPE_NAME_SHORT: 'adv_type_name_short',

      COL_ADV_STATS_BARCHART: 'adv_stats_barchart',

      COL_STOCK_DETAILS: "stock_details",
      COL_ORDER_DETAILS: "order_details",

      TYPE_COL_ORDERS_BARCHART: "",
      TYPE_COL_ADV_STATS_BARCHART: "",

      selectedWh: 0,

      arrTotal: [],
      arrBarcodes: [],
      arrAdv: [],
      arrWhs: [],

      divModalWindow: undefined,
      textModalHeader: "",
      textModalContent: "",

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "Дашборд"

    this.TYPE_COL_ORDERS_BARCHART = TYPE_COL_ORDERS_BARCHART
    this.TYPE_COL_ADV_STATUS = TYPE_COL_ADV_STATUS;
    this.TYPE_COL_ADV_OWN_PRODUCTS = TYPE_COL_ADV_OWN_PRODUCTS;
    this.TYPE_COL_ADV_TYPE_NAME_SHORT = TYPE_COL_ADV_TYPE_NAME_SHORT;
    this.TYPE_COL_ADV_STATS_BARCHART = TYPE_COL_ADV_STATS_BARCHART;

    this.allHeadersBarcodes.push({id: this.COL_FOTO, name: "Фото", active: true, title: "name", type: "foto" })
    this.allHeadersBarcodes.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", active: false })
    this.allHeadersBarcodes.push({id: this.COL_ARTICLE, name: "Артикул", active: false })
    this.allHeadersBarcodes.push({id: this.COL_STOCK_QUANTITY, name: "Остаток", active: true, type: "int", totalRow: true, modal_window_content: this.COL_STOCK_DETAILS })
    this.allHeadersBarcodes.push({id: this.COL_ORDERS_QUANTITY, name: "Заказы", active: true, type: "int", totalRow: true, modal_window_content: this.COL_ORDER_DETAILS })
    this.allHeadersBarcodes.push({id: this.COL_ORDERS_SUM, name: "Сумма", active: false, type: "int", totalRow: true })
    this.allHeadersBarcodes.push({id: this.COL_DRR_PERCENT, name: "ДРР %", active: true, type: "int" })
    this.allHeadersBarcodes.push({id: this.COL_ORDERS_BARCHART, name: "График заказов", active: true, type: this.TYPE_COL_ORDERS_BARCHART })

    this.allHeadersAdv.push({id: this.COL_ADV_OWN_PRODUCTS, name: "Номенклатура", active: true, type: this.TYPE_COL_ADV_OWN_PRODUCTS, title: this.COL_NAME})
    this.allHeadersAdv.push({id: this.COL_ADV_TYPE_NAME, name: "Тип", active: true, type: this.TYPE_COL_ADV_TYPE_NAME_SHORT, title: this.COL_ADV_TYPE_NAME })
    this.allHeadersAdv.push({id: this.COL_STATUS_NAME, name: "Статус", active: true, type: this.TYPE_COL_ADV_STATUS })
    this.allHeadersAdv.push({id: this.COL_BET_CURRENT, name: "₽", active: true, type: "int" })
    this.allHeadersAdv.push({id: this.COL_ADV_STATS_BARCHART, name: "График показов", active: true, type: this.TYPE_COL_ADV_STATS_BARCHART })

    this.allHeadersAdv.push({id: this.COL_NAME, name: "Название", active: false })
    this.allHeadersAdv.push({id: this.COL_WB_ID, name: "ID", active: false })

    this.initDates()
    this.loadDashBoardData()
  },

  computed:{
    totalChartData(){
      let dates = []
      let quantitys = []
      let sums = []
      let labels = []

      let allWhs = {}

      for(let i = 0; i < this.arrTotal.length; i++ ){
        let orders_wh = this.arrTotal[i]["orders_wh"]

        for (let key in orders_wh){
          let whName =  orders_wh[key]["wh_name"]
          let whColor = orders_wh[key]["wh_color"]

          if (!whColor) whColor = 'grey'

          allWhs[key] = {
            whName: whName,
            whColor: whColor
          }
        }
      }

      // Создадим и заполним нулями
      let sums_wh = {}
      let quantitys_wh = {}

      for (let key in allWhs){
        sums_wh[key] = []
        quantitys_wh[key] = []

        for(let i = 0; i < this.arrTotal.length; i++ ){
          sums_wh[key][i] = 0;
          quantitys_wh[key][i] = 0;
        }
      }

      for(let i = 0; i < this.arrTotal.length; i++ ){
        dates.push(this.arrTotal[i]["date"])
        labels.push( `${this.arrTotal[i]["date"]}` )
        quantitys.push(this.arrTotal[i]["quantity"])
        sums.push(this.arrTotal[i]["sum"])

        let orders_wh = this.arrTotal[i]["orders_wh"]

        for (let key in orders_wh){
          sums_wh[key][i] = orders_wh[key]["sum"]
          quantitys_wh[key][i] = orders_wh[key]["quantity"]
        }
      }

      let chartData = {
        labels: dates,
        datasets: [],
      }

      for (let key in allWhs){
        let whName = allWhs[key]["whName"]
        let whColor = allWhs[key]["whColor"]

        chartData["datasets"].push({
          label: whName,
          backgroundColor: whColor,
          data: sums_wh[key],
          barThickness: 20,
        })
      }

      return chartData
    },


    filteredRowsBarcodes() {
      return this.arrTableBarcodes.filter(row => {

        return true // !!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },


    filteredRowsAdv() {
      return this.arrTableAdv.filter(row => {

        return true // !!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },
  },

  methods: {
    showModalWindow(){
      this.divModalWindow = new Modal(this.$refs.divModalWindow)
      this.divModalWindow.show()
    },

    hideModalWindow(){
      this.divModalWindow.hide()
    },

    showModalWindowClick(item, header){
      this.textModalHeader = header["name"]
      this.textModalContent = item[header.modal_window_content]

      this.showModalWindow()
    },

    onClickBarchartBarcodes(index, item){
      if (index === undefined) return;

      let orders_wh = this.arrTotal[index]["orders_wh"]

      if (!orders_wh) return

      let sOrdersWh = "" ;
      let totalSum = 0;
      let totalSumSpp = 0;
      let totalQuantity = 0;
      let date = this.arrTotal[index]["date"]

      for (let key in orders_wh) {
        let quantity = orders_wh[key]["quantity"]
        let sum = orders_wh[key]["sum"]
        let sumSpp = orders_wh[key]["sum_spp"]
        let wh_name = orders_wh[key]["wh_name"]

        let wh_color = orders_wh[key]["wh_color"]
        if (!wh_color) wh_color = 'grey'

        totalSum += sum
        totalSumSpp += sumSpp
        totalQuantity += quantity

        sOrdersWh += `<span class="badge" style="background-color: ${wh_color}">&nbsp;</span> ${wh_name}: <b>${sum}</b> (${quantity}) <br/>`
      }

      let avgSum = 0
      let avgSumSpp = 0
      let avgSppDiscountPercent = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
        avgSumSpp = Math.floor(totalSumSpp / totalQuantity)
      }
      if (totalSum) avgSppDiscountPercent = Math.floor((1 - (totalSumSpp/totalSum)) * 100)

      if (!avgSumSpp) avgSppDiscountPercent = undefined

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`

      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`
      sOrdersWh += `Средняя цена с СПП: <b>${prepareNumber(avgSumSpp)}</b> <br/>`
      sOrdersWh += `Средняя СПП: <b>${prepareNumber(avgSppDiscountPercent)}%</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()

    },

    async prepareTable(){
      for (let i = 0; i < this.arrTableBarcodes.length; i++){

        // stocks
        this.arrTableBarcodes[i][this.COL_STOCK_DETAILS] = "";

        let sStockDetails = ""
        let stocks_wh = this.arrTableBarcodes[i]["stocks_wh"]

        if (!stocks_wh) continue

        for (let j = 0; j < stocks_wh.length; j++){
          let color = stocks_wh[j]["color"]
          if (!color) color = "grey"
          sStockDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${stocks_wh[j]["name"]} : <b>${stocks_wh[j]["quantity"]}</b> <br/>`
        }

        this.arrTableBarcodes[i][this.COL_STOCK_DETAILS] = sStockDetails;

        let sOrderDetails = ""

        let orders_wh = this.arrTableBarcodes[i]["orders_wh"]

        if (!orders_wh) orders_wh = []

        for (let j = 0; j < orders_wh.length; j++){
          let color = orders_wh[j]["color"]
          if (!color) color = "grey"
          sOrderDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${orders_wh[j]["name"]} : <b>${orders_wh[j]["quantity"]}</b> <br/>`
        }

        sOrderDetails += `<hr/>`
        sOrderDetails += `Всего заказов: <b>${prepareNumber(this.arrTableBarcodes[i][this.COL_ORDERS_QUANTITY])}</b>  <br/>`
        sOrderDetails += `Сумма: <b>${prepareNumber(this.arrTableBarcodes[i][this.COL_ORDERS_SUM])}</b>  <br/>`
        sOrderDetails += `ДРР: <b>${prepareNumber(this.arrTableBarcodes[i][this.COL_DRR_PERCENT])}</b> % <br/>`
        sOrderDetails += `Затраты на рекламу: <b>${prepareNumber(this.arrTableBarcodes[i][this.COL_ADV_COST])}</b> <br/>`

        this.arrTableBarcodes[i][this.COL_ORDER_DETAILS] = sOrderDetails;
      }
    },

    async selectChange(){
      await this.loadDashBoardData()
    },

    initDates() {
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 14)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },

    async loadDashBoardData() {

      let searchParams = new URLSearchParams({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        wh: this.selectedWh,
      })

      let res = await fetchDrf(`/dashboard/?${searchParams}`)

      console.log("res")
      console.log(res)
      //
      // for (let i = 0; i  < res.data[0].barcodes.length; i++)
      // {
      //   console.log(res.data[0].barcodes[i].drr_percent)
      // }

      if (!res.gotError) {
        this.arrTotal = res.data[0].total;
        this.arrTableBarcodes = res.data[0].barcodes;
        this.arrTableAdv = res.data[0].adv;
        this.arrWhs = res.data[0].whs; //

        this.prepareTable()
      }
      else {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },
  },
}
</script>

<style scoped>
  .popover {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 10px;
  }
  .popover-header {
    font-weight: bold;
  }
  .popover-body {
    color: #333;
  }
</style>