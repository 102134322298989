<template>
  <h1>Рекламные кампании ВБ</h1>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <div class="row">
    <div class="col-auto">
      <WbOwnAdvListTable
        :showButtonSync="true"
        :showOnlyActual="false"
      />
    </div>
  </div>
</template>

<script>
  import WbOwnAdvListTable from "@/components/WbOwnAdvListTable";

  export default {
    name: "WbOwnAdvList",

    components: {
      WbOwnAdvListTable,
    },

    data(){
      return{
        messageResult: "",
        gotError: false
      }
    },

    created() {
    },

    methods: {
    }
  }
</script>

<style scoped>

</style>