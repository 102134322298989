<template>
  <div id="divSpinner"  class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'WbSearchProductList'}" class="btn btn-info mt-1">← вернуться</router-link>
    </div>
  </div>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <div class="row g-3 align-items-center">
    <h5>
      <div class="col-auto">
        {{ name }} ({{ nm_id }})

        <router-link :to="{ name: 'WbSearchProductEdit', params: { id: id }}" target="_blank" class="btn btn-info mx-2">
          Редактировать ключи
        </router-link>

        <a :href=getWbUrlByNmId(nm_id) target="_blank" class="btn btn-link">
          Ссылка на товар на WB
        </a>
      </div>
    </h5>
  </div>

  <span class="text-secondary">(Позиции товара в органическом поиске по регионам)</span>

  <wb-search-product-analytics-table
      :showDateSelection="true"
      :showButtonCheckCurrentPositions="true"
      :showTextFilter="true"
      :showRegionsFilter="true"
      :showAllRegions="false"
      :daysBeforeStartDate="14"
      :wbSearchProductId=id
      />

</template>

<script>
import {fetchDrf, getWbUrlByNmId, showMessageResult} from "@/common";

import WbSearchProductAnalyticsTable from '../components/WbSearchProductAnalyticsTable'

export default {
  name: "WbSearchProductAnalytics",
  props: ['id'],

  components: {
    WbSearchProductAnalyticsTable
  },

  data() {
    return {
      name: "",
      nm_id: "",
      messageResult: "",
      gotError: false
    }
  },

  created() {
    this.loadProductName();
  },

  methods: {
    getWbUrlByNmId,

    async loadProductName(){
      let res = await fetchDrf(`/wb-search-product/${this.id}/`)

      if (!res.gotError){
        this.name = res.data["name"]
        this.nm_id = res.data["nm_id"]
      }
      else {
        showMessageResult(res, this);
      }
    },
  }
}
</script>

<style scoped>

</style>