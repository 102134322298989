<template>
  <MessageResultDiv :messageResult="messageResult" :gotError="gotError" />

  <div class="row">
    <div class="col-xs-12 mt-2">
      <div class="form-group">
        <input id="cbShowDashboard" class="form-check-input align-middle me-1" type="checkbox" v-model="show_dashboard" @change="saveShowDashboard">
        <label for="cbShowDashboard"  class="col-form-label align-middle">Выводить "Дашбоард" вместо "Первых шагов"</label>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <h3>Первые шаги</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <ol>
        <li>Заполните токен из ВБ:
          <span v-if="tokensAreFilled" class="text-success fw-bold">Заполнен</span>
          <span v-else class="text-danger fw-bold">
            Не заполнен. <router-link :to="{ name: 'WbLkList'}" target="_blank" class="fw-normal">Заполните токен</router-link> <span class="text-secondary fw-normal"> чтобы подгружать данные о заказах, остатках, финансовых отчетах</span>
          </span>
        </li>

        <li>
          Все, основная настройка завершена
        </li>
      </ol>

      <h3>Дальнейшие шаги - по желанию</h3>
      <ol>
        <li>
          Подождите примерно 5 минут после заполнения токенов пока грузятся данные. В редких случаях загрузка занимает до часа.
        </li>
        <li>
          Система отпишется в бот телеграм когда закончит загружать данные из ВБ
        </li>
        <li>
          Заполните себестоимость товаров:
          <span v-if="costPricesAreFilled" class="text-success fw-bold">Заполнена</span>
          <span v-else class="text-danger fw-bold">
            Не заполнена.
            <router-link :to="{ name: 'WbOwnCostPriceList'}" target="_blank" class="fw-normal">Заполните здесь</router-link>
            <span class="text-secondary fw-normal"> если хотите чтобы система рассчитывала вашу прибыль</span>
          </span>
        </li>
        <li>
          Начните знакомство с системой с разделов <router-link :to="{ name: 'WbOwnProductList'}" target="_blank" class="fw-normal">Мои товары</router-link> и <router-link :to="{ name: 'WbOwnRReportList'}" target="_blank" class="fw-normal">Недельные отчеты</router-link> чтобы посмотреть как у вас дела с прибылью, остатками и заказами
        </li>
      </ol>

    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <h3>Возможности</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <p>Вся загрузка данных безопасна и идет строго по официальному API Wildberries </p>
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <ol>
        <li>Раздел <router-link :to="{ name: 'WbOwnRReportList'}" target="_blank">Недельные отчеты</router-link> - сколько денег вы заработали, какая рентабельность, норма прибыли и еще более 30 показателей</li>
        <li>Раздел <router-link :to="{ name: 'WbOwnCostPriceList'}" target="_blank">Себестоимость</router-link> - вспомогательный раздел для недельных отчетов. Заполните чтобы точно рассчитать прибыль</li>
        <li>Раздел <router-link :to="{ name: 'WbOwnProductList'}" target="_blank">Мои товары</router-link> - список ваших товаров (баркодов) + остатки в разбивке по складам + заказы по дням</li>
        <li>Раздел <router-link :to="{ name: 'WbAdvBetsRequestList'}" target="_blank">Ставки рекламы</router-link> - узнать какие сейчас актуальные ставки рекламы в поиске</li>
      </ol>

      <p>ВНИМАНИЕ: часть столбцов c важными показателями по умолчанию скрыта чтобы не перегружать экран. Заглядывайте в настройки таблиц (шестеренка наверху таблиц) чтобы их выводить </p>
      <p>В настройках таблиц можно скрыть/показать любой столбец, или поменять их местами. Можно выгрузить в Excel</p>
      <p>Когда ВБ выпустит новый недельный отчет - система его автоматически загрузит по API и оповестит вас в телеграм боте (в которым вы регистрировались)</p>
    </div>
  </div>

</template>

<script>
import {loadWblksFromDrf, loadCostPricesFromDrf} from "@/service_layer";
import MessageResultDiv from "@/components/MessageResultDiv";
import {fetchDrf} from "@/common";

export default {
  name: "PageStart",

  components: {
    MessageResultDiv,
  },

  data() {
    return{
      wblks: [],
      costPrices: [],

      show_dashboard: false,

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "Первые шаги"

    await this.loadShowDashboard();
    await this.loadWblks();
    await this.loadCostPrices();
  },

  computed: {
    tokensAreFilled() {
      let res = false

      for (let i = 0; i < this.wblks.length; i++) {
        if (this.wblks[i].token_wb) {
          res = true
        }
      }
      return res
    },

    costPricesAreFilled(){
      let res = false

      if (this.costPrices.length > 0){
        res = true
      }
      return res
    },

  },

  methods: {
    loadWblksFromDrf,

    async loadWblks(){
      let res = await loadWblksFromDrf()

      if (!res.gotError){
        this.wblks = res.data;
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async loadCostPrices(){
      let res = await loadCostPricesFromDrf()

      if (!res.gotError){
        this.costPrices = res.data;
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async loadShowDashboard(){
      let res = await fetchDrf(
        `/auth/users/me/`,
        "GET",
        {},
        "",
        undefined,
        true
      )

      if (!res.gotError){
        this.show_dashboard = res.data["show_dashboard"]
      }
      else {
       this.messageResult = res.messageResult;
       this.gotError = res.gotError
      }
    },

    async saveShowDashboard(){
      // console.log("saveShowDashboard")

      let jsonBody = {
        show_dashboard: this.show_dashboard,
      }

      let res = await fetchDrf(
      `/auth/users/me/`,
       "PUT",
       jsonBody,
       "Успешно обновили профиль",
       200,
       true
      )

      if (this.gotError){
        console.log(res.messageResult)
      }
    },

  },



}
</script>

<style scoped>

</style>