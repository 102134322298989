<template>
  <div class="row g-3 align-items-center">
    <div class="col-auto">
      <input id="cbStatusShown" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bStatusShown">
      <label for="cbStatusShown"  class="form-check-label"><span class="badge bg-success" >Идут показы ({{getNumberOfCampaignsInStatus(WB_ADV_STATUS_SHOWN)}})</span></label>
    </div>

    <div class="col-auto">
      <input id="cbStatusPause" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bStatusPause">
      <label for="cbStatusPause"  class="form-check-label"><span class="badge bg-warning" >На паузе ({{getNumberOfCampaignsInStatus(WB_ADV_STATUS_PAUSE)}})</span></label>
    </div>

    <div class="col-auto">
      <input id="cbStatusFinished" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bStatusFinished">
      <label for="cbStatusFinished"  class="form-check-label me-5"><span class="badge bg-danger" >Завершена ({{getNumberOfCampaignsInStatus(WB_ADV_STATUS_FINISHED)}})</span></label>
    </div>

    <div class="col-auto">
      <input id="cbTypeCard" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bTypeCard">
      <label for="cbTypeCard" class="form-check-label">Карточка товара</label>
    </div>

    <div class="col-auto">
      <input id="cbTypeSearch" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bTypeSearch">
      <label for="cbTypeSearch"  class="form-check-label me-1">В поиске</label>
    </div>

    <div class="col-auto">
      <input id="cbTypeAuto" @change="saveOptions" class="form-check-input me-1" type="checkbox" v-model="bTypeAuto">
      <label for="cbTypeAuto"  class="form-check-label me-5">Автоматическая</label>
    </div>

    <div class="col-auto">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

<!--    <div class="col-auto">-->
<!--      <div class="input-group">-->
<!--        <button v-if="showButtonSync" @click="renewWbAdvCampaigns" type="button" class="btn btn-primary">↻ Синхронизировать рекламные кампании</button>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div class="row mt-1">
    <div class="col-auto">

      <UniversalTable
        :items="filteredRows"
        :allHeaders="allColumns"
        :localStorageHeadersName="WbOwnAdvList_headers"
        :arrTable="arrTable"
        tableClass="table-striped table-bordered border-light table-hover"
      />

    </div>
  </div>
</template>

<script>
import UniversalTable from "@/components/UniversalTable";


import {
  fetchDrf,
  prepareDate,
  prepareNumber,
  getActualityCampaignStr,
  getActualityBetStr,
  getErrorSignClass,
  getWbCampaignUrl,

  WB_ADV_STATUS_SHOWN,
  WB_ADV_STATUS_FINISHED,
  WB_ADV_STATUS_PAUSE,
  SYSTEM_NAME_RUS,

  WB_ADV_TYPE_CARD,
  WB_ADV_TYPE_SEARCH,
  WB_ADV_TYPE_AUTO,

  WB_ADV_STRATEGY_BET_NOT_MORE,
  WB_ADV_STRATEGY_BET_FIXED,
  WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS,
  WB_ADV_STRATEGY_POS_NOT_MORE,

  TYPE_COL_ADV_CAMPAIGN_ID,
  TYPE_COL_ADV_STATUS,
  TYPE_COL_ADV_OWN_PRODUCTS,

  COL_ADV_OWN_PRODUCTS,
  COL_ADV_STATUS,

  // getAdvStatusBadgeClass,

} from "@/common";

export default {
  name: "WbAdvListTable",

  components: {
    UniversalTable,
  },

  props: {
    wbOwnProductId: Number,
    showButtonSync: Boolean,
    showOnlyActual: Boolean,
  },

  data() {
    return {
        arrTable:[],
        auth_token: "",

        allColumns: [],
        columns: [],

        sortedbyASC: true,
        sortBy: "",
        filterString: "",

        bStatusShown: true,
        bStatusPause: true,
        bStatusFinished: false,

        bTypeCard: true,
        bTypeSearch: true,
        bTypeAuto: true,

        COL_WB_ID: 'wb_id',
        COL_NAME: 'name',
        COL_NM_IDS: 'nm_ids',
        COL_ADV_TYPE: 'adv_type',
        COL_ADV_TYPE_NAME: 'adv_type_name',
        COL_STATUS: 'status',
        COL_STATUS_NAME: 'status_name',

        COL_DATE_CREATE: 'date_create',
        COL_DATE_CHANGE: 'date_change',
        COL_DATE_START: 'date_start',
        COL_DATE_END: 'date_end',

        COL_BUDGET: 'budget',

        COL_BET_CURRENT: 'bet_current',
        COL_POS: 'pos_current_string',
        COL_COMP_QUANTITY: 'competitors_quantity',
        COL_STRATEGY: 'strategy_name',
        COL_MANAGED: 'managed',

        COL_ACTUALITY_CAMPAIGN: 'actuality_campaign',
        COL_ACTUALITY_BET: 'actuality_bet',

        COL_POS_MIN: 'pos_min',
        COL_BET_MAX: 'bet_max',
        COL_COMP_POS_STRING: 'competitors_pos_string',

        allColumnsSelected: true,

        messageResult: "",
        gotError: false,
    }
  },

  emits: [],

  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {
        const filterString = this.filterString.toString().toLowerCase().trim();

        if (!row['name']) return false

        const name = row['name'].toString().toLowerCase();
        const advertId = row[this.COL_WB_ID].toString().toLowerCase();
        const nmIds = row['nm_ids'].toString().toLowerCase();

        const status = row['status']
        const adv_type = row['adv_type']

        let bTextFiltersOk = false

        if (  name.includes(filterString) || advertId.includes(filterString) || nmIds.includes(filterString)  ){
          bTextFiltersOk = true
        }

        let bStatusFilterOk = false

        if ( (status == WB_ADV_STATUS_FINISHED && this.bStatusFinished) || (status == WB_ADV_STATUS_SHOWN && this.bStatusShown) || (status == WB_ADV_STATUS_PAUSE && this.bStatusPause) )
          bStatusFilterOk = true


        let bAdvTypeFilterOk = false
        if ( (adv_type == WB_ADV_TYPE_CARD && this.bTypeCard) || (adv_type == WB_ADV_TYPE_SEARCH && this.bTypeSearch) || (adv_type == WB_ADV_TYPE_AUTO && this.bTypeAuto))
          bAdvTypeFilterOk = true

        return bTextFiltersOk && bStatusFilterOk && bAdvTypeFilterOk
      });
    }
  },

  async created() {

    document.title = "Рекламные кампании ВБ"

    this.WB_ADV_STATUS_SHOWN = WB_ADV_STATUS_SHOWN;
    this.WB_ADV_STATUS_FINISHED = WB_ADV_STATUS_FINISHED;
    this.WB_ADV_STATUS_PAUSE = WB_ADV_STATUS_PAUSE;
    this.SYSTEM_NAME_RUS = SYSTEM_NAME_RUS;

    this.WB_ADV_TYPE_CARD = WB_ADV_TYPE_CARD;
    this.WB_ADV_TYPE_SEARCH = WB_ADV_TYPE_SEARCH;
    this.WB_ADV_TYPE_AUTO = WB_ADV_TYPE_AUTO;

    this.TYPE_COL_ADV_CAMPAIGN_ID = TYPE_COL_ADV_CAMPAIGN_ID;
    this.TYPE_COL_ADV_STATUS = TYPE_COL_ADV_STATUS;
    this.TYPE_COL_ADV_OWN_PRODUCTS = TYPE_COL_ADV_OWN_PRODUCTS;

    this.COL_ADV_OWN_PRODUCTS = COL_ADV_OWN_PRODUCTS;

    this.allColumns.push({id: this.COL_WB_ID, name: "ID", type: this.TYPE_COL_ADV_CAMPAIGN_ID})
    this.allColumns.push({id: this.COL_STATUS_NAME, name: "Статус", type: this.TYPE_COL_ADV_STATUS}) //  title: this.COL_ACTUALITY_BET,
    this.allColumns.push({id: this.COL_NAME, name: "Название", width: "300px"})
    this.allColumns.push({id: this.COL_ADV_OWN_PRODUCTS, name: "Номенклатура", type: this.TYPE_COL_ADV_OWN_PRODUCTS})
    this.allColumns.push({id: this.COL_ADV_TYPE_NAME, name: "Тип"})
    this.allColumns.push({id: this.COL_DATE_CREATE, name: "Дата создания", type: "date"})
    this.allColumns.push({id: this.COL_DATE_CHANGE, name: "Дата изменения", type: "date", title: this.COL_ACTUALITY_CAMPAIGN})
    this.allColumns.push({id: this.COL_DATE_START, name: "Дата старта", type: "date"})
    this.allColumns.push({id: this.COL_DATE_END, name: "Дата завершения", type: "date"})
    this.allColumns.push({id: this.COL_BET_CURRENT, name: "Тек ставка", title: this.COL_ACTUALITY_BET, type: "int"})


    // this.allColumns.push({id: this.COL_BUDGET_TEXT, name: "Баланс"})
    // this.allColumns.push({id: this.COL_BET_MAX, name: "Макс ставка", class: "text-end"})
    // this.allColumns.push({id: this.COL_POS, name: "Позиция", title: this.COL_COMP_POS_STRING})
    // this.allColumns.push({id: this.COL_COMP_QUANTITY, name: "Конкурентов", title: this.COL_COMP_POS_STRING, class: "text-end"})
    // this.allColumns.push({id: this.COL_STRATEGY, name: "Стратегия"})
    // this.allColumns.push({id: this.COL_MANAGED, name: `Управляется ${this.SYSTEM_NAME_RUS}`})

    // По умолчанию все активны
    for (var i = 0; i < this.allColumns.length; i++){
      this.allColumns[i]['active'] = true
    }

    await this.restoreOptions()
    await this.loadWbAdvCampaigns()
  },

  methods: {
    fetchDrf,
    prepareDate,
    prepareNumber,
    getActualityCampaignStr,
    getActualityBetStr,
    getErrorSignClass,
    getWbCampaignUrl,

    getNumberOfCampaignsInStatus(status){
      let nLength = this.arrTable.filter(row => {
        const cur_status = row['status']
        if (cur_status == status) return true
        else return false
      }).length

      return nLength
    },

    getAdvStrategyClass(adv_campaign){
      if (adv_campaign.strategy == WB_ADV_STRATEGY_BET_NOT_MORE) return "bg-light text-dark"
      if (adv_campaign.strategy == WB_ADV_STRATEGY_BET_FIXED) return "bg-secondary"
      if (adv_campaign.strategy == WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS) return "bg-dark"
      if (adv_campaign.strategy == WB_ADV_STRATEGY_POS_NOT_MORE) return "bg-info text-dark"
    },

    // getAdvStatusBadgeClass(adv_campaign){
    //   if (adv_campaign.status == WB_ADV_STATUS_FINISHED) return "bg-danger" // Завершена
    //   if (adv_campaign.status == WB_ADV_STATUS_SHOWN) return "bg-success" // Идут показы
    //   if (adv_campaign.status == WB_ADV_STATUS_PAUSE) return "bg-warning" // На паузе
    // },

    restoreOptions(){
      if(localStorage.getItem("WbAdvList_bStatusFinished"))  this.bStatusFinished = JSON.parse(localStorage.getItem("WbAdvList_bStatusFinished"));
      if(localStorage.getItem("WbAdvList_bStatusShown"))  this.bStatusShown = JSON.parse(localStorage.getItem("WbAdvList_bStatusShown"));
      if(localStorage.getItem("WbAdvList_bStatusPause"))  this.bStatusPause = JSON.parse(localStorage.getItem("WbAdvList_bStatusPause"));

      if(localStorage.getItem("WbAdvList_bTypeCard"))  this.bTypeCard = JSON.parse(localStorage.getItem("WbAdvList_bTypeCard"));
      if(localStorage.getItem("WbAdvList_bTypeSearch"))  this.bTypeSearch = JSON.parse(localStorage.getItem("WbAdvList_bTypeSearch"));
      if(localStorage.getItem("WbAdvList_bTypeAuto"))  this.bTypeAuto = JSON.parse(localStorage.getItem("WbAdvList_bTypeAuto"));

      if (this.showOnlyActual){
        this.bStatusFinished = false
      }
    },

    saveOptions(){
      if (!this.showOnlyActual){
        localStorage.setItem("WbAdvList_bStatusFinished", JSON.stringify(this.bStatusFinished));
      }

     localStorage.setItem("WbAdvList_bStatusShown", JSON.stringify(this.bStatusShown));
     localStorage.setItem("WbAdvList_bStatusPause", JSON.stringify(this.bStatusPause));

     localStorage.setItem("WbAdvList_bTypeCard", JSON.stringify(this.bTypeCard));
     localStorage.setItem("WbAdvList_bTypeSearch", JSON.stringify(this.bTypeSearch));
     localStorage.setItem("WbAdvList_bTypeAuto", JSON.stringify(this.bTypeAuto));
    },

    async changeCampaignManaged(adv_campaign) {
      let jsonBody = {
        wblk: adv_campaign.wblk,
        advert_id: adv_campaign.advert_id,
        managed: !adv_campaign.managed
      }

      let res = await fetchDrf(
       `/wbadv-campaign/${adv_campaign.id}/`,
       "PUT",
       jsonBody,
       "Успешно стартанули/приостановили управление кампанией"
      )
      if (!res.gotError){
        this.managed = res.managed
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }

      await this.loadWbAdvCampaigns()
    },

    prepareTableAdv(){
      for (let i = 0; i < this.arrTable.length; i++){
        // this.arrTable[i][this.COL_ACTUALITY_CAMPAIGN] = this.getActualityCampaignStr(this.arrTable[i])
        // this.arrTable[i][this.COL_ACTUALITY_BET] = this.getActualityBetStr(this.arrTable[i])
      }
    },

    async loadWbAdvCampaigns() {
      let searchParams = ""

      if (this.wbOwnProductId){
        searchParams = new URLSearchParams({
            wb_own_product_id: this.wbOwnProductId,
        })
      }

      let res = await fetchDrf(`/wbadv-campaign/?${searchParams}`)

      if (!res.gotError){
        this.arrTable = res.data;
        this.prepareTableAdv()
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async renewWbAdvCampaigns() {
      let res = await fetchDrf(
          `/wbadv-campaign-renew/`,
          "POST",
      )

      if (!res.gotError){
        this.loadWbAdvCampaigns()
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    }
  }
}
</script>

<style scoped>

</style>