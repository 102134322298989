<template>
      <component :is="layout">
          <router-view/>
      </component>
</template>

<script>
  import Nav from "@/components/Nav";
  import Footer from "@/components/Footer";

  import CabinetLayout from './layouts/CabinetLayout.vue'
  import CabinetTopMenuLayout from './layouts/CabinetTopMenuLayout.vue'
  import EmptyLayout from './layouts/EmptyLayout.vue'
  import UserLayout from './layouts/UserLayout.vue'

  export default {
    components: {
      Nav,
      Footer,
      CabinetLayout,
      CabinetTopMenuLayout,
      UserLayout,
      EmptyLayout,
    },

    data(){
      return{
      }
    },

    methods: {
    },
    computed: {
        layout(){
            return this.$route.meta.layout || "EmptyLayout"
        }
    }
  }
</script>