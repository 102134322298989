<template>
  <span v-if="!editMode" @click="editMode=true" >{{ displayedValue }}</span>
  <span v-else>
    <input v-if="type=='float'"      type="number" :value="value" v-on:keyup.esc="editMode=false" @blur="updateValue($event.target.value)" v-on:keyup.enter="updateValue($event.target.value)" step='0.01' value='0.00' placeholder='0.00'>
    <input v-else-if="type=='int'"   type="number" :value="value" v-on:keyup.esc="editMode=false" @blur="updateValue($event.target.value)" v-on:keyup.enter="updateValue($event.target.value)" step='1'>
    <input v-else-if="type=='date'"  type="date"   :value="value" v-on:keyup.esc="editMode=false" @blur="updateValue($event.target.value)" v-on:keyup.enter="updateValue($event.target.value)">
    <input v-else                    type="text"   :value="value" v-on:keyup.esc="editMode=false" @blur="updateValue($event.target.value)" v-on:keyup.enter="updateValue($event.target.value)">
  </span>
</template>

<script>
export default {
  name: "EditableCell",
  props: {
    value: String,
    displayedValue: String,
    type: String,
  },

  data() {
    return {
      editMode: false,
      editedValue: ''
    }
  },

methods: {
    updateValue(value) {
      this.editMode=false
      this.editedValue = value
    }
  },
  watch: {
    editedValue(value) {
      this.$emit('input', value)
    }
  }

}
</script>

<style scoped>

</style>