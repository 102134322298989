<template>


<Bar
  id="my-chart-id"
  :options="chartOptions"
  :data="chartData"
/>

</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChartWhOrders',
  components: { Bar },

  props:{
    chartData: Object,
  },

  data() {
    return {
      chartOptions: {

        layout: {
            padding: -10,
        },

        maintainAspectRatio: false,
        aspectRatio: 1,

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,

            titleFont:{
              weight: 'normal',
            },


          },
        },

        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            padding: 0,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: false
            }

          },
          y: {
            stacked: true,
            padding: 0,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: false
            }
          }
        }
      }
    }
  },

  methods: {
    // my_legend( tooltipItems ){
    //   return "8888888"
    // }
  }
}
</script>

<style scoped>
.tooltip label {
  display: none;
}
</style>