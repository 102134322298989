<template>

<nav class="navbar navbar-expand-lg navbar-light bg-light" >
  <div class="container-fluid">

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

        <li class="nav-item">
          <router-link :to="{name: 'WbOwnRReportList'}" class="nav-link mx-2 text-dark">Недельные отчеты</router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{name: 'WbOwnCostPriceList'}" class="nav-link mx-2 text-dark">Себестоимость</router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{name: 'WbOwnProductList'}" class="nav-link mx-2 text-dark">Мои товары</router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{name: 'WbAdvBetsRequestList'}" class="nav-link mx-2 text-dark">Ставки рекламы</router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{name: 'PageStart'}" class="nav-link mx-2 text-dark">Первые шаги</router-link>
        </li>

        <li class="nav-item" >
          <router-link :to="{name: 'DashBoard'}" class="nav-link mx-2 text-dark">Дашбоард</router-link>
        </li>

        <li class="nav-item" v-if="is_staff">
          <router-link :to="{name: 'WbAdmin'}" class="nav-link mx-2 text-dark">Админка</router-link>
        </li>


        <li class="nav-item" >
          <router-link :to="{name: 'WbOwnAdvList'}" class="nav-link mx-2 text-dark">Рекламные кампании</router-link>
        </li>

        <li class="nav-item" v-if="is_staff">
          <router-link :to="{name: 'WbSearchProductList'}" class="nav-link mx-2 text-dark">Отслеживание позиций</router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{name: 'UserSelfEdit'}" class="nav-link mx-2 text-secondary">{{username}}</router-link>
        </li>

      </ul>
    </div>

  </div>
</nav>

<div class="container-fluid mx-2">
  <slot/>

  <div class="text-secondary my-3">
    Поддержка в Телеграм: <a :href="'https://t.me/' + LOC_TG_SUPPORT_NAME" class="link-secondary" target="_blank">@{{LOC_TG_SUPPORT_NAME}}</a>
  </div>

</div>


</template>

<script>

import {fetchDrf, TG_SUPPORT_NAME} from "@/common";

export default {
  name: "CabinetTopMenuLayout",

  data () {
    return {
      username: '',
      is_staff: false,
      LOC_TG_SUPPORT_NAME: "",
    }
  },

  created() {
      this.loadUser();
      this.LOC_TG_SUPPORT_NAME = TG_SUPPORT_NAME;
  },

  methods: {
    fetchDrf,

    async loadUser() {
      let res = await fetchDrf(`/auth/users/me/`,
          "GET",
          {},
          "",
          undefined,
          true
      )

      if (!res.gotError){
        this.username = res.data["username"]
        this.is_staff = res.data["is_staff"]
      }
      else {
        console.log("CabinetTopMenuLayout: ошибка получения имени пользователя")
        // console.log(response.status)
        this.$router.push({ name: 'UserEnter' })
      }
    }
  }
}
</script>

<style scoped>

</style>