import {fetchDrf} from "@/common";

export async function loadWblksFromDrf(){
  let res = await fetchDrf(`/wblk/`)

  return res
}

export async function loadCostPricesFromDrf(){
  let res = await fetchDrf(`/wb-own-cost-price/`)

  return res
}

