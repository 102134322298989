<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-3">
        <h1>Регистрация</h1>
        <form>
          <div class="form-group mb-3">
            <input v-model="email" type="text" class="form-control" id="inputEmail" aria-describedby="inputEmail" placeholder="Почта">
          </div>

          <div class="form-group mb-3">
            <input v-model="password" type="password" class="form-control" id="inputPassword" aria-describedby="inputPassword" placeholder="Пароль">
          </div>

          <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
            {{ messageResult }}
          </div>

          <button @click="registerUser" type="button" class="btn btn-primary mb-3">Зарегистрироваться</button>
        </form>

        <div>
          Есть аккаунт? <router-link :to="{name: 'UserEnter'}">Войти</router-link>
        </div>
        <div>
          <router-link :to="{name: 'UserRestorePassword'}">Забыли пароль?</router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {fetchDrf} from "@/common";

export default {
  name: "UserRegister",
  data() {
      return {
        email: "",
        password: "",
        messageResult: "",
        getError: false
      }
  },
  methods: {
     async registerUser() {
        let jsonBody = {username: this.email,
                        email: this.email,
                        password: this.password,
                        re_password: this.password
                       }

        let res = await fetchDrf(`/auth/users/`,
                                 "POST",
                                 jsonBody,
                                 "Успешно зарегистрировали",
                                 201,
                                 true,
                                 false
                                )
        if (!res.gotError){
           localStorage.setItem("auth_token", res.data["auth_token"])
           this.$router.push({ name: 'WbLkList' })
        }
        else {
           this.messageResult = res.messageResult;
           this.gotError = res.gotError;
        }
    }
  }
}

</script>

<style scoped>

</style>