<template>

  <div class="container mt-3">
    <slot/>
  </div>

</template>

<script>
export default {
  name: "UserLayout"
}
</script>

<style scoped>

</style>