<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-3">
        <h1>Восстановить пароль</h1>
        <form>
          <div class="form-group mb-3">
            <input v-model="email" type="text" class="form-control" id="inputEmail" aria-describedby="inputEmail" placeholder="Почта">
          </div>

          <button @click="sendRestoreEmail" type="button" class="btn btn-primary mb-3">Отправить</button>
        </form>

        <div>
          <router-link :to="{ name: 'UserRegister'}">Регистрация</router-link>
        </div>
        <div>
          Есть аккаунт? <router-link :to="{name: 'UserEnter'}">Войти</router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserRestorePassword"
}
</script>

<style scoped>

</style>