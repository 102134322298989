<template>
  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'WbAdvList'}" class="btn btn-info my-1">← вернуться</router-link>
    </div>
  </div>

  <h1>Редактировать кампанию <a :href=getWbCampaignUrl(adv_campaign) target="_blank" > {{advert_id}} </a>  </h1>

  <div class="row g-3 align-items-center mt-1">
    <div class="col-auto">
      <button @click="startPauseAdv" type="button" class="btn btn-primary">{{getStartPauseButtonText}}</button>
    </div>
    <div class="col-auto">
      <button @click="stopAdv" type="button" class="btn btn-warning">Окончательно завершить</button>
   </div>
  </div>

  <div class="row mt-1">
    <div class="col-3">
      <div class="form-group">
        <label for="inputName">Название</label>
        <input v-model="name" type="text" class="form-control" id="inputName">
      </div>
   </div>
  </div>

  <div class="row mt-1">
    <div class="col-auto">
      <span :title=getActualityBetStr(adv_campaign) class="badge" :class=getBadgeClass(adv_campaign) >{{status_name}}</span>
    </div>
  </div>

  <div class="row mt-1" v-if="type==WB_ADV_TYPE_SEARCH">
    <div class="col-3">
      <div class="form-group">
        <label for="inputMainSearchKey">Главный ключ</label>
        <input v-model="main_search_key" type="text" class="form-control" id="inputMainSearchKey">
      </div>
   </div>
  </div>


  <div class="row g-3 align-items-center mt-1">
    <div class="col-auto">
      <input id="cbManaged" class="form-check-input me-1" type="checkbox" v-model="managed">
      <label for="cbManaged"  class="form-check-label">Управляется МПБубен</label>
   </div>
    <div class="col-auto">
      <input id="cbDoNotUpdate" class="form-check-input me-1" type="checkbox" v-model="do_not_update" disabled>
      <label for="cbDoNotUpdate"  class="form-check-label" disabled>Не обновлять при автоподгрузке</label>
   </div>
  </div>

  <div class="row g-3 align-items-center mt-1">
    <div class="col-auto">
      <label>Стратегия</label>
      <select class="form-select" v-model="strategy">
        <option v-for="cur_strategy in strategies" :value="cur_strategy.id">
          {{ cur_strategy.name }}
        </option>
      </select>
    </div>
  </div>

<!--  <div class="row g-3 align-items-center mt-1">-->
<!--    <div class="col-auto">-->
<!--      <label>Стратегия если превысили ставку</label>-->
<!--      <select class="form-select" v-model="strategy_if_exceed_bet">-->
<!--        <option v-for="cur_strategy in strategies_if_exceed_bet" :value="cur_strategy.id">-->
<!--          {{ cur_strategy.name }}-->
<!--        </option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row g-3 align-items-center mt-1">
    <div class="col-1">
      <label for="inputPosMin" class="col-form-label">Позиция с</label>
    </div>
    <div class="col-1">
      <input v-model="pos_min" type="number" class="form-control" id="inputPosMin">
    </div>
    <div class="col-auto">
      <label for="inputPosMax" class="col-form-label">по</label>
    </div>
    <div class="col-1">
      <input v-model="pos_max" type="number" class="form-control" id="inputPosMax">
    </div>
  </div>

  <div class="row g-3 align-items-center mt-1">
    <div class="col-1">
      <label for="inputBetMax" class="col-form-label">Максимальная ставка</label>
    </div>
    <div class="col-1">
      <input v-model="bet_max" type="number" class="form-control" id="inputBetMax">
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-auto">
      Ставки конкурентов:
    </div>
  </div>
  <div class="row g-3 align-items-center">
    <div class="col-12">
      <div class="d-flex flex-wrap">
          <button v-for="competitor in getCompetitorsShort" type="button" class="btn btn-outline-light"  @click="bet_max=competitor.bet">
            <span class="fw-bold " :class="getBetClass(competitor.bet, bet_max)">
              {{competitor.bet}}
            </span>
            <br/>
            <span class="badge bg-light text-secondary" >
              {{competitor.place}}
            </span>
          </button>
      </div>

    </div>
  </div>

  <div class="row g-3 align-items-center mt-1">
    <div class="col-4">
      <span class="fw-bold">Товары в рекламе</span>
      <table class="table table-borderless w-auto align-middle">
        <tbody>
          <tr v-for="(product, index) in products" :key="product.nm_id">
            <td>#{{index+1}}</td>
            <td>
              <router-link :to="{ name: 'WbOwnProductEdit', params: { id: product.wb_own_product_id }}" target="_blank" >
                <img :key="product.nm_id" :src="product.foto_link" width="40">
              </router-link>
            </td>
            <td>{{product.name}} ({{product.nm_id}})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row g-3 align-items-center">
    <div class="col-1">
      <div class="form-group">
          <span class="fw-bold">ID:</span> {{advert_id}}
      </div>
   </div>
    <div class="col-2">
      <div class="form-group">
          <span class="fw-bold">Тип:</span> {{type_name}}
      </div>
   </div>
  </div>

  <div class="row g-3 align-items-center">
    <div class="col-1" :title=getActualityBetStr(adv_campaign)>
      <div class="form-group">
          <span class="fw-bold" >Тек. ставка:</span> {{prepareOutput(adv_campaign.bet_current)}}
      </div>
   </div>
    <div class="col-2" :title=getActualityBetStr(adv_campaign)>
      <div class="form-group">
          <span class="fw-bold" >Тек. позиция:</span> {{pos_current_string}}
      </div>
   </div>
  </div>

  <div class="row g-3 align-items-center">
    <div class="col-2" :title="getActualityBetStr(adv_campaign) + '\n\n' + competitors_pos_string">
      <div class="form-group">
        <span><span class="fw-bold">Тек. конкуренты:</span> {{competitors_quantity}}</span>
      </div>
   </div>
  </div>


  <div class="row g-3 align-items-center">
    <div class="col-1">
      <div class="form-group">
          <span class="fw-bold">Созд:</span> {{dateTime(date_create)}}
      </div>
   </div>

    <div class="col-2">
      <div class="form-group">
          <span class="fw-bold">Окончание:</span> {{dateTime(date_end)}}
      </div>
   </div>
  </div>

  <div class="row g-3 align-items-center">
    <div class="col-1">
      <div class="form-group">
          <span class="fw-bold">Старт:</span> {{dateTime(date_start)}}
      </div>
   </div>
    <div class="col-2" :title=getActualityCampaignStr(adv_campaign) >
      <div class="form-group">
          <span class="fw-bold">Изменение:</span> {{dateTime(date_change)}}
      </div>
   </div>
  </div>


  <div class="row">
    <div class="col-auto">
      <button @click="updateAdvCampaign" type="button" class="btn btn-primary mt-3">Сохранить</button>
    </div>
    <div class="col-auto">
      <router-link :to="{name: 'WbAdvList'}" class="btn btn-warning mt-3">Вернуться</router-link>
    </div>
  </div>

  <div v-if="messageResult" class="alert mt-3" :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

</template>

<script>

import {
  fetchDrf,
  prepareNumber,
  prepareDate,
  getActualityCampaignStr,
  getActualityBetStr,

  WB_ADV_TYPE_CARD,
  WB_ADV_TYPE_SEARCH,
  WB_ADV_TYPE_AUTO,

  WB_ADV_STATUS_FINISHED,
  WB_ADV_STATUS_SHOWN,
  WB_ADV_STATUS_PAUSE,

  WB_ADV_STRATEGY_BET_NOT_MORE,
  WB_ADV_STRATEGY_BET_FIXED,
  WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS,
  WB_ADV_STRATEGY_POS_NOT_MORE,

  WB_ADV_STRATEGY_CHOISES,

} from "@/common";


export default {
  name: "WbAdvEdit",
  props: ['id'],
  data() {
    return {
      adv_campaign: {},
      wblk: 0,
      wblk_name: 0,
      advert_id: 0,
      name: "",
      budget: 0,
      budget_day: 0,
      type: 0,
      type_name: "",
      status: 0,
      status_name: "",
      strategy: 0,
      strategy_if_exceed_bet: 0,

      date_create: new Date(),
      date_change: new Date(),
      date_start: new Date(),
      date_end: new Date(),

      bet_current: 0,
      bet_min: 0,
      bet_max: 0,

      pos_current_min: 0,
      pos_current_max: 0,
      pos_current_string: "",

      pos_max: 0,
      pos_min: 0,

      competitors_quantity: 0,
      competitors_pos_string: "",

      products: [],
      competitors: [],
      competitors_short: [],

      main_search_key: "",

      managed: false,
      do_not_update: false,

      strategies:[],
      name_original: "",

      action_wb: "",

      WB_ADV_TYPE_CARD: 0,
      WB_ADV_TYPE_SEARCH: 0,
      WB_ADV_TYPE_AUTO: 0,

      WB_ADV_STATUS_SHOWN: 0,
      WB_ADV_STATUS_PAUSE: 0,
      WB_ADV_STATUS_FINISHED: 0,

      WB_ADV_STRATEGY_BET_NOT_MORE: 0,
      WB_ADV_STRATEGY_BET_FIXED: 0,
      WB_ADV_STRATEGY_POS_NOT_LESS: 0,
      WB_ADV_STRATEGY_POS_NOT_MORE: 0,

      WB_ADV_STRATEGY_CHOISES: [],

      messageResult: "",
      gotError: false
    }
  },

  created() {
    this.loadAdvCampaign();
    this.WB_ADV_TYPE_CARD = WB_ADV_TYPE_CARD
    this.WB_ADV_TYPE_SEARCH = WB_ADV_TYPE_SEARCH
    this.WB_ADV_TYPE_AUTO = WB_ADV_TYPE_AUTO

    this.WB_ADV_STATUS_SHOWN = WB_ADV_STATUS_SHOWN
    this.WB_ADV_STATUS_PAUSE = WB_ADV_STATUS_PAUSE
    this.WB_ADV_STATUS_FINISHED = WB_ADV_STATUS_FINISHED

    this.WB_ADV_STRATEGY_BET_NOT_MORE = WB_ADV_STRATEGY_BET_NOT_MORE
    this.WB_ADV_STRATEGY_BET_FIXED = WB_ADV_STRATEGY_BET_FIXED
    this.WB_ADV_STRATEGY_POS_NOT_LESS = WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS
    this.WB_ADV_STRATEGY_POS_NOT_MORE = WB_ADV_STRATEGY_POS_NOT_MORE

    this.WB_ADV_STRATEGY_CHOISES = WB_ADV_STRATEGY_CHOISES

    this.strategies = WB_ADV_STRATEGY_CHOISES
  },

  computed: {
    getCompetitorsShort(){
      let res = []

      if (!this.competitors) return []
      if (this.competitors.length == 0) return []

      let competitors_copy = [...this.competitors]
      competitors_copy.push(0)

      let cur_min_bet = competitors_copy[0]
      let cur_min_place = 0
      let cur_max_place = 0

      for (var i=0; i < competitors_copy.length; i++){
        let cur_competitor = {}

        if (competitors_copy[i] != cur_min_bet){

          if (cur_min_place == cur_max_place) res.push({"place": `${cur_min_place+1}`, "bet": cur_min_bet })
          else                                res.push({"place": `${cur_min_place+1}-${cur_max_place+1}`, "bet": cur_min_bet })

          cur_min_place = i
          cur_max_place = i
          cur_min_bet = competitors_copy[i]
        }
        // Если текущая ставка равна минимальной - минимальное место оставляем, а максимальное - делаем текущим
        else{
          cur_max_place = i
        }
      }

      return res
    },

    getStartPauseButtonText() {
       if (this.status == WB_ADV_STATUS_SHOWN) return "Пауза"
       else if (this.status == WB_ADV_STATUS_PAUSE) return "Запустить"
       else return "Нельзя запустить - завершено"
    },
  },

  methods: {
    prepareOutput: prepareNumber,
    dateTime: prepareDate,
    fetchDrf,
    getActualityCampaignStr,
    getActualityBetStr,

    getBetClass(competitor, bet_max){
      if (competitor > bet_max) return "text-secondary"  // text-danger text-secondary
      if (competitor == bet_max) return "text-body"   // text-warning primary text-black-50
      if (competitor < bet_max) return "text-secondary" // text-success
    },

    getWbCampaignUrl(adv_campaign){
      let url = ""

      if (adv_campaign.type == WB_ADV_TYPE_CARD){ // В карточке товара
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/carousel-auction/${adv_campaign.advert_id}`
      }
      else if (adv_campaign.type == WB_ADV_TYPE_SEARCH){
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/search/${adv_campaign.advert_id}`
      }
      else if (adv_campaign.type == WB_ADV_TYPE_AUTO){
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/auto/${adv_campaign.advert_id}`
      }
      else{
        url = `https://cmp.wildberries.ru/campaigns/list/all/`
      }

      return url
    },


    getBadgeClass(adv_campaign){
      if (adv_campaign.status == WB_ADV_STATUS_FINISHED) return "bg-danger" // Завершена
      if (adv_campaign.status == WB_ADV_STATUS_SHOWN) return "bg-success" // Идут показы
      if (adv_campaign.status == WB_ADV_STATUS_PAUSE) return "bg-warning" // На паузе
    },

    async startPauseAdv(){
      let jsonBody = {
        wblk: this.wblk,
        advert_id: this.advert_id,
      }

      // Если идут показы
      if (this.status == 9) {
        jsonBody.action_wb = "pause"
      }
      else{
        jsonBody.action_wb = "start"
      }

      // console.log(jsonBody)

      let res = await fetchDrf(`/wbadv-campaign/${this.id}/`,
                               "PUT",
                               jsonBody)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      this.loadAdvCampaign()
    },


    async stopAdv(){
      let jsonBody = {
        wblk: this.wblk,
        advert_id: this.advert_id,
        action_wb: "stop",
      }

      let res = await fetchDrf(`/wbadv-campaign/${this.id}/`,
                               "PUT",
                               jsonBody)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      this.loadAdvCampaign()
    },


    async loadAdvCampaign() {
      let searchParams = new URLSearchParams({
          action_wb: "renew",
      })

      let res = await fetchDrf(`/wbadv-campaign/${this.id}/?${searchParams}`)

      if (res.gotError) {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError
        return
      }

      this.adv_campaign = res.data
      this.wblk = res.data["wblk"]
      this.wblk_name = res.data["wblk_name"]
      this.advert_id = res.data["advert_id"]
      this.name = res.data["name"]
      this.budget = res.data["budget"]
      this.budget_day = res.data["budget_day"]
      this.type = res.data["type"]
      this.type_name = res.data["type_name"]
      this.type = res.data["type"]
      this.status = res.data["status"]
      this.status_name = res.data["status_name"]
      this.strategy = res.data["strategy"]
      this.strategy_if_exceed_bet = res.data["strategy_if_exceed_bet"]

      this.date_create = res.data["date_create"]
      this.date_change = res.data["date_change"]
      this.date_start = res.data["date_start"]
      this.date_end = res.data["date_end"]

      this.bet_current = res.data["bet_current"]
      this.bet_min = res.data["bet_min"]
      this.bet_max = res.data["bet_max"]

      this.pos_current_min = res.data["pos_current_min"]
      this.pos_current_max = res.data["pos_current_max"]
      this.pos_current_string = res.data["pos_current_string"]

      this.pos_max = res.data["pos_max"]
      this.pos_min = res.data["pos_min"]

      this.competitors_quantity = res.data["competitors_quantity"]
      this.competitors_pos_string = res.data["competitors_pos_string"]
      this.competitors_pos_json = res.data["competitors_pos_json"]
      this.products = res.data["products"]

      this.managed = res.data["managed"]
      this.do_not_update = res.data["do_not_update"]
      this.main_search_key = res.data["main_search_key"]

      this.competitors = JSON.parse(this.competitors_pos_json)

      // console.log(this.competitors)

      // Пусть сохранится отдельно
      this.name_original = this.name
    },

    async updateAdvCampaign() {
      if (this.name_original != this.name){
        this.action_wb="rename"
      }


      let jsonBody = {
        wblk: this.wblk,
        advert_id: this.advert_id,
        name: this.name,
        strategy: this.strategy,
        strategy_if_exceed_bet: this.strategy_if_exceed_bet,
        bet_min: this.bet_min,
        bet_max: this.bet_max,
        pos_min: this.pos_min,
        pos_max: this.pos_max,
        managed: this.managed,
        main_search_key: this.main_search_key,
        action_wb: this.action_wb,
      }

      // console.log("jsonBody")
      // console.log(jsonBody)

      let res = await fetchDrf(`/wbadv-campaign/${this.id}/`,
                               "PUT",
                               jsonBody)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      await this.loadAdvCampaign()
    }
  }
}
</script>

<style scoped>

</style>