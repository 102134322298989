<template>
  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'WbOwnRReportList'}" class="btn btn-info my-1">← вернуться</router-link>
    </div>
  </div>

 <h4>Финансовый отчет № {{wb_rreport.wb_id}}</h4>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 11">
    <div v-if="toastIsShowed" class="toast show" :class="toastClass" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" :data-bs-delay="toastDelay">
      <div class="toast-header">
        <span class="w-100">{{ toastMessage }}</span>
        <button type="button" class="btn-close float-end" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>

  <span class="text-secondary">
    {{prepareDate(wb_rreport.date_from)}} - {{prepareDate(wb_rreport.date_to)}}
  </span>

  <div class="row mt-2">
    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedBrand" @change="selectChange" title="Бренд" >
        <option value="" selected="selected">
          Все бренды
        </option>

        <option v-for="brand in brands" :value="brand.id">
          {{ brand.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedSubject" @change="selectChange" title="Предмет">
        <option value="" selected="selected">
          Все предметы
        </option>
        <option v-for="subject in subjects" :value="subject.id">
          {{ subject.name }}
        </option>
      </select>
    </div>

    <div class="col-sm-auto mt-2">
      <select class="form-select" v-model="selectedProduct" @change="selectChange" data-bs-toggle="tooltip" title="Фильтр по товару">
        <option selected="selected" value="">
          Все товары
        </option>
        <option v-for="product in products" :value="product.id" :title="product.name + ' / '+ product.color">
          {{ product.nm_id }} {{product.name.slice(0,10)}}...
        </option>
      </select>
    </div>

  </div>

  <div class="row mt-2" v-if="arrTableEmptyCostPrices.length" >
    <div class="col-sm-auto mt-2">
      <a href="#" class="link-danger fw-bold" @click="downloadEmptyCostPrices"><i class="bi bi-exclamation-triangle"></i>Товаров c нулевой себестоимостью - {{arrTableEmptyCostPrices.length}}</a>
      <span class="ms-2 text-secondary">(<a href="#" @click="downloadEmptyCostPrices">Скачайте</a> файл, заполните колонку "Себестоимость" и импортируйте его на странице <router-link :to="{ name: 'WbOwnCostPriceList'}" target="_blank">Себестоимость</router-link> за любую дату ранее {{prepareDate(wb_rreport.date_from)}})</span>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-auto">
      <span>Проверка отчета</span>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered border-secondary table-hover">
            <tbody>
              <tr>
                <td class="table-primary fw-bold">До вычета комиссии</td>
                <td class="table-primary fw-bold">Сумма</td>
                <td class="table-primary fw-bold">Количество</td>
              </tr>
              <tr>
                <td >Продажа</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_sales_and_comp_defects_with_disc, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_sales_and_comp_defects, 0)}}</td>
              </tr>
              <tr>
                <td >Возврат</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_returns_with_disc, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_returns, 0)}}</td>
              </tr>

              <tr>
                <td class="table-primary fw-bold" colspan="3">После вычета комиссии</td>
              </tr>
              <tr>
                <td>Продажа</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_sales_and_comp_defects_for_pay, 0)}}</td>
              </tr>
              <tr>
                <td>Возврат</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_returns_for_pay, 0)}}</td>
              </tr>

              <tr>
                <td class="table-primary fw-bold">Комиссия</td>
                <td class="table-primary fw-bold text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_comission_wb, 0)}}</td>
                <td class="table-primary fw-bold text-end">{{prepareNumber(wb_rreport.fin_metrics.percent_comission_wb, 0)}}%</td>
              </tr>
              <tr>
                <td>Оплата брака</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_comp_defects, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_comp_defects, 0)}}</td>
              </tr>
              <tr>
                <td>Оплата потерянного товара</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_comp_lost_products, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_comp_lost_goods, 0)}}</td>
              </tr>
              <tr>
                <td>Возмещение издержек по перевозке</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_comp_transport_cost, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_comp_transport_cost, 0)}}</td>
              </tr>

              <tr>
                <td class="fw-bold">Продано ВБ</td>
                <td class="text-end fw-bold" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_sales_and_comp_defects_minus_returns_real, 0)}}</td>
              </tr>

              <tr>
                <td class="table-primary fw-bold">К перечислению за товар</td>
                <td class="table-primary text-end fw-bold" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_payment_for_products, 0)}}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered border-secondary table-hover">
            <tbody>
              <tr>
                <td class="table-primary fw-bold">Логистика</td>
                <td class="table-primary fw-bold">Сумма</td>
                <td class="table-primary fw-bold">Количество</td>
              </tr>

              <tr>
                <td class="fw-bold">Скачать детализацию</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_logistics, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.quantity_logistics, 0)}}</td>
              </tr>


              <tr>
                <td>Штрафы</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_penalty, 0)}}</td>
              </tr>
              <tr>
                <td>Доплаты</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_additional_payment, 0)}}</td>
              </tr>
              <tr>
                <td>Хранение <span >(щелкните цифру для редактирования)</span></td>
                <td class="text-end" >
                  <editable-cell
                      type="float"
                      :value="wb_rreport.fin_metrics.sum_fee_storage"
                      :displayedValue="prepareNumber(wb_rreport.fin_metrics.sum_fee_storage, 0)"
                      @input="updateRReportValue(id, wb_rreport.fin_metrics.sum_fee_storage, $event, 'sum_fee_storage')" />
                </td>
                <td class="text-end">
<!--                  <router-link :to="{ name: 'WbOwnRReportDistrExpenses', params: { id: id }}" class="link-primary"><i class="bi bi-pencil-square"></i></router-link>-->
                </td>
              </tr>

              <tr>
                <td>Стоимость платной приемки <span >(щелкните цифру для редактирования)</span></td>
                <td class="text-end">
                  <editable-cell
                      type="float"
                      :value="wb_rreport.fin_metrics.sum_fee_paid_receiving"
                      :displayedValue="prepareNumber(wb_rreport.fin_metrics.sum_fee_paid_receiving, 0)"
                      @input="updateRReportValue(id, wb_rreport.fin_metrics.sum_fee_paid_receiving, $event, 'sum_fee_paid_receiving')" />
                </td>
                <td class="text-end">
<!--                  <router-link :to="{ name: 'WbOwnRReportDistrExpenses', params: { id: id }}" class="link-primary"><i class="bi bi-pencil-square"></i></router-link>-->
                </td>
              </tr>

              <tr>
                <td>Прочие удержания <span >(щелкните цифру для редактирования)</span></td>
                <td class="text-end">
                  <editable-cell
                      type="float"
                      :value="wb_rreport.fin_metrics.sum_fee_other"
                      :displayedValue="prepareNumber(wb_rreport.fin_metrics.sum_fee_other, 0)"
                      @input="updateRReportValue(id, wb_rreport.fin_metrics.sum_fee_other, $event, 'sum_fee_other')" />
                </td>
                <td class="text-end">
<!--                  <router-link :to="{ name: 'WbOwnRReportDistrExpenses', params: { id: id }}" class="link-primary"><i class="bi bi-pencil-square"></i></router-link>-->
                </td>
              </tr>

              <tr>
                <td class="table-primary fw-bold">Итого к оплате</td>
                <td class="table-primary text-end fw-bold" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_payment_total, 0)}}</td>
              </tr>

              <tr>
                <td>Себестоимость товара</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_products_cost, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.percent_products_cost)}}%</td>
              </tr>


              <tr>
                <td>Стоимость рекламы</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_adv_cost, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.percent_adv_cost)}}%</td>
              </tr>


              <tr>
                <td class="fw-bold">Валовая прибыль</td>
                <td class="text-end fw-bold" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_gross_profit, 0)}}</td>
              </tr>

              <tr>
                <td>Налоги</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.sum_taxes, 0)}}</td>
                <td class="text-end">{{prepareNumber(wb_rreport.fin_metrics.percent_taxes, 0)}}%</td>
              </tr>

              <tr>
                <td class="table-primary fw-bold">Итоговая прибыль</td>
                <td class="table-primary text-end fw-bold" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.sum_profit, 0)}}</td>

              </tr>

              <tr>
                <td class="fw-bold">Рентабельность вложений</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.percent_roi, 0)}}%</td>
              </tr>

              <tr>
                <td class="fw-bold">Норма прибыли</td>
                <td class="text-end" colspan="2">{{prepareNumber(wb_rreport.fin_metrics.percent_profit, 0)}}%</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


  <div class="row mt-4">
    <div class="col-auto">
      <h5>Стоимость хранения</h5>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <UniversalTable
        :items="arrFeeStorageBarcodesReport"
        :allHeaders="allHeadersFeeStorageBarcodesReport"
        :arrTable="arrFeeStorageBarcodesReport"
        :showTotalRow="true"
        localStorageHeadersName="WbOwnRReportEdit_headersFeeStorageBarcodesReport"
        tableClass="table-striped table-bordered border-light table-hover"
        tableTheadClass="opacity-100 bg-white"
        excelFileName="fee_storage_table"
      >
      </UniversalTable>
    </div>
  </div>


  <div class="row mt-4">
    <div class="col-auto">
      <h5>Продажи по складам</h5>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <UniversalTable
        :items="arrTableSalesWhReport"
        :allHeaders="allHeadersSalesWhReport"
        :arrTable="arrTableSalesWhReport"
        :showTotalRow="true"
        localStorageHeadersName="WbOwnRReportEdit_headersSalesWhReport"
        tableClass="table-striped table-bordered border-light table-hover"
        tableTheadClass="opacity-100 bg-white"
        excelFileName="sales_wh_table"
      >
      </UniversalTable>
    </div>
  </div>


  <div class="row mt-1">
      <div class="col-auto">
        <div class="input-group">
          <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр"
                 aria-describedby="btnClearFilter">
          <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X
          </button>
        </div>
      </div>
  </div>


  <div class="row mt-2">
    <div class="col-auto">
      <h5>Сводный отчет</h5>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <UniversalTable
        :items="filteredRowsGeneralReport"
        :allHeaders="allHeadersGeneralReport"
        :arrTable="arrTableGeneralReport"
        :showTotalRow="true"
        localStorageHeadersName="WbOwnRReportEdit_headersGeneralReport"
        tableClass="table-striped table-bordered border-light table-hover"
        tableTheadClass="opacity-100 bg-white"
        excelFileName="svod_table"
      >
      </UniversalTable>
    </div>
  </div>


  <div class="row mt-5">
    <div class="col-auto">
      <h5>Продажи</h5>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <UniversalTable
        :items="filteredRowsSalesReport"
        :allHeaders="allHeadersSalesReport"
        :arrTable="arrTableSalesReport"
        :showTotalRow="true"
        localStorageHeadersName="WbOwnRReportEdit_headersSalesReport"
        tableClass="table-striped table-bordered border-light table-hover"
        tableTheadClass="opacity-100 bg-white"
        excelFileName="prod_table"
      >
      </UniversalTable>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-auto">
      <h5>Возвраты</h5>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <UniversalTable
        :items="filteredRowsReturnsReport"
        :allHeaders="allHeadersReturnsReport"
        :arrTable="arrTableReturnsReport"
        :showTotalRow="true"
        localStorageHeadersName="WbOwnRReportEdit_headersReturnsReport"
        tableClass="table-striped table-bordered border-light table-hover"
        tableTheadClass="opacity-100 bg-white"
        excelFileName="vozvr_table"
      >
      </UniversalTable>
    </div>
  </div>


</template>

<script>
import UniversalTable from "@/components/UniversalTable";
import EditableCell from "@/components/EditableCell";

import {
  fetchDrf,
  showMessageResult,
  prepareDate,
  prepareNumber,
  prepareCell,
  getWbUrlByNmId,
  exportToExcel,
} from "@/common";



export default {
  name: "WbOwnRReportEdit",
  props: ['id'],

  components: {
    UniversalTable,
    EditableCell,
  },

  data() {
    return {
      wb_rreport: {},
      brands: [],
      subjects: [],
      products: [],

      selectedBrand: "",
      selectedSubject: "",
      selectedProduct: "",

      allHeadersGeneralReport: [],
      arrTableGeneralReport: [],

      allHeadersSalesReport: [],
      arrTableSalesReport: [],

      allHeadersSalesWhReport: [],
      arrTableSalesWhReport: [],

      allHeadersReturnsReport: [],
      arrTableReturnsReport: [],

      allHeadersEmptyCostPrices: ['Артикул WB', 'Баркод', 'Себестоимость', 'Примечание (не учитывается)'],
      arrTableEmptyCostPrices: [],

      allHeadersFeeStorageBarcodesReport: [],
      arrFeeStorageBarcodesReport: [],


      filterString: "",

      // filterStringGeneralReport: "",
      // filterStringSalesReport: "",
      // filterStringReturnsReport: "",

      COL_OWN_PRODUCT_ID: 'own_product_id',
      COL_FOTO: 'foto_link',
      COL_NAME: 'name',
      COL_BRAND: 'brand',

      COL_ARTICLE_WB: 'nm_id',
      COL_ARTICLE: 'article',
      COL_SIZE: 'size',
      COL_BARCODE: 'barcode',
      COL_COST_PRICE_PER_ONE: 'cost_price_per_one',
      COL_QUANTITY_SALES: 'quantity_sales',
      COL_QUANTITY_RETURNS: 'quantity_returns',

      COL_SALES_WITH_DISC: 'sum_sales_with_disc', // Для продаж по складам
      COL_SALES_AND_COMP_DEFECTS_WITH_DISC: 'sum_sales_and_comp_defects_with_disc', // Для всего остального
      COL_RETURNS_WITH_DISC: 'sum_returns_with_disc',

      COL_SALES_FOR_PAY: 'sum_sales_for_pay',
      COL_RETURNS_FOR_PAY: 'sum_returns_for_pay',

      COL_SUM_COMP_DEFECTS: 'sum_comp_defects',
      COL_SUM_COMP_LOST_PRODUCTS: 'sum_comp_lost_products',
      COL_SUM_COMP_TRANSPORT_COST: 'sum_comp_transport_cost',
      COL_SUM_PAYMENT_FOR_PRODUCTS: 'sum_payment_for_products',

      COL_SUM_PRODUCTS_COST: 'sum_products_cost',
      COL_SUM_COST_PRICE_SALES: 'sum_cost_price_sales',
      COL_SUM_COST_PRICE_RETURNS: 'sum_cost_price_returns',

      COL_SUM_LOGISTICS: 'sum_logistics',
      COL_SUM_PENALTY: 'sum_penalty',
      COL_SUM_ADDITIONAL_PAYMENT: 'sum_additional_payment',
      COL_SUM_GROSS_PROFIT: 'sum_gross_profit',
      COL_SUM_PAYMENT_TOTAL: 'sum_payment_total',
      COL_PERCENT_ROI: 'percent_roi',
      COL_PERCENT_PROFIT: 'percent_profit',
      COL_PERCENT_GROSS_ROI: 'percent_gross_roi',

      COL_SUM_ADV_COST: 'sum_adv_cost',
      COL_PERCENT_ADV_COST: 'percent_adv_cost',

      COL_WH_NAME: 'wh_name',
      COL_PERCENT_SUM_SALES: 'percent_sum_sales',
      COL_PERCENT_QUANTITY_SALES: 'percent_quantity_sales',

      COL_VOLUME_PER_ONE: 'volume_per_one',
      COL_VOLUME_TOTAL: 'volume_total',
      COL_QUANTITY: 'quantity',
      COL_SUM: 'sum',
      COL_PERCENT_SUM: 'percent_sum',

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",
      toastClass: "",

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    this.allHeadersGeneralReport.push({id: this.COL_FOTO, name: "Фото", type: "foto", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allHeadersGeneralReport.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", type: "nm_id", active: true})
    this.allHeadersGeneralReport.push({id: this.COL_NAME, name: "Название", active: true})
    this.allHeadersGeneralReport.push({id: this.COL_BRAND, name: "Бренд"})
    this.allHeadersGeneralReport.push({id: this.COL_ARTICLE, name: "Артикул"})
    this.allHeadersGeneralReport.push({id: this.COL_SIZE, name: "Размер"})
    this.allHeadersGeneralReport.push({id: this.COL_BARCODE, name: "Баркод"})
    this.allHeadersGeneralReport.push({id: this.COL_COST_PRICE_PER_ONE, name: "Себестоимость единицы товара", type: "int", need_fill: true, active: true}) // float
    this.allHeadersGeneralReport.push({id: this.COL_QUANTITY_SALES, name: "Продажи, шт", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_QUANTITY_RETURNS, name: "Возвраты, шт", type: "int", totalRow: true, active: true})

    // this.allHeadersGeneralReport.push({id: this.COL_SALES_WITH_DISC, name: "Продажи до вычета комиссии", type: "float", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SALES_AND_COMP_DEFECTS_WITH_DISC, name: "Продажи до вычета комиссии", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_RETURNS_WITH_DISC, name: "Возвраты до вычета комиссии", type: "int", totalRow: true, active: true})

    this.allHeadersGeneralReport.push({id: this.COL_SALES_FOR_PAY, name: "Продажи после вычета комиссии", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_RETURNS_FOR_PAY, name: "Возвраты после вычета комиссии", type: "int", totalRow: true, active: true})

    this.allHeadersGeneralReport.push({id: this.COL_SUM_COMP_DEFECTS, name: "Оплата брака", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_COMP_LOST_PRODUCTS, name: "Оплата потерянного товара", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_COMP_TRANSPORT_COST, name: "Возмещение издержек по перевозке", type: "int", totalRow: true, active: true})

    this.allHeadersGeneralReport.push({id: this.COL_SUM_PAYMENT_FOR_PRODUCTS, name: "К перечислению за товар", type: "int", hint: "[Продажи после вычета комиссии] + [Оплата брака] - [Возвраты после вычета комиссии]", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_LOGISTICS, name: "Логистика", type: "int", totalRow: true, active: true})

    this.allHeadersGeneralReport.push({id: this.COL_SUM_ADV_COST, name: "Стоимость рекламы", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_PERCENT_ADV_COST, name: "% расходов на рекламу", type: "int", active: true})

    this.allHeadersGeneralReport.push({id: this.COL_SUM_PENALTY, name: "Штрафы", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_ADDITIONAL_PAYMENT, name: "Доплаты", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_PAYMENT_TOTAL, name: "Итого к оплате", type: "int", hint: "[К перечислению за товар] - [Логистика] - [Штрафы] + [Доплаты] - [Хранение] - [Платная приемка] - Прочие удержания", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_PRODUCTS_COST, name: "Себестоимость товара", type: "int", totalRow: true, active: true})
    this.allHeadersGeneralReport.push({id: this.COL_SUM_GROSS_PROFIT, name: "Валовая прибыль",  type: "int", hint: "[Итого к оплате] - [Себестоимость товара]", totalRow: true, active: true })
    this.allHeadersGeneralReport.push({id: this.COL_PERCENT_ROI, name: "Рентабельность вложений, %", type: "int", hint: "[Итоговая прибыль] / [Себестоимость товара]", active: true})
    this.allHeadersGeneralReport.push({id: this.COL_PERCENT_GROSS_ROI, name: "Валовая рентабельность, %", type: "int", hint: "[Валовая прибыль] / [Себестоимость товара]", active: true})
    this.allHeadersGeneralReport.push({id: this.COL_PERCENT_PROFIT, name: "Норма прибыли, %", type: "int", hint: "[Итоговая прибыль] / ( [Продажи до вычета комиссии] - [Возвраты до вычета комиссии] )", active: true})


    this.allHeadersSalesReport.push({id: this.COL_FOTO, name: "Фото", type: "foto", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allHeadersSalesReport.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", type: "nm_id", active: true})
    this.allHeadersSalesReport.push({id: this.COL_NAME, name: "Название", active: true})
    this.allHeadersSalesReport.push({id: this.COL_BRAND, name: "Бренд", active: true})
    this.allHeadersSalesReport.push({id: this.COL_ARTICLE, name: "Артикул", active: true})
    this.allHeadersSalesReport.push({id: this.COL_SIZE, name: "Размер", active: true})
    this.allHeadersSalesReport.push({id: this.COL_BARCODE, name: "Баркод", active: true})
    this.allHeadersSalesReport.push({id: this.COL_COST_PRICE_PER_ONE, name: "Себестоимость единицы товара", type: "int", need_fill: true, active: true})
    this.allHeadersSalesReport.push({id: this.COL_QUANTITY_SALES, name: "Продажи, шт", type: "int", totalRow: true, active: true})
    // this.allHeadersSalesReport.push({id: this.COL_SALES_WITH_DISC, name: "Продажи до вычета комиссии", type: "float", totalRow: true, active: true})
    this.allHeadersSalesReport.push({id: this.COL_SALES_AND_COMP_DEFECTS_WITH_DISC, name: "Продажи до вычета комиссии", type: "int", totalRow: true, active: true})
    this.allHeadersSalesReport.push({id: this.COL_SALES_FOR_PAY, name: "Продажи после вычета комиссии", type: "int", totalRow: true, active: true})

    this.allHeadersSalesReport.push({id: this.COL_SUM_ADV_COST, name: "Стоимость рекламы", type: "int", totalRow: true, active: true})
    this.allHeadersSalesReport.push({id: this.COL_PERCENT_ADV_COST, name: "% расходов на рекламу", type: "int", active: true})

    this.allHeadersSalesReport.push({id: this.COL_SUM_PAYMENT_FOR_PRODUCTS, name: "К перечислению за товар", type: "int", totalRow: true, hint: "[Продажи после вычета комиссии] + [Оплата брака] - [Возвраты после вычета комиссии]", active: true})
    this.allHeadersSalesReport.push({id: this.COL_SUM_COST_PRICE_SALES, name: "Себестоимость товара продажи", type: "int", totalRow: true, active: true})

    // Продажи по складам
    this.allHeadersSalesWhReport.push({id: this.COL_WH_NAME, name: "Склад", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allHeadersSalesWhReport.push({id: this.COL_QUANTITY_SALES, name: "Количество", type: "int", active: true, totalRow: true})
    this.allHeadersSalesWhReport.push({id: this.COL_SALES_WITH_DISC, name: "Сумма", type: "int", active: true, totalRow: true})
    this.allHeadersSalesWhReport.push({id: this.COL_PERCENT_SUM_SALES, name: "% от количества всех продаж", type: "int"})
    this.allHeadersSalesWhReport.push({id: this.COL_PERCENT_QUANTITY_SALES, name: "% от суммы всех продаж", type: "int", active: true})


    this.allHeadersReturnsReport.push({id: this.COL_FOTO, name: "Фото", type: "foto", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allHeadersReturnsReport.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", type: "nm_id", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_NAME, name: "Название", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_BRAND, name: "Бренд", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_ARTICLE, name: "Артикул", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_SIZE, name: "Размер", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_BARCODE, name: "Баркод", active: true})
    this.allHeadersReturnsReport.push({id: this.COL_COST_PRICE_PER_ONE, name: "Себестоимость единицы товара", type: "int", need_fill: true, active: true})
    this.allHeadersReturnsReport.push({id: this.COL_QUANTITY_RETURNS, name: "Возвраты, шт", type: "int", totalRow: true, active: true})
    this.allHeadersReturnsReport.push({id: this.COL_RETURNS_WITH_DISC, name: "Возвраты до вычета комиссии", type: "int", totalRow: true, active: true})
    this.allHeadersReturnsReport.push({id: this.COL_RETURNS_FOR_PAY, name: "Возвраты после вычета комиссии", type: "int", totalRow: true, active: true})
    this.allHeadersReturnsReport.push({id: this.COL_SUM_PAYMENT_FOR_PRODUCTS, name: "К перечислению за товар", type: "int", hint: "=[Возвраты после вычета комиссии]", totalRow: true, active: true})
    this.allHeadersReturnsReport.push({id: this.COL_SUM_COST_PRICE_RETURNS, name: "Себестоимость товара возвраты", type: "int", totalRow: true, active: true})


    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_FOTO, name: "Фото", type: "foto", active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", type: "nm_id", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_NAME, name: "Название", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_ARTICLE, name: "Артикул", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_SIZE, name: "Размер", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_BARCODE, name: "Баркод", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_VOLUME_PER_ONE, name: "Объем 1 шт", type: "float", active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_QUANTITY, name: "Количество", type: "int", hint: "шт., на конец недели", totalRow: true, active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_VOLUME_TOTAL, name: "Объем всего", type: "int", hint: "л., на конец недели", totalRow: true, active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_SUM, name: "Затраты на хранение", type: "int", totalRow: true, active: true})
    this.allHeadersFeeStorageBarcodesReport.push({id: this.COL_PERCENT_SUM, name: "% от всех затрат", type: "int", hint: "= Затраты на хранение данного баркода / Затраты на хранение всех баркодов", active: true})

    // for (let i = 0; i < this.allHeadersReturnsReport.length; i++){
    //   this.allHeadersReturnsReport[i]['active'] = true
    // }


    this.wb_rreport = []
    this.wb_rreport.fin_metrics = []
    await this.loadRReport();

    document.title = `Финансовый отчет № ${this.wb_rreport.wb_id} (${prepareDate(this.wb_rreport.date_from)} - ${prepareDate(this.wb_rreport.date_to)}) `

  },

  computed: {
    filteredRowsGeneralReport() {
      return this.arrTableGeneralReport.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const article = row[this.COL_ARTICLE] ? row[this.COL_ARTICLE].toString().toLowerCase() : "";
        const articleWb = row[this.COL_ARTICLE_WB] ? row[this.COL_ARTICLE_WB].toString().toLowerCase() : "";
        const name = row[this.COL_NAME] ? row[this.COL_NAME].toString().toLowerCase() : "";
        const barcode = row[this.COL_BARCODE] ? row[this.COL_BARCODE].toString().toLowerCase() : "";

        let bTextFilterOk = false

        if ( article.includes(filterString) || articleWb.includes(filterString) || name.includes(filterString) || barcode.includes(filterString)  ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },

    filteredRowsSalesReport() {
      return this.arrTableSalesReport.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const article = row[this.COL_ARTICLE].toString().toLowerCase();
        const articleWb = row[this.COL_ARTICLE_WB].toString().toLowerCase();
        const name = row[this.COL_NAME].toString().toLowerCase();
        const barcode = row[this.COL_BARCODE].toString().toLowerCase();

        let bTextFilterOk = false

        if ( article.includes(filterString) || articleWb.includes(filterString) || name.includes(filterString) || barcode.includes(filterString)  ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },

    filteredRowsReturnsReport() {
      return this.arrTableReturnsReport.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const article = row[this.COL_ARTICLE].toString().toLowerCase();
        const articleWb = row[this.COL_ARTICLE_WB].toString().toLowerCase();
        const name = row[this.COL_NAME].toString().toLowerCase();
        const barcode = row[this.COL_BARCODE].toString().toLowerCase();

        let bTextFilterOk = false

        if ( article.includes(filterString) || articleWb.includes(filterString) || name.includes(filterString) || barcode.includes(filterString)  ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },

  },

  methods:{
    fetchDrf,
    showMessageResult,
    prepareDate,
    prepareNumber,
    prepareCell,
    getWbUrlByNmId,
    exportToExcel,

    async selectChange(){
      await this.loadRReport()
    },

    downloadEmptyCostPrices(){
      exportToExcel(this.arrTableEmptyCostPrices, this.allHeadersEmptyCostPrices, "import_cost_prices")
    },

    async loadRReport() {
      let searchParams = new URLSearchParams({
          brand: this.selectedBrand,
          subject: this.selectedSubject,
          product: this.selectedProduct,
          get_reports: true,
      })

      let res = await fetchDrf(`/wb-own-rreport/${this.id}/?${searchParams}`)

      if (!res.gotError) {
        this.wb_rreport = res.data

        this.brands = res.data.brands
        this.subjects = res.data.subjects
        this.products = res.data.products

        this.arrTableGeneralReport = res.data.general_report
        this.arrTableSalesReport = res.data.sales_report
        this.arrTableReturnsReport = res.data.returns_report
        this.arrTableEmptyCostPrices = res.data.empty_cost_prices
        this.arrTableSalesWhReport = res.data.sales_wh_report
        this.arrFeeStorageBarcodesReport = res.data.fee_storage_barcodes_report

        console.log("arrFeeStorageBarcodesReport")
        console.log(this.arrFeeStorageBarcodesReport)

        this.prepareTableReportGeneral()
        this.prepareTableReportSales()
        this.prepareTableReportReturns()
      }
      else {
        showMessageResult(res, this)
      }
    },

    prepareTableReportGeneral(){
    },

    prepareTableReportSales(){
    },

    prepareTableReportReturns(){
    },

    async updateRReportValue(id, oldValue, newValue, attr_name){

      if ( typeof (newValue) == 'object' ) return

      if (oldValue == newValue){
        return
      }

      newValue = parseFloat(newValue)


      let jsonBody = {}

      jsonBody[attr_name] = newValue

      let res = await fetchDrf(`/wb-own-rreport/${id}/`,
          "PUT",
          jsonBody
      )

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      this.showToast(res.messageResult, res.gotError)

      await this.loadRReport()
    },


    showToast(toastMessage, gotError) {
      if (gotError) this.toastClass = "bg-danger"
      else this.toastClass = "bg-success"

      this.toastMessage = toastMessage
      this.toastIsShowed = true

      setTimeout(() => {
        this.toastIsShowed = false
      }, this.toastDelay)
    },

  }
}
</script>

<style scoped>

</style>