<template>

  <div class="row">
    <div class="col-auto">
      <h1>Текущие ставки рекламы в поиске</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <span class="text-secondary">Работает в тестовом режиме. Показывает только ставки, а не реальные позиции</span>
    </div>
  </div>

  <div v-if="messageResult" class="alert mt-2" :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />


  <div class="row g-3 align-items-center mt-2">
    <div class="col-sm-auto mt-3">
      <input v-model="keyword" type="text" class="form-control" v-on:keyup.enter="showToast(this, 'Сделали запрос, подождите несколько секунд...', false, 10000); makeAdvBetsRequestAndFollow(keyword)">
    </div>

    <div class="col-sm-auto mt-3">
      <button @click="showToast(this, 'Сделали запрос, подождите несколько секунд...', false, 10000); makeAdvBetsRequestAndFollow(keyword)" type="button" class="btn btn-primary">Сделать запрос</button>
    </div>


    <div class="col-1 mt-3">
    </div>

    <div class="col-sm-auto mt-3">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

<!--    <div class="col-auto">-->
<!--      <input id="cbAuto" class="form-check-input" type="checkbox" v-model="bShowAuto" @change="saveOptions">-->
<!--      <label for="cbAuto" class="form-check-label mx-1">Автоматический</label>-->
<!--    </div>-->
<!--    <div class="col-auto">-->
<!--      <input id="cbShowManual" class="form-check-input" type="checkbox" v-model="bShowManual" @change="saveOptions">-->
<!--      <label for="cbShowManual" class="form-check-label mx-1">Ручной</label>-->
<!--    </div>-->
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <universal-table
        :items="filteredRows"
        :allHeaders="allColumns"
        :localStorageHeadersName="'WbAdvBestRequestList_headers'"
        :arrTable="arrTable"
        :showTotalRow="true"
        tableClass="table-striped table-bordered border-light table-sm table-hover"
        @data-updated="requests"
      >

      </universal-table>
    </div>
  </div>


</template>

<script>
import {
  fetchDrf,
  makeAdvBetsRequestAndFollow,
  showToast,
  WB_ADV_REQUEST_TYPE_MANUAL,
  WB_ADV_REQUEST_TYPE_AUTO,
  COL_ADV_BETS_REQUEST_ID,
  COL_RENEW_ADV,
} from "@/common";

import UniversalTable from "@/components/UniversalTable";
import Toast from "@/components/Toast";

export default {
  name: "WbAdvBetsRequestList",

  components: {
    UniversalTable,
    Toast,
  },

  data() {
    return {
      region: 0, // По умолчанию "Все регионы"
      regions: [],
      keyword: "",
      maxAdvertPosition: 5,
      bCollectCompetitorsDetails: false,
      bShowAuto: true,
      bShowManual: true,
      WB_ADV_REQUEST_TYPE_MANUAL: 0,
      WB_ADV_REQUEST_TYPE_AUTO: 0,

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      arrTable:[],
      allColumns: [],
      filterString: "",

      COL_DATE_REQUEST: 'date_request',
      COL_KEYWORD: 'keyword',
      COL_REQUEST_TYPE: 'request_type_name',
      COL_ADVERT: 'advert',

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "Текущие ставки рекламы в поиске"
    this.WB_ADV_REQUEST_TYPE_MANUAL = WB_ADV_REQUEST_TYPE_MANUAL;
    this.WB_ADV_REQUEST_TYPE_AUTO = WB_ADV_REQUEST_TYPE_AUTO;
    this.COL_ADV_BETS_REQUEST_ID = COL_ADV_BETS_REQUEST_ID;
    this.COL_RENEW_ADV = COL_RENEW_ADV;

    this.allColumns.push({id: this.COL_KEYWORD, name: "Запрос", type: this.COL_ADV_BETS_REQUEST_ID, active: true, class: "position-sticky start-0 top-0 opacity-100 bg-white"})
    this.allColumns.push({id: this.COL_DATE_REQUEST, name: "Дата", type: "date", active: true})
    // this.allColumns.push({id: this.COL_REQUEST_TYPE, name: "Тип", active: true})

    for (let i = 0; i < this.maxAdvertPosition; i++){
      this.allColumns.push({id: `${this.COL_ADVERT}${i}`, name: `Место ${i+1}`, type: "advert", active: true})
    }

    this.allColumns.push({id: this.COL_RENEW_ADV, name: "Обновить", type: this.COL_RENEW_ADV, active: true})

    await this.restoreOptions()

    await this.loadRegions();
    await this.loadWbAdvBetsRequests();

  },

  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {
        const filterString = this.filterString.toString().toLowerCase().trim();

        if (!row['keyword']) return false

        const keyword = row['keyword'].toString().toLowerCase();
        const request_type = row['request_type'];

        let bTextFiltersOk = false

        if ( keyword.includes(filterString) ){
          bTextFiltersOk = true
        }

        let bRequestTypeFilterOk = false
        if ( (request_type == WB_ADV_REQUEST_TYPE_MANUAL && this.bShowManual) || (request_type == WB_ADV_REQUEST_TYPE_AUTO && this.bShowAuto) ){
          bRequestTypeFilterOk = true
        }

        return bTextFiltersOk && bRequestTypeFilterOk
      });
    }
  },

  methods: {
    fetchDrf,
    makeAdvBetsRequestAndFollow,
    showToast,

    restoreOptions(){
      if(localStorage.getItem("WbAdvBetsRequestList_bShowAuto"))    this.bShowAuto = JSON.parse(localStorage.getItem("WbAdvBetsRequestList_bShowAuto"));
      if(localStorage.getItem("WbAdvBetsRequestList_bShowManual"))  this.bShowManual = JSON.parse(localStorage.getItem("WbAdvBetsRequestList_bShowManual"));
    },

    saveOptions(){
     localStorage.setItem("WbAdvBetsRequestList_bShowAuto", JSON.stringify(this.bShowAuto));
     localStorage.setItem("WbAdvBetsRequestList_bShowManual", JSON.stringify(this.bShowManual));
    },

    prepareTable(){
      for (let i = 0; i < this.arrTable.length; i++){
          // Если места не подгрузились - заполним пустотой
          for (let j=0; j < this.maxAdvertPosition; j++){
            this.arrTable[i][`advert${j}`] = {cpm: "", nm_id: ""}
          }

          for (let j=0; j < this.arrTable[i].adverts.length; j++){
            this.arrTable[i][`advert${j}`] = this.arrTable[i].adverts[j]
          }
      }
    },

    async loadRegions(){
      let res = await fetchDrf(`/wbadv-bets-region/`)

      if (!res.gotError){
        this.regions = res.data
        if (this.regions.length) this.region = this.regions[0].id
      }
      else {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },


    async loadWbAdvBetsRequests(){
      let searchParams = new URLSearchParams({
          maxAdvertPosition: this.maxAdvertPosition,
          type: 0 // В поиске
      })

      let res = await fetchDrf(`/wbadv-bets-request/?${searchParams}`)

      if (!res.gotError){
        this.arrTable = res.data;
        this.prepareTable()
      }
      else {
        console.log("зачем-то в другую ветку зашли")
        console.log(res.gotError)
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async duplicateRequest(requestId){
      for(let i = 0; i < this.arrTable.length; i++)
      {
        if (this.arrTable[i]["id"] == requestId){
          this.keyword = this.arrTable[i]["keyword"]
          this.region = this.arrTable[i]["region"]
          await this.makeRequest()
          break // Нашли, сделали запрос, вышли
        }
      }
    },

  }
}

// https://catalog-ads.wildberries.ru/api/v5/search?keyword=%D0%BE%D0%BC%D0%B5%D0%B3%D0%B0%203

</script>

<style scoped>

</style>