<template>
  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    <span v-html="messageResult"></span>
  </div>
</template>

<script>
export default {
  name: "messageResultDiv",
  props: {
    messageResult: String,
    gotError: Boolean,
  }
}
</script>

<style scoped>

</style>