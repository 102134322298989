<template>
    <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-3">
        <h1>Войти</h1>
        <form>
          <div class="form-group mb-3">
            <small id= "labelInputName" class="text-secondary">Введите логин как его выдал телеграм бот</small>
            <input v-model="username" type="text" class="form-control" id="inputName" placeholder="Логин" aria-describedby="labelInputName">
          </div>

          <div class="form-group mb-3">
            <input v-model="password" type="password" class="form-control" id="inputPassword" aria-describedby="inputPassword" placeholder="Пароль">
          </div>

          <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
            {{ messageResult }}
          </div>

          <button @click="authUser" type="button" class="btn btn-primary mb-3">Войти</button>
        </form>

        <div>
<!--      <router-link :to="{ name: 'UserRegister'}">Регистрация</router-link>-->
          <a :href="'https://t.me/' + LOC_TG_BOT_NAME + '?start=register'" target="_blank">Зарегистрироваться через нашего телеграм-бота</a>
        </div>
        <div>
          <a :href="'https://t.me/' + LOC_TG_BOT_NAME + '?start=restore_password'" target="_blank">Забыли пароль?</a>
<!--      <router-link :to="{ name: 'UserRestorePassword'}">Забыли пароль?</router-link>-->
        </div>

      </div>
    </div>
  </div>

</template>

<script>
  import {fetchDrf, TG_BOT_NAME, normalizePhoneNumber} from "@/common";

  export default {
    name: "UserEnter",
    data() {
        return {
          username: "",
          password: "",
          messageResult: "",
          gotError: false,
          LOC_TG_BOT_NAME: ""
        }
    },

    created() {
      this.LOC_TG_BOT_NAME = TG_BOT_NAME;
    },

    methods: {
      async authUser() {

        let jsonBody = {
         username: this.username,
         password: this.password
        }

        let res = await fetchDrf(
           `/auth/token/login/`,
           "POST",
           jsonBody,
           "Нашли данные авторизации при входе на страницу",
           200,
           true,
           false
        )

        // Если ошибка
        if (res.gotError){
           this.messageResult = res.messageResult;
           this.gotError = res.gotError;
           return
        }

        console.log("авторизовались")

        localStorage.setItem("auth_token", res.data["auth_token"])

        // Подгрузим пользователя
        let res_user = await fetchDrf(
          `/auth/users/me/`,
          "GET",
          {},
          "",
          undefined,
          true
        )

        if (res.gotError){
         this.messageResult = res.messageResult;
         this.gotError = res.gotError;
        }

        console.log("подгрузили пользователя")

        let show_dashboard = res_user.data["show_dashboard"]

        console.log("show_dashboard")
        console.log(show_dashboard)

        if (show_dashboard){
          this.$router.push({ name: 'DashBoard' })
        }
        else {
          this.$router.push({ name: 'PageStart' })
        }
      }
  }
}
</script>

<style scoped>

</style>