<template>
  <div class="col-auto">
    <router-link :to="{name: 'WbOwnProductList'}" class="btn btn-info my-1">← вернуться</router-link>
  </div>

  <h5>{{ name }}</h5>

  <div id="divSpinner" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row g-3 align-top">
    <div class="col-1">
      <img class="w-100" :src="foto_link"/>
    </div>
    <div class="col-6">
      <span class="fw-bold">Артикул ВБ:</span> {{ nm_id }}
      <br>
      <span class="fw-bold">Артикул продавца:</span>  {{ article }}
      <br>
      <span class="fw-bold">Бренд:</span> {{ brand }}
      <br>
      <span class="fw-bold">Предмет:</span> {{ subject_name }} ({{ subject_wb_id }})
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-2">
      <label for="inputCost" class="col-form-label">Себестоимость:</label>
    </div>

    <div class="col-1">
      <input v-model="cost" type="number" id="inputCost" class="form-control">
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-2">
      <label for="inputLogisticsCostWb" class="col-form-label">Стоимость логистики ВБ:</label>
    </div>

    <div class="col-1">
      <input v-model="logistics_cost_wb" type="number" id="inputLogisticsCostWb" class="form-control">
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-3">
      <div class="form-group">
        <label for="textareaKeywords">Ключи</label>
        <textarea v-model="textAreaKeywords" class="form-control" id="textareaKeywords" rows="5"></textarea>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-auto">
      <button @click="updateProduct" type="button" class="btn btn-primary">Обновить</button>
    </div>

    <div class="col-auto">
      <router-link :to="{name: 'WbOwnProductList'}" class="btn btn-warning">Отмена</router-link>
    </div>

    <div class="col-auto">
      <button @click="updateKeywords" type="button" class="btn btn-info">Обновить ключи</button>
    </div>
  </div>

  <!--div class="row mt-3">
    <div class="col-auto">
      <h4>Связанные рекламные кампании</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{ name: 'WbAdvList'}" target="_blank" >
        Все рекламные кампании
      </router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <wb-adv-list-table
        :wbOwnProductId=id
        :showButtonSync="false"
        :showOnlyActual="true"
      />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-auto">
      <h4>Позиции ключей</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{ name: 'WbSearchProductAnalytics', params: { id: search_product_id }}" target="_blank" >
        Все позиции ключей
      </router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <wb-search-product-analytics-table
      :showDateSelection="true"
      :showButtonCheckCurrentPositions="true"
      :showTextFilter="true"
      :showRegionsFilter="true"
      :showAllRegions="true"
      :daysBeforeStartDate="6"
      :wbSearchProductId=search_product_id
      />
    </div>
  </div-->

</template>

<script>
import {fetchDrf, prepareNumber, getAdvStrategyClass, getWbCampaignUrl, getStatusBadgeClass, showMessageResult} from "@/common";
import WbSearchProductAnalyticsTable from "@/components/WbSearchProductAnalyticsTable";
import WbOwnAdvListTable from "@/components/WbOwnAdvListTable";

export default {
  name: "WbOwnProductEdit",
  props: ['id'],

  components: {
    WbOwnAdvListTable,
    WbSearchProductAnalyticsTable
  },

  data() {
    return {
      nm_id: "",
      article: "",
      name: "",
      brand: "",
      subject_wb_id: "",
      subject: 0,
      subject_name: "",
      search_product_id: 0,
      foto_link: "",
      cost: 0,
      logistics_cost_wb: 0,
      divSpinner: null,
      textAreaKeywords: "",
      adv_campaigns: [],
      messageResult: "",
      gotError: false
    }
  },

  async created() {
    await this.loadProduct();
    await this.loadKeywords();

    document.title = this.name
  },

  methods: {
    getStatusBadgeClass,
    getWbCampaignUrl,
    prepareOutput: prepareNumber,
    getAdvStrategyClass,

    keywordsArrayToTextArea(arrArray){
      // добавить еще сортировку по orders !!!
      let res = arrArray.map(elem => elem["name"]).join("\n");
      return res;
    },

    textAreaToKeywordsArray(sTextArea){
      let keywordsFromTextArea = sTextArea.split(/\n|\r/);
      let keywords = Array();

      for (let i = 0; i < keywordsFromTextArea.length; i++) {
        let new_item = {};
        new_item.name = keywordsFromTextArea[i];
        keywords.push(new_item);
      }

      return keywords
    },

    async loadKeywords() {
      let searchParams = new URLSearchParams({
          wb_own_product_id: this.id,
      })

      let res = await fetchDrf(`/wb-search-keyword/?${searchParams}`)

      if (!res.gotError) {
        let keywordsArray = res.data;
        this.textAreaKeywords = this.keywordsArrayToTextArea(res.data)
      }
      else {
        showMessageResult(res, this)
      }
    },


    async updateKeywords() {
      let keywords = this.textAreaToKeywordsArray(this.textAreaKeywords)

      let jsonBody = {
        wb_own_product_id: this.id,
        keywords: keywords
      }

      console.log("jsonBody")
      console.log(jsonBody)
      let res = await fetchDrf(`/wb-search-keyword/`,
          "POST",
          jsonBody,
          "Успешно обновили ключи"
      );

      this.loadKeywords()

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;
    },

    async loadProduct() {
      let res = await fetchDrf(`/wb-own-product/${this.id}/`)

      if (!res.gotError) {
        this.nm_id = res.data["nm_id"]
        this.article = res.data["article"]
        this.name = res.data["name"]

        this.brand = res.data["brand"]
        this.subject_wb_id = res.data["subject_wb_id"]
        this.subject_name = res.data["subject_name"]
        this.foto_link = res.data["foto_link"]
        this.cost = res.data["cost"]
        this.logistics_cost_wb = res.data["logistics_cost_wb"]
        this.search_product_id = res.data["search_product_id"]
      }
      else {
        showMessageResult(res, this)
      }
    },


    async updateProduct() {
      let jsonBody = {
        cost: this.cost,
        logistics_cost_wb: this.logistics_cost_wb,
      }

      let res = await fetchDrf(`/wb-own-product/${this.id}/`,
          "PUT",
          jsonBody
      )

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      await this.loadProduct()
    },

  }
}
</script>

<style scoped>

</style>