<template>
  <div class="row g-3 align-items-center mt-1">
    <div class="col-auto" v-if="showDateSelection">
      <label for="inputDateStart" class="col-form-label">Дата с</label>
    </div>

    <div class="col-auto" v-if="showDateSelection">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control">
    </div>

    <div class="col-auto" v-if="showDateSelection">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-auto" v-if="showDateSelection">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control">
    </div>

    <div class="col-auto" v-if="showDateSelection">
      <button @click="loadPositions" type="button" class="btn btn-primary">Обновить</button>
    </div>

    <div class="col-auto" v-if="showButtonCheckCurrentPositions">
      <button @click="checkCurrentPositions" type="button" class="btn btn-warning">Снять позиции прямо сейчас</button>
    </div>

    <div class="col-auto" v-if="showTextFilter">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>
  </div>

  <div class="row g-3 align-items-center mt-1" v-if="showRegionsFilter">
     <div class="col-auto">
      <div v-for="region in arrRegions" class="form-check form-check-inline">
        <input :id="'cb_region_'+region.id" @change="saveOptions"  class="form-check-input" type="checkbox" :value="region.id" v-model="arrSelectedRegions">
        <label :for="'cb_region_'+region.id"  class="form-check-label">{{region.name}}</label>
      </div>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-auto">
      <table class="table">
        <thead>
          <tr>
            <th class="align-top" scope="col" :style="{width: '200px'}">Ключ</th>
            <th class="align-top" scope="col" :style="{width: '150px'}">Регион</th>
            <th class="text-end align-top" :style="{width: '50px'}" scope="col" v-for="curDate in arrDates" :key="curDate">{{dateTime(curDate, 'DD.MM')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(curPos, index) in filteredRows" :key=index :class="curPos.isSubtitle ? 'fw-bold' : 'table-light'"  >
            <td class="py-0"> <span v-if="curPos.isSubtitle">{{ curPos.name }}</span>  </td>
            <td class="py-0">{{ curPos.region }}</td>
            <td class="py-0 text-end" v-for="curDate in arrDates" :key="curDate">{{curPos[curDate]}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


</template>

<script>
import {prepareDate, fetchDrf, getDaysArray, hideSpinner, showMessageResult, showSpinner} from "@/common";

export default {
  name: "WbSearchProductAnalyticsTable",

  props: {
    wbSearchProductId: Number,
    showDateSelection: Boolean,
    showButtonCheckCurrentPositions: Boolean,
    showTextFilter: Boolean,
    showRegionsFilter: Boolean,
    showAllRegions: Boolean,
    daysBeforeStartDate: Number
  },

  data() {
    return {
      dateStart: "",
      dateEnd: "",
      positions: [],
      arrDates: [],
      arrRegions: [],
      arrPositionsRaw:[],
      arrSelectedRegions: [],
      filterString: "",
      messageResult: "",
      gotError: false
    }
  },

  emits: [],

  computed: {
    filteredRows() {
      return this.positions.filter(row => {
        const filterString = this.filterString.toString().toLowerCase().trim();

        const keywordName = row['name'].toString().toLowerCase();

        let bTextFiltersOk = false
        if ( keywordName.includes(filterString) ){
          bTextFiltersOk = true
        }

        let bRegionFilterOk = true

        // Если region_id у строки заполнен и его нет в отмеченных
        if (row["region_id"] && !this.arrSelectedRegions.includes(row["region_id"]) ){
          bRegionFilterOk = false
        }

        return bTextFiltersOk && bRegionFilterOk
      });
    }
  },

  async created() {
    this.initDates();
    await this.loadRegions();
    await this.loadPositions();
    this.restoreOptions();
  },

  methods:{
    fetchDrf,
    showMessageResult,
    showSpinner,
    hideSpinner,
    dateTime: prepareDate,
    getDaysArray,

    saveOptions(){
      // Сохраняем только если не выставили опцию "принудительно показывать все регионы"
      if (!this.showAllRegions){
        localStorage.setItem("WbSearchProductAnalytics_arrSelectedRegions", JSON.stringify(this.arrSelectedRegions));
      }
    },

    initDates(){
      // Можно даже в localStore сохранить даты
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - this.daysBeforeStartDate)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },

    restoreOptions(){
      if(localStorage.getItem("WbSearchProductAnalytics_arrSelectedRegions")){
        this.arrSelectedRegions = JSON.parse(localStorage.getItem("WbSearchProductAnalytics_arrSelectedRegions"));
      }

      if (this.showAllRegions){
        this.arrSelectedRegions = new Array
        for (let i = 0; i < this.arrRegions.length; i++){
          this.arrSelectedRegions.push(this.arrRegions[i].id)
        }
      }
    },

    async loadRegions(){
      let res = await fetchDrf(`/wb-search-region`)

      if (!res.gotError){
        this.arrRegions = res.data;
      }
      else {
        showMessageResult(res, this);
      }
    },

    async checkCurrentPositions(){
      // Это должна быть одна функция и для снятия одной позиции и для снятия всех позиций!

      let jsonBody = {
        wb_search_product_id: this.id,
      }

      this.showSpinner(this)

      let res = await fetchDrf(`/wb-search-position/`,
                               "POST",
                               jsonBody,
                               "Успешно получили позиции"
                              )

      this.hideSpinner(this)

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      this.loadPositions()
    },

    prepareTable(){

      if (!this.arrPositionsRaw){
        console.log("prepareTable(): ошибка")
        return
      }

      this.arrDates = this.getDaysArray (this.dateStart, this.dateEnd);
      this.arrDates = this.arrDates.reverse()

      this.positions = new Array()

      // Для каждого ключа - создадим строку
      for (let i = 0; i < this.arrPositionsRaw["keywords"].length; i++){
        // Строка с ключом
        let curRow = {};
        curRow["name"] = this.arrPositionsRaw["keywords"][i]["keyword_name"];
        curRow["region"] = "";
        curRow["region_id"] = 0;
        curRow["isSubtitle"] = true;

        for(let k = 0; k < this.arrDates.length; k++){
          curRow[this.arrDates[k]] = "";
        }

        this.positions.push(curRow);

        // Для каждого региона - создадим строку
        for (let j = 0; j < this.arrPositionsRaw["keywords"][i]["regions"].length; j++){
          let curRow = {};
          curRow["name"]       = this.arrPositionsRaw["keywords"][i]["keyword_name"]; //  "";
          curRow["region"]     = this.arrPositionsRaw["keywords"][i]["regions"][j]["region_name"];
          curRow["region_id"]  = this.arrPositionsRaw["keywords"][i]["regions"][j]["id"];
          curRow["isSubtitle"] = false;

          // Сначала за все даты заполним ячейки прочерками
          for(let k = 0; k < this.arrDates.length; k++){
            curRow[this.arrDates[k]] = "-";
          }

          // А теперь заполним оставшееся переданными данными
          for (let k = 0; k < this.arrPositionsRaw["keywords"][i]["regions"][j]["positions"].length; k++){
            let curDate = this.arrPositionsRaw["keywords"][i]["regions"][j]["positions"][k]["date_request"]
            let curPosition = this.arrPositionsRaw["keywords"][i]["regions"][j]["positions"][k]["position"]
            curRow[curDate] = curPosition;
          }

          this.positions.push(curRow);
        }
      }
    },

    async loadPositions() {
      let searchParams = new URLSearchParams({
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
      })

      let res = await fetchDrf(`/wb-search-product-positions/${this.wbSearchProductId}/?${searchParams}`)

      if (!res.gotError){
        this.arrPositionsRaw = res.data;
        this.prepareTable()
      }
      else {
        showMessageResult(res, this);
      }
    },
  }
}
</script>

<style scoped>

</style>