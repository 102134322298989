<template>
  <h1>Отслеживание позиций ВБ</h1>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"
       role="alert">
    {{ messageResult }}
  </div>

  <div id="divSpinner" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row g-3 align-items-center  mt-3">
    <div class="col-auto">
      <router-link :to="{ name: 'WbSearchProductEdit', params: {id: 0}}" class="btn btn-info">
        Добавить товар ВБ на отслеживание
      </router-link>
    </div>

    <div class="col-2">
    </div>
    <div class="col-2">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

    <div class="col-2">

      <input id="cbOnlyForWbOwnProduct"  class="form-check-input me-1" type="checkbox" v-model="bOnlyForWbOwnProduct" @change="saveOptions">
      <label for="cbOnlyForWbOwnProduct"  class="form-check-label">Только автосозданные</label>
    </div>

    <div class="col-3">
      <select class="form-select" v-model="selectedRegion" @change="regionChange" title="Регион для топа ключей на этой странице">
        <option v-for="curRegion in arrRegions" :value="curRegion.id">
          {{ curRegion.name }}
        </option>
      </select>
    </div>
  </div>

  <table class="table table-striped mt-3">
    <thead class="sticky-top opacity-100 bg-white">
    <tr>
      <th scope="col">Фото</th>
      <th scope="col">Артикул</th>
      <th scope="col">Товар</th>
      <th scope="col">Ключей</th>
      <th scope="col">В ТОП-30</th>
      <th scope="col">На первой странице</th>
      <th scope="col">На первых 10 страницах</th>
      <!--  топ-10 и т.д. взять у mpstats! тем более, это все по регионам должно быть еще!   -->
      <th scope="col">Авто-создано для товара</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="product in filteredRows" :key="product.id">
      <td>
        <img width="30" :src="product.foto_link"/>
      </td>
      <td>
        {{ product.nm_id }}
      </td>
      <td>
        <router-link :to="{ name: 'WbSearchProductAnalytics', params: { id: product.id }}">
          {{ product.brand }}/{{ product.name }}
        </router-link>
      </td>
      <td>{{product.total_keywords}}</td>
      <td>{{product.number1}} <!--sup class="text-success fst-italic">+2</sup--></td>
      <td>{{product.number2}} <!--sup class="text-danger fst-italic">-10</sup--></td>
      <td>{{product.number3}}</td>
      <td>
        <span v-if="product.wb_own_product">
          Да
        </span>
      </td>
      <td>
        <span v-if="product.wb_own_product">
          <router-link :to="{ name: 'WbOwnProductEdit', params: { id: product.wb_own_product }}" class="btn btn-warning mx-1">
            Редакт
          </router-link>
        </span>
        <span v-else>
          <router-link :to="{ name: 'WbSearchProductEdit', params: { id: product.id }}" class="btn btn-warning mx-1">
            Редакт
          </router-link>
        </span>

        <a @click="deleteProduct(product.id)" href="#" class="btn btn-danger mx-1">Удалить</a>
      </td>
    </tr>
    </tbody>
  </table>


</template>

<script>
import {fetchDrf, showMessageResult} from "@/common";

export default {
  name: "WbSearchProductList",

  components: {
  },

  data() {
    return {
      products: [],
      selectedRegion: 0,
      arrRegions: [],
      bOnlyForWbOwnProduct: true,
      filterString: "",
      messageResult: "",
      gotError: false
    }
  },

  computed: {
    filteredRows() {
      return this.products.filter(row => {
        const filterString = this.filterString.toString().toLowerCase().trim();

        const name = row['name'].toString().toLowerCase();
        const nm_id = row['nm_id'].toString().toLowerCase();
        const wb_own_product = row['wb_own_product'];

        let bTextFiltersOk = false
        if ( name.includes(filterString) || nm_id.includes(filterString) ){
          bTextFiltersOk = true
        }

        let bOwnProductFilterOk = true
        if ( this.bOnlyForWbOwnProduct && !wb_own_product ){
          bOwnProductFilterOk = false
        }

        return bTextFiltersOk && bOwnProductFilterOk
      });
    }
  },

  created() {
    this.restoreOptions();
    this.loadRegions();
    this.loadProducts();

    document.title = "Отслеживание позиций ВБ"
  },

  methods: {
    restoreOptions(){
      if(localStorage.getItem("WbSearchProductList_bOnlyForWbOwnProduct"))  this.bOnlyForWbOwnProduct = JSON.parse(localStorage.getItem("WbSearchProductList_bOnlyForWbOwnProduct"));
      if(localStorage.getItem("WbSearchProductList_selectedRegion"))  this.selectedRegion = JSON.parse(localStorage.getItem("WbSearchProductList_selectedRegion"));
    },

    saveOptions(){
       localStorage.setItem("WbSearchProductList_bOnlyForWbOwnProduct", JSON.stringify(this.bOnlyForWbOwnProduct));
       localStorage.setItem("WbSearchProductList_selectedRegion", JSON.stringify(this.selectedRegion));
    },

    async regionChange(){
      this.saveOptions()
      await this.loadProducts()
    },

    async loadRegions(){
      let res = await fetchDrf(`/wb-search-region`)

      // По умолчанию выбран первый регион
      if (!res.gotError){
        this.arrRegions = res.data;
        if (this.selectedRegion == 0 && this.arrRegions.length) this.selectedRegion = this.arrRegions[0].id;
      }
      else {
        showMessageResult(res, this);
      }
    },


    async loadProducts() {
      let searchParams = new URLSearchParams({
          region: this.selectedRegion,
      })

      let res = await fetchDrf(`/wb-search-product/?${searchParams}`)

      if (!res.gotError) this.products = res.data;
      else showMessageResult(res, this);
    },


    async deleteProduct(id) {
      if (!confirm('Подтверждаете удаление?')) return;

      let res = await fetchDrf(`/wb-search-product/${id}/`,
                               "DELETE");

      showMessageResult(res, this);

      await this.loadProducts() // обновить список
    },
  }

}
</script>

<style scoped>

</style>

