<template>
  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div class="row">
    <div class="col-sm-auto mt-1">
      <router-link :to="{name: 'WbAdvBetsRequestList'}" class="btn btn-info mt-1">← вернуться</router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-1">
      <h5>{{wb_adv_bets_request.keyword}} [{{prepareDate(wb_adv_bets_request.date_request )}}]</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-1">
      <span class="text-secondary">Работает в тестовом режиме. Показывает только ставки, а не реальные позиции</span>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <h6>Приоритетность категорий: </h6>
      <span class="mt-0">{{wb_adv_bets_request.priority_subjects}}</span>
    </div>
  </div>

  <div class="row">
      <div class="col-sm-auto mt-2">
        <div class="input-group">
          <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр"
                 aria-describedby="btnClearFilter">
          <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X
          </button>
        </div>
      </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <UniversalTable
        :items="filteredRows"
        :allHeaders="allHeaders"
        :arrTable="arrTable"
        localStorageHeadersName="WbAdvBetsRequestEdit_headers"
        tableClass="table-striped table-bordered border-light table-hover"
        excelFileName="adverts"
      >
      </UniversalTable>
    </div>
  </div>

</template>

<script>
import {
  prepareDate,
  fetchDrf,
  IMAGE_LINK_PRODUCT_NOT_FOUND
} from "@/common";

import UniversalTable from "@/components/UniversalTable";
import {Tooltip} from "bootstrap";

export default {
  name: "WbAdvBetsRequestEdit",
  props: ['id'],

  components: {
    UniversalTable,
  },

  data(){
    return {
      wb_adv_bets_request: {},
      filterString: "",
      arrTable: new Array(),
      allHeaders: new Array(),

      COL_FOTO: "foto_link",
      COL_CPM: "cpm",
      COL_POSITION: "position",
      COL_PAGE_NUMBER: "page_number",
      COL_POSITION_ON_PAGE: "position_on_page",
      COL_SUBJECT_NAME: "subject_name",
      COL_SUBJECT_ID: "subject_id",
      COL_ARTICLE_WB: "nm_id",

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      messageResult: "",
      gotError: false
    }
  },

  async created() {

    this.allHeaders.push({id: this.COL_POSITION, name: "#", active: true})
    this.allHeaders.push({id: this.COL_PAGE_NUMBER, name: "Страница", active: true})
    this.allHeaders.push({id: this.COL_POSITION_ON_PAGE, name: "Позиция на странице", active: true})
    this.allHeaders.push({id: this.COL_CPM, name: "Ставка", active: true})
    this.allHeaders.push({id: this.COL_FOTO, name: "Фото", type: "foto", active: true})
    this.allHeaders.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", active: true, type: "nm_id_not_own"})
    this.allHeaders.push({id: this.COL_SUBJECT_NAME, name: "Категория", active: true})

    await this.loadWbAdvBetsRequest();

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    document.title = this.wb_adv_bets_request.keyword
  },

  methods: {
    prepareDate,
    // showMessageResult,
    fetchDrf,

    defaultFotoLink(advert){
      advert.foto_link = IMAGE_LINK_PRODUCT_NOT_FOUND
    },

    async loadWbAdvBetsRequest() {
      let searchParams = new URLSearchParams({
          maxAdvertPosition: 1000,
      })

      let res = await fetchDrf(`/wbadv-bets-request/${this.id}/?${searchParams}`)

      if (!res.gotError) {
        this.wb_adv_bets_request = res.data
        this.arrTable = res.data["adverts"]

        this.prepareTable()
        console.log("this.arrTable")
        console.log(this.arrTable)
      }
      else {
        this.messageResult = res.messageResult
        this.gotError = res.gotError
      }
    },

    prepareTable() {
      for (let i = 0; i < this.arrTable.length; i++) {
        this.arrTable[i][this.COL_SUBJECT_NAME] = `${this.arrTable[i][this.COL_SUBJECT_NAME]} (${this.arrTable[i][this.COL_SUBJECT_ID]})`
      }
    }
  },



  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const articleWb = row[this.COL_ARTICLE_WB].toString().toLowerCase();
        const subjectName = row[this.COL_SUBJECT_NAME].toString().toLowerCase();

        let bTextFilterOk = false

        if (articleWb.includes(filterString) || subjectName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },
  },
}
</script>

<style scoped>

</style>