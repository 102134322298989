<template>

  <div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 11">
    <div v-if="toastIsShowedByFunc||toastIsShowed" class="toast show" :class="toastClass" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" :data-bs-delay="toastDelay">
      <div class="toast-header">
        <span class="w-100">{{ toastMessage }}</span>
        <button type="button" class="btn-close float-end" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "Toast",

  props: {
    toastMessage: {
      type: String,
      default: "Внимание",
    },
    gotError: {
      type: Boolean,
      default: false,
    },

    toastIsShowed: {
      type: Boolean,
      default: false,
    },

    toastDelay: {
      type: Number,
      default: 5000,
    },
  },

  data() {
    return{
      toastIsShowedByFunc: false,
      messageText: "",
    }
  },

  computed: {
    toastClass(){
      if (this.gotError) return "bg-danger"
      else return "bg-success"
    }
  },

  methods: {
    // Функция глючит. Может когда-нибудь доправлю ее
    showToast(messageText){

      this.toastIsShowedByFunc = true

      // console.log("this.toastIsShowedByFunc")
      // console.log(this.toastIsShowedByFunc)

      // this.messageText = messageText

      setTimeout(() => {
        this.toastIsShowedByFunc = false
      }, this.toastDelay)
    },
  },

}
</script>

<style scoped>

</style>