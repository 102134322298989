<template>
  <h1>Базовая себестоимость товаров ({{ this.filteredRows.length }})</h1>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
    {{ messageResult }}
  </div>

  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />


  <div id="divCostPriceImportWindow"  class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">Импорт себестоимости товаров</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideCostPricesImportWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <ol >
                <li ><a :href="LINK_COST_PRICES_TEMPLATE">Скачайте шаблон</a></li>
                <li >Заполните скачанный файл, затем сохраните его</li>
                <li >Выберите дату с которой будет действовать себестоимость</li>
                <li >Нажмите на кнопку "Выбрать файл" и найдите заполненный файл</li>
                <li >Нажмите на кнопку "Загрузить"</li>
              </ol>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-auto">
              <label for="inputDateStartCostPriceImport" class="col-form-label">Фильтр по дате начала</label>
              <input v-model="dateStartCostPriceImport" type="date" id="inputDateStartCostPriceImport" class="form-control mt-0">
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-auto">
              <input type="file" class="form-control" ref="inputFileCostPriceImport" />
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-primary" @click="onImportFileClick">Загрузить</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-auto mt-2">
      <label for="inputDate" class="col-form-label">Дата начала действия</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateStart" type="date" id="inputDate" class="form-control">
    </div>

    <div class="col-sm-auto mt-2">
      <div class="input-group">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр"
               aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X
        </button>
      </div>
    </div>

    <div class="col-sm-auto mt-2">
    </div>

    <div class="col-sm-auto mt-2">
      <button @click="showCostPricesImportWindow" type="button" class="btn btn-primary">Импорт из файла</button>
    </div>

    <div class="col-sm-auto mt-2">
      <button @click="downloadProductsEmptyCostPrices" type="button" class="btn btn-primary">Скачать товары с незаполненной себестоимостью</button>
    </div>

  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <UniversalTable
        :items="filteredRows"
        :allHeaders="allHeaders"
        localStorageHeadersName="WbOwnCostList_headers"
        :arrTable="arrTable"
        tableClass="table-striped table-bordered border-light table-hover"
        @data-updated="loadCosts"
      >

      </UniversalTable>
    </div>
  </div>


</template>

<script>
import {
  prepareDate,
  fetchDrf,
  prepareNumber,
  getWbUrlByNmId,
  showMessageResult,
  showToast,
  LINK_COST_PRICES_TEMPLATE,
  TYPE_COL_DELETE_BUTTON,
  exportToExcel,
} from "@/common";

import UniversalTable from "@/components/UniversalTable";
import EditableCell from "@/components/EditableCell";
import {Modal} from "bootstrap";
import Toast from "@/components/Toast";

export default {
  name: "WbOwnCostPriceList",

  components: {
    UniversalTable,
    EditableCell,
    Toast,
  },

  data() {
    return {
      arrTable:[],
      columns: [],
      allColumns: [],
      allHeaders: [],

      LINK_COST_PRICES_TEMPLATE: "",

      filterString: "",
      dateStart: "",

      COL_ID: 'id',
      COL_FOTO: 'foto_link',
      COL_NAME: 'name',
      COL_ARTICLE: 'article',
      COL_ARTICLE_WB: 'nm_id',
      COL_OWN_PRODUCT_ID: 'own_product_id',
      COL_BARCODE: 'barcode',
      COL_SIZE: 'size',
      COL_DATE_START: 'date_start',
      COL_COST_PRICE: 'cost_price',
      COL_BUTTONS: 'cost_buttons',

      toastIsShowed: false,
      toastMessage: "",

      costPriceWindowIsShown: false,
      fileCostPriceImport: "",

      dateStartCostPriceImport: "", // new Date().toISOString().substr(0, 10)

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "Базовая себестоимость товаров"
    this.LINK_COST_PRICES_TEMPLATE = LINK_COST_PRICES_TEMPLATE;
    this.TYPE_COL_DELETE_BUTTON = TYPE_COL_DELETE_BUTTON;

    this.allHeaders.push({id: this.COL_FOTO, name: "Фото", type: 'foto'})
    this.allHeaders.push({id: this.COL_NAME, name: "Название"})
    this.allHeaders.push({id: this.COL_ARTICLE, name: "Артикул"})
    this.allHeaders.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ"})
    this.allHeaders.push({id: this.COL_BARCODE,name: "Баркод"})
    this.allHeaders.push({id: this.COL_SIZE, name: "Размер"})
    this.allHeaders.push({id: this.COL_DATE_START, name: "Дата начала действия", type: 'date', need_fill: true, mark_not_null: true, editable_cell_path: "wb-own-cost-price"})
    this.allHeaders.push({id: this.COL_COST_PRICE, name: "Себестоимость", type: 'float', need_fill: true, mark_not_null: true, editable_cell_path: "wb-own-cost-price"}) // COL_COST_PRICE_TEXT !!!!!
    this.allHeaders.push({id: this.COL_BUTTONS, name: "Кнопки", type: TYPE_COL_DELETE_BUTTON, delete_path: "wb-own-cost-price"})

    // По умолчанию все активны
    for (var i = 0; i < this.allHeaders.length; i++){
      this.allHeaders[i]['active'] = true
    }

    this.loadCosts()
    this.restoreOptions()
  },

  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        let article = ""
        if (row[this.COL_ARTICLE]){
          article = row[this.COL_ARTICLE].toString().toLowerCase();
        }

        const articleWb = row[this.COL_ARTICLE_WB].toString().toLowerCase();
        const name = row[this.COL_NAME].toString().toLowerCase();
        const barcode = row[this.COL_BARCODE].toString().toLowerCase();
        const date_start = row[this.COL_DATE_START];

        let bTextFilterOk = false

        if ( article.includes(filterString) || articleWb.includes(filterString) || name.includes(filterString) || barcode.includes(filterString)  ){
          bTextFilterOk = true
        }

        let bDateFilterOk = true

        if (! this.dateStart) {
          bDateFilterOk = true
        }
        else if (date_start == this.dateStart){
          bDateFilterOk = true
        }
        else if (date_start != this.dateStart){
          bDateFilterOk = false
        }

        return bTextFilterOk && bDateFilterOk
      });
    }
  },

  methods: {
    prepareDate,
    prepareNumber,
    getWbUrlByNmId,
    showToast,


    async downloadProductsEmptyCostPrices(){
      // console.log("downloadProductsEmptyCostPrices")

      let res = await fetchDrf(
        `/wb-own-barcode-empty-cost-price/`,
        "GET",
        {},
        "Успешно скачали пустые цены"
        )

      if (res.gotError){
        this.showToast(this, res.messageResult, res.gotError, 10000);
      }
      else {
        let headersEmptyPrices = ['Артикул WB', 'Баркод', 'Себестоимость', 'Примечание (не учитывается)']
        exportToExcel(res.data, headersEmptyPrices, "import_empty_cost_prices")
      }
    },


    async onImportFileClick(event){
      const file = this.$refs.inputFileCostPriceImport.files[0]

      if (!file){
        alert("Выберите файл")
        return
      }

      if (!this.dateStartCostPriceImport){
        alert("Введите дату начала действия")
        return
      }

      const formData = new FormData();
      formData.append('file_uploaded', file);
      formData.append('date_start', this.dateStartCostPriceImport);

      let res = await fetchDrf(
        `/wb-own-cost-price-upload/`,
        "POST",
        formData,
        "Успешно загрузили файл"
        )

      this.hideCostPricesImportWindow()

      this.showToast(this, res.messageResult, res.gotError, 10000);

      if (res.gotError){
        this.messageResult = res.messageResult;
        this.gotError = res.gotError
      }

      this.loadCosts()
    },


    showCostPricesImportWindow(){
      this.divCostPriceImportWindow = new Modal(document.getElementById('divCostPriceImportWindow'))
      this.divCostPriceImportWindow.show()
    },


    hideCostPricesImportWindow(){
      this.divCostPriceImportWindow.hide()
    },


    // async updateCostPrice(id, oldValue, newValue, attr_name){
    //   if ( typeof (newValue) == 'object' ) return
    //   if (oldValue == newValue){
    //     return
    //   }
    //
    //   let jsonBody = {}
    //
    //   jsonBody[attr_name] = newValue
    //
    //   let res = await fetchDrf(`/wb-own-cost-price/${id}/`,
    //       "PUT",
    //       jsonBody
    //   )
    //
    //   this.messageResult = res.messageResult;
    //   this.gotError = res.gotError
    //
    //   this.showToast(this, res.messageResult, res.gotError, 10000);
    //
    //   await this.loadCosts()
    // },


    // async deleteCostPrice(id){
    //   if(! confirm('Подтверждаете удаление?')) return;
    //
    //   let res = await fetchDrf(`/wb-own-cost-price/${id}/`,
    //                            "DELETE")
    //
    //   this.messageResult = res.messageResult;
    //   this.gotError = res.gotError;
    //
    //   await this.loadCosts() // обновить список
    // },

    restoreOptions(){
    },

    saveOptions(){
    },

    prepareTable() {
      // for (let i = 0; i < this.arrTable.length; i++) {
      //   this.arrTable[i][this.COL_DATE_START_TEXT] = prepareDate(this.arrTable[i][this.COL_DATE_START])
      //   this.arrTable[i][this.COL_COST_PRICE_TEXT] = prepareNumber(this.arrTable[i][this.COL_COST_PRICE], "0")
      // }
    },

    async loadCosts(){
      let searchParams = new URLSearchParams({
      })

      let res = await fetchDrf(`/wb-own-cost-price`)

      if (!res.gotError){
        this.arrTable = res.data;

        console.log("this.arrTable")
        console.log(this.arrTable)

        this.prepareTable()
      }
      else {
        showMessageResult(res, this);
      }
    },
  }
}
</script>

<style scoped>

</style>