<template>


<Bar
  id="my-chart-id"
  :options="chartOptions"
  :data="chartData"
/>

</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChartAdv',
  components: { Bar },

  props:{
    chartData: Object,
  },

  data() {
    return {
      chartOptions: {

        layout: {
            padding: -10,
        },

        maintainAspectRatio: false,
        aspectRatio: 1,

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,

            titleFont:{
              weight: 'normal',
            },

            callbacks: {
              title: (context) => {
                let stacks = context[0].parsed._stacks.y

                let arrAdvNames = []
                arrAdvNames[0] = 'Каталог'
                arrAdvNames[1] = 'Карточка'
                arrAdvNames[2] = 'Поиск'
                arrAdvNames[3] = 'На главной'
                arrAdvNames[4] = 'Автоматическая'
                arrAdvNames[5] = 'Поиск + каталог'

                arrAdvNames = arrAdvNames.reverse()

                let res = []

                for (let i = 5; i >=0; i--){
                  if (!stacks[i]) {
                    continue
                  }

                  let sDate = ""
                  if (! res.length){
                    sDate = `   (${context[0].label})`
                  }

                  res.push(`${arrAdvNames[i]}: ${stacks[i]} ${sDate}\n`)
                }

                return res
              },

              label: (context) => {
                return ""
              },

              labelPointStyle: function(context) {
                  return {
                      pointStyle: false,
                  };
              },
            }
          },
        },

        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            padding: 0,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: false
            }

          },
          y: {
            stacked: true,
            padding: 0,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: false
            }
          }
        }
      }
    }
  },

  methods: {
    // my_legend( tooltipItems ){
    //   return "8888888"
    // }
  }
}
</script>

<style scoped>
.tooltip label {
  display: none;
}
</style>