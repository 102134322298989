<template>


<Pie
  id="my-chart-id"
  :options="chartOptions"
  :data="chartData"
/>

</template>

<script>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement)

export default {
  name: 'PieChartWhStocks',
  components: { Pie },

  props:{
    chartData: Object,
  },

  data() {
    return {
      chartOptions: {
        layout: {
            padding: -10,
        },

        maintainAspectRatio: false,
        aspectRatio: 1,

        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            usePointStyle: true,

            callbacks: {
              title: (context) => {
                return []
              },

              label: (context) => {
                return `${context["label"]}: ${context["formattedValue"]}`
              },

            }
          },
        },
      }
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.tooltip label {
  display: none;
}
</style>