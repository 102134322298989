<template>
  <h5>Настроить отображение складов </h5>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'UserSelfEdit'}" class="btn btn-info my-1">← вернуться</router-link>
    </div>
  </div>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': getError, 'alert-success': !getError }"  role="alert">
    {{ messageResult }}
  </div>

  <div class="row">
    <div class="col-auto">
      <div class="input-group mt-2">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <universal-table
        :items="filteredRows"
        :allHeaders="allHeaders"
        localStorageHeadersName="WbWhRel_headers"
        :arrTable="arrTable"
        tableClass="table-striped  table-hover table-sm"
      />
    </div>
  </div>

</template>

<script>
import {fetchDrf} from "@/common";

import UniversalTable from "@/components/UniversalTable";

export default {
  name: "WbWhRel",

  components: {
    UniversalTable
  },

  data(){
    return{
      filterString: "",

      arrTable: [],
      columns: [],
      allHeaders: [],

      COL_FAVORITE: 'favorite',
      COL_HIDDEN: 'hidden',
      COL_WH_NAME: 'wh_name',

      messageResult: "",
      getError: false
    }
  },

  created() {
    document.title = "Скрыть склады"

    this.allHeaders.push({id: this.COL_HIDDEN, name: "Скрыть", active: true, type: "checkbox", checkbox_path: "wb-wh-rel"})
    this.allHeaders.push({id: this.COL_WH_NAME, name: "Склад", active: true, title: "wh_name" })

    this.loadWbWhRel()
  },

  methods: {
    async prepareTable(){
    },

    async loadWbWhRel(){
      let res = await fetchDrf(`/wb-wh-rel/`)

      if (!res.gotError){
        this.arrTable = res.data;
        this.prepareTable()
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    }
  },

  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk

      });
    }
  },
}
</script>

<style scoped>

</style>