<template>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">HoMP</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">

        </li>

      </ul>
      <span class="navbar-text">
        Добро пожаловать, <a href="/frontendv/self-user-edit">admin</a> | <a href="#">Выход</a>
      </span>
    </div>
  </div>
</nav>

</template>

<script>
export default {
  name: "Nav"
}
</script>

<style scoped>

</style>