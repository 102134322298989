<template>

<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span class="fs-5 d-none d-sm-inline">Меню</span>
                </a>
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                  <li class="nav-item">
                      <router-link :to="{name: 'WbSearchProductList'}" class="nav-link align-middle px-0 text-dark">
                        <i class="fs-4 bi bi-bag"></i> <span class="ms-1 d-none d-sm-inline">Отслеживание позиций</span>
                      </router-link>
                  </li>


                  <li class="nav-item" v-if="is_staff">
                      <router-link :to="{name: 'WbAdmin'}" class="nav-link align-middle px-0 text-dark">
                        <i class="fs-4 bi bi-emoji-sunglasses"></i> <span class="ms-1 d-none d-sm-inline">Админка</span>
                      </router-link>
                  </li>


<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbLkList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-people"></i> <span class="ms-1 d-none d-sm-inline">Аккаунты ВБ</span>-->
<!--                      </router-link>-->
<!--                  </li>-->

<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbAdvList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-rocket-takeoff"></i> <span class="ms-1 d-none d-sm-inline">Рекламные кампании</span>-->
<!--                      </router-link>-->
<!--                  </li>-->

<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbAdvBetsRequestList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-currency-dollar"></i> <span class="ms-1 d-none d-sm-inline">Текущие ставки рекламы</span>-->
<!--                      </router-link>-->
<!--                  </li>-->


                  <!----------------------------------------------->

<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbProductOwnList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-bag"></i> <span class="ms-1 d-none d-sm-inline">Свои товары</span>-->
<!--                      </router-link>-->
<!--                  </li>-->

<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbProductList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-yin-yang"></i> <span class="ms-1 d-none d-sm-inline">Товары конкурентов</span>-->
<!--                      </router-link>-->
<!--                  </li>-->

<!--                  <li class="nav-item">-->
<!--                      <router-link :to="{name: 'WbProductList'}" class="nav-link align-middle px-0 text-dark">-->
<!--                        <i class="fs-4 bi bi-percent"></i> <span class="ms-1 d-none d-sm-inline">Отслеживание СПП</span>-->
<!--                      </router-link>-->
<!--                  </li>-->

                </ul>
                <hr>
                <div class="dropdown pb-4">
                    <router-link :to="{name: 'UserSelfEdit'}" class="d-flex align-items-center text-dark text-decoration-none" >
                      <i class="fs-4 bi bi-file-person-fill" :title=username></i>
<!--                        <img src="https://github.com/mdo.png" width="30" height="30" class="rounded-circle" >-->
<!--                        <span class="d-none d-sm-inline mx-1">{{username}}</span>      -->
                    </router-link>
                </div>
            </div>
        </div>
        <div class="col py-3">
            <slot/>
        </div>
    </div>
</div>

<div class="m-4 text-secondary">
  Поддержка в Телеграм: <a :href="'https://t.me/' + LOC_TG_SUPPORT_NAME" class="link-secondary" target="_blank">@{{LOC_TG_SUPPORT_NAME}}</a>
</div>


</template>

<script>

import fetchDrf from "@/common";
import {TG_SUPPORT_NAME} from "@/common";

export default {
  name: "CabinetLayout",

  data () {
    return {
      username: '',
      is_staff: false,
      LOC_TG_SUPPORT_NAME: "",
    }
  },

  created() {
      this.loadUser();
      this.LOC_TG_SUPPORT_NAME = TG_SUPPORT_NAME;
  },

  methods: {
    async loadUser() {
      let res = await fetchDrf(`/auth/users/me/`,
          "GET",
          {},
          "",
          undefined,
          true
      )

      if (!res.gotError){
        this.username = res.data["username"]
        this.is_staff = res.data["is_staff"]
      }
      else {
        console.log("CabinetLayout: ошибка получения имени пользователя")
        console.log(response.status)
        this.$router.push({ name: 'UserAuth' })
      }
    }
  }
}
</script>

<style scoped>

</style>