<template>

  <div class="container mt-3">
    <slot/>
  </div>

  <div class="m-4 text-secondary">
    Поддержка в Телеграм: <a :href="'https://t.me/' + LOC_TG_SUPPORT_NAME" class="link-secondary" target="_blank">@{{LOC_TG_SUPPORT_NAME}}</a>
  </div>

</template>

<script>
import {TG_SUPPORT_NAME} from "@/common";

export default {
  name: "EmptyLayout",

  data () {
    return {
      LOC_TG_SUPPORT_NAME: "",
    }
  },

  created() {
      this.LOC_TG_SUPPORT_NAME = TG_SUPPORT_NAME;
  },

}
</script>

<style scoped>

</style>