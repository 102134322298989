import {Modal} from "bootstrap";
import moment from 'moment';
import * as XLSX from 'xlsx'

export const COL_OWN_PRODUCT_ID = "own_product_id"
export const COL_WB_ID = "wb_id"
export const COL_ID = "id"
export const COL_WB_OWN_RREPORT_ID = "wb_own_rreport_id"
export const COL_ARTICLE_WB = "nm_id"
export const COL_ARTICLE_WB_NOT_OWN = "nm_id_not_own"
export const COL_ADV_BETS_REQUEST_ID = "adv_bets_request_id"
export const COL_ADVERT = "advert"
export const COL_RENEW_ADV = "renew_adv"
export const COL_ADV_KEYWORD = "keyword"

export const COL_ORDER_QUANTITY = "order_quantity"
export const COL_ORDER_SUM = "order_sum"
export const COL_ORDER_HINT = "order_hint"
export const COL_ADV_OWN_PRODUCTS = "adv_own_products"
export const COL_ADV_TYPE = "adv_type"
export const COL_ADV_TYPE_NAME = "adv_type_name"
export const COL_ADV_TYPE_NAME_SHORT = "adv_type_name_short"
export const COL_ADV_STATUS = "status"
export const COL_ADV_STATS_BARCHART = "adv_stats_barchart"

export const TYPE_COL_OWN_PRODUCT_ID = "own_product_id"
export const TYPE_COL_ORDER_DAY = "order_day"
export const TYPE_COL_DELETE_BUTTON = "delete_button"
export const TYPE_COL_CHART_ORDERS = "chart_orders"
export const TYPE_COL_CHART_ADV = "chart_adv"
export const TYPE_COL_CHART_WH_STOCKS = "chart_wh_stocks"
export const TYPE_COL_CHART_WH_ORDERS = "chart_wh_orders"
export const TYPE_COL_BARCHART_WH_ORDERS = "barchart_wh_orders"

export const TYPE_COL_ORDERS_BARCHART = "orders_barchart"

export const TYPE_COL_ADV_CAMPAIGN_ID = "adv_campaign_id"
export const TYPE_COL_ADV_STATUS = "adv_status"
export const TYPE_COL_ADV_OWN_PRODUCTS = "adv_own_products"
export const TYPE_COL_ADV_TYPE_NAME_SHORT = "adv_type_name_short"

export const TYPE_COL_ADV_STATS_BARCHART = "adv_stats_barchart"

// export const TG_BOT_URL = 'https://t.me/mpbuben_bot?start=start';
export const TG_BOT_NAME = 'mpbuben_bot';
export const TG_SUPPORT_NAME = 'litkevich';
export const IMAGE_LINK_PRODUCT_NOT_FOUND = "/static/img/product.png";
export const LINK_COST_PRICES_TEMPLATE = "/static/etc/import_cost_prices.xlsx";
export const SYSTEM_NAME_RUS = "МПБубен";

export const WB_ADV_REQUEST_TYPE_MANUAL = 1;
export const WB_ADV_REQUEST_TYPE_AUTO = 2;

export const WB_ADV_STRATEGY_BET_FIXED = 1;
export const WB_ADV_STRATEGY_BET_NOT_MORE = 2;
export const WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS = 3;
export const WB_ADV_STRATEGY_POS_NOT_MORE = 4;

export const WB_ADV_STRATEGY_CHOISES = [
    {id: WB_ADV_STRATEGY_BET_NOT_MORE,              name: "Удержание ставки не более макс"},
    {id: WB_ADV_STRATEGY_BET_FIXED,                 name: "Фиксированная ставка"},
    {id: WB_ADV_STRATEGY_POS_MINIMAL_BUT_NOT_LESS,  name: "Удерж наим поз, но не менее мин"},
    {id: WB_ADV_STRATEGY_POS_NOT_MORE,              name: "Удержание позиции не более макс"},
]

export const WB_ADV_STATUS_READY = 4
export const WB_ADV_STATUS_FINISHED = 7
export const WB_ADV_STATUS_SHOWN = 9
export const WB_ADV_STATUS_PAUSE = 11

export const WB_ADV_STATUS_CHOICES = [
    {id: WB_ADV_STATUS_READY,       name: "Готова к запуску"},
    {id: WB_ADV_STATUS_FINISHED,    name: "Завершена"},
    {id: WB_ADV_STATUS_SHOWN,       name: "Идут показы"},
    {id: WB_ADV_STATUS_PAUSE,       name: "На паузе"},
]

export const WB_ADV_TYPE_CATALOG = 4
export const WB_ADV_TYPE_CARD = 5
export const WB_ADV_TYPE_SEARCH = 6
export const WB_ADV_TYPE_REC = 7
export const WB_ADV_TYPE_AUTO = 8
export const WB_ADV_TYPE_SEARCH_AND_CARD = 9

export const WB_ADV_TYPE_CHOICES = [
    {id: WB_ADV_TYPE_CATALOG,   name: 'Каталог'},
    {id: WB_ADV_TYPE_CARD,      name: 'Карточка'},
    {id: WB_ADV_TYPE_SEARCH,    name: 'Поиск'},
    {id: WB_ADV_TYPE_REC,       name: 'На главной странице'},
    {id: WB_ADV_TYPE_AUTO,      name: 'Автоматическая '},
    {id: WB_ADV_TYPE_SEARCH_AND_CARD,  name: 'поиск + каталог'},
]

export const TYPE_PROBLEM_ERROR = "error"
export const TYPE_PROBLEM_WARNING = "warning"
export const TYPE_PROBLEM_INFO = "info"

export const TYPE_PROBLEM = [
    {id: TYPE_PROBLEM_ERROR, name: 'Ошибка'},
    {id: TYPE_PROBLEM_WARNING, name: 'Предупреждение'},
    {id: TYPE_PROBLEM_INFO, name: 'Информация'},
]


export function exportToExcel(data, headers, fileName='table'){
    const worksheet = XLSX.utils.json_to_sheet(data)

    if ( headers) XLSX.utils.sheet_add_aoa(worksheet, [headers])

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
}


export function getErrorSignClass(error_type){
    if (!error_type) return ""
    if (error_type == TYPE_PROBLEM_ERROR) return "bi bi-exclamation-circle text-danger"
    if (error_type == TYPE_PROBLEM_WARNING) return "bi bi-exclamation-triangle text-warning"
    if (error_type == TYPE_PROBLEM_INFO) return "bi bi-envelope-exclamation text-info"
}

export function defaultFotoLink(){
  return IMAGE_LINK_PRODUCT_NOT_FOUND
}

export function setDefaultFotoLink(advert){
  advert.foto_link = defaultFotoLink()
}


export function sortList(sortBy, thisObject, arrTable) {
    thisObject.sortBy = sortBy;

    if (thisObject.sortedbyASC) {
        arrTable.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        thisObject.sortedbyASC = false;
    } else {
        arrTable.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        thisObject.sortedbyASC = true;
    }
}


export function getIconSortClass(columnnName, sortBy, sortedbyASC){
    let res_class = ""

    if (columnnName == sortBy) {
        res_class = "float-end bi "

        if (sortedbyASC) res_class += "bi-sort-up"
        else res_class += "bi-sort-down"
    }

    return res_class
}


export function getActualityCampaignStr(adv_campaign){
    if (!adv_campaign.campaign_actuality_date) return "" // <Данные не снимались>

    let res = "Актуальность: \n" + prepareDate(adv_campaign.campaign_actuality_date, 'DD.MM.YY, hh:mm')
    return res
}

export function getActualityBetStr(adv_campaign){
    if (!adv_campaign.bet_actuality_date) return ""

    let res = "Актуальность: \n" + prepareDate(adv_campaign.bet_actuality_date, 'DD.MM.YY, hh:mm')
    return res
}


export function getDaysArray(dateStart, dateEnd) {
    let start = new Date(dateStart);
    let end = new Date(dateEnd);

    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(dt.toISOString().split('T')[0]);
    }
    return arr;
}


export function showSpinner(thisObject) {
    thisObject.divSpinner = new Modal(document.getElementById('divSpinner'))
    thisObject.divSpinner.show()
}

export function hideSpinner(thisObject) {
    thisObject.divSpinner.hide()
}


export function normalizePhoneNumber(phoneNumber){

    if (!phoneNumber){
        return phoneNumber;
    }

    if (phoneNumber[0] == 7) phoneNumber = "+" + phoneNumber;
    if (phoneNumber[0] == 9) phoneNumber = "+7" + phoneNumber;
    if (phoneNumber[0] == 8) phoneNumber = "+7" + phoneNumber.substring(1)

    return phoneNumber
}

export function showMessageResult(res, this_obj){
    this_obj.messageResult = res.messageResult;
    this_obj.gotError = res.gotError;
    return;
}


export async function fetchDrf(urlInternal="", method="GET", jsonBody={}, successMessage = "", successStatus=undefined, fullPath=false, needAuth=true  ){ // default
    // let DOMAIN_API = "http://127.0.0.1:8000"
    // let DOMAIN_API =  "http://80.90.185.32"

    let DOMAIN_API = ""   // Используем текущий серверный IP

    let urlDomain = `${DOMAIN_API}/api/v1`

    let file_uploaded = undefined

    try{
        file_uploaded = jsonBody.get('file_uploaded')
    } 
    catch (e) {
        
    }


    if (fullPath) urlDomain = `${DOMAIN_API}`

    let res = {}
    res.messageResult = ""
    res.gotError = false
    res.status = 0
    res.data = []

    if (!successStatus){
        if (method == "GET") successStatus = 200;  // ругается на method == "GET"
        else if (method == "POST") successStatus = 201;
        else if (method == "PUT") successStatus = 200;
        else if (method == "DELETE") successStatus = 204;
    }

    // Сообщения по умолчанию
    if(!successMessage) {
        if (method == "GET") successMessage = "";
        else if (method == "POST") successMessage = "Успешно создали";
        else if (method == "PUT") successMessage = "Успешно обновили";
        else if (method == "DELETE") successMessage = "Успешно удалили";
    }

    let auth_token = localStorage.getItem("auth_token");

    if (!auth_token && needAuth){
        res.gotError = true
        res.messageResult = "auth_token не найден. Авторизуйтесь на сайте"
        return res;
        // Вместо этого лучше сделать редирект!
        // this.$router.push({name: 'WbLkList'})
    }

    let response = {}
    // let responseCopy = {}

    let jsonRequest = {}
    jsonRequest.method = method


    jsonRequest.headers = {}
    if (needAuth) jsonRequest.headers['Authorization'] = `Token ${auth_token}`
    if (!file_uploaded) jsonRequest.headers['Content-Type'] = 'application/json'

    if (method != "GET"){
        if (file_uploaded) {
            jsonRequest.body =  jsonBody
        }
        else jsonRequest.body = JSON.stringify(jsonBody)
    }

    try{
        response = await fetch(urlDomain + urlInternal, jsonRequest)

        // console.log("fetchDrf") *
        // console.log(urlDomain + urlInternal)

        // responseCopy = Object.assign(responseCopy, response);
        // const returnedTarget = Object.assign(target, source);
    }
    catch (e) {
        console.log(`fetchDrf: ошибка ${e}`)
        return res
    }


    if (response.ok) { // status === successStatus
        let data = {}
        try{
           data = await response.json()
        }
        catch (e) {
            console.log(`Ошибка: ${e}`)
        }

        if (data["message"]) res.messageResult = successMessage + "; "+ data["message"]
        else res.messageResult = successMessage;

        res.gotError = false
        res.data = data;
        res.status = response.status
    }
    else{
        res.gotError = true

        let dataText = ""

        try{
            dataText = await response.text();
        }
        catch (e) {
            console.log(`Ошибка response.text() ${e}`)
            console.log(`auth_token: ${auth_token}`)

            res.messageResult = `Ошибка ${response.status} ${response.statusText}`
            res.gotError = true
            return res
        }

        let data = ""

        try {
            data = JSON.parse(dataText);
        }
        catch (e) {
            console.log(`Ошибка JSON.parse ${e}`)
            console.log(`auth_token: ${auth_token}`)

            console.log('выводим окно')
            const newWindow = window.open();
            newWindow.document.write(dataText);

            res.messageResult = `Ошибка ${response.status} ${response.statusText}`
            res.gotError = true
            return res
        }

        if (data["message"]){
          res.messageResult = res.messageResult + "; "+ data["message"];
          res.gotError = true;
          return res
        }

        for (var key in data) {
            if (typeof(data[key]) == "string") res.messageResult += (key + ": " + data[key] + "\n\n");
            else res.messageResult += (key + ": " + data[key][0] + "\n\n");
        }
    }

    return res;
}


export function getWbRReportUrlById(wbId){
    if (!wbId) return ""

    return `https://seller.wildberries.ru/suppliers-mutual-settlements/reports-implementations/reports-weekly/report/${wbId}`
}


export function getWbUrlByNmId(nmId){
    if (!nmId) return ""

    return `https://www.wildberries.ru/catalog/${nmId}/detail.aspx`
}

export function getWbAdvCampaignUrl(campaignType, campaignWbId){ //adv_campaign
    let url = ""

    if (campaignType == WB_ADV_TYPE_CARD){ // В карточке товара
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/carousel-auction/${campaignWbId}`
    }
    else if (campaignType == WB_ADV_TYPE_AUTO){ // Автоматическая
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/auto/${campaignWbId}`
    }
    else{ // В поиске
        url = `https://cmp.wildberries.ru/campaigns/list/all/edit/search/${campaignWbId}`
    }

    return url
}


export function getAdvStatusBadgeClass(campaignStatus){
    if (campaignStatus == WB_ADV_STATUS_FINISHED) return "bg-danger" // Завершена
    if (campaignStatus == WB_ADV_STATUS_SHOWN) return "bg-success" // Идут показы
    if (campaignStatus == WB_ADV_STATUS_PAUSE) return "bg-warning" // На паузе

    return ""
}


export function getAdvStrategyClass(adv_campaign){
    if (adv_campaign.strategy == 1) return "text-primary"
    else if (adv_campaign.strategy == 2) return "text-warning"
    else return "text-info" // На паузе
}


export function prepareNumber(value, replacement = "", formatNumber=true, maximumFractionDigits=0, replacementNull="-") {
    if (value === null) return replacementNull //
    else if (!value)  return replacement

    if (formatNumber)
    {
        if (maximumFractionDigits != undefined) return new Intl.NumberFormat("ru", { maximumFractionDigits: maximumFractionDigits, }).format(value)
        else                       return new Intl.NumberFormat("ru").format(value)
    }
    else return value
}


export function prepareDate(value, format) {
    // Если формат не задан - формат по умолчанию
    if (!format) format = 'DD.MM.YYYY'

    if (!value) return ""

    if (!moment(value).isValid()) {
        return ""
    }
    else {
        if (moment(value).year() >= 2100) return ""
        return moment(value).format(format) //
    }
}


export function prepareCell(value, type, replacement=0){
    if (type == "int") {
        return prepareNumber(value, replacement, true, 0)
    }
    else if (type == "float") {
        return prepareNumber(value, replacement, true, 2)
    }
    else if (type == "date"){
        return prepareDate(value)
    }

    else {
        return value
    }
}

export async function makeAdvBetsRequestAndFollow(keyword, bCollectCompetitorsDetails=false) {
    let jsonBody = {
        keyword: keyword,
        bCollectCompetitorsDetails: bCollectCompetitorsDetails,
    }

    let res = await fetchDrf(`/wbadv-bets-request/`,
        "POST",
        jsonBody)

        console.log("res.messageResult")
        console.log(res.messageResult)
        console.log(res.gotError)

    if (!res.gotError) {
        // создаем новую запись. можно даже заодно редиректнуть на нее

        console.log("ПЕРЕХОДИМ!")

        this.$router.push({name: 'WbAdvBetsRequestEdit', params: {id: res.data.new_request_id}}); // id

    }
    else {
        console.log(res.messageResult)
        // this.messageResult = res.messageResult;
        // this.gotError = res.gotError;
    }
}

export function showToast(thisObject, toastMessage, gotError, delay) {
  console.log("showToast...")

  thisObject.toastMessage = toastMessage
  thisObject.toastIsShowed = true

  if (!delay) delay = thisObject.toastDelay

  setTimeout(() => {
    thisObject.toastIsShowed = false
    console.log("showToast false")

  }, delay)
}


export function sortAssocByValue(obj, reverse=false)
{
    var sorted = Object.keys(obj).sort(function(a, b) {
        if (obj[a] == obj[b]) {
            return 0;
        }
        return (obj[a] < obj[b]) ? 1 : -1; // -1 : 1
    });

    var result = [];
    for (var i = 0; i < sorted.length; i++) {
        result[sorted[i]] = obj[sorted[i]];
    }

    if (reverse){
        result = result.reverse()
    }

    return result;
}

