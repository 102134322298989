<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-12">
        <img src="../assets/logo.png" height="60" >
        <h1>МПБубен</h1>
      </div>
      <div class="col-auto">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Инструмент аналитики для продавцов на Wildberries</p>
        <p>Анализирует еженедельные финансовые отчеты Wildberries и делает многое другое </p>
        <router-link :to="{ name: 'UserEnter'}" class="btn btn-primary my-2">Начать</router-link>

      </div>
    </div>
  </div>




<!--    <h1>Heroes of Marketing and Marketplaces</h1>-->





</template>

<script>
import {TG_SUPPORT_NAME} from "@/common";

export default {
  name: "PageHome",

  data() {
    return {
      username: "",
      password: "",
      messageResult: "",
      gotError: false,
      LOC_TG_SUPPORT_NAME: ""
    }
  },

  created() {
    this.LOC_TG_SUPPORT_NAME = TG_SUPPORT_NAME;
  },

}
</script>

<style scoped>

</style>