<template>

  <Bar
    id="my-chart-id"
    :options="getChartOptions"
    :data="chartData"
  />

</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import {prepareDate, prepareNumber} from "@/common";

export default {
  name: "BarChartUniversal",
  components: { Bar },

  props:{
    chartData: Object,
    withYAxis: Boolean,
  },

  data() {
    return {
    }
  },

  emits: [],

  computed:{
    getChartOptions(){
      let layoutPadding = -10
      let ticksDisplay = false

      if (this.withYAxis) {
        layoutPadding = 0
        ticksDisplay = true
      }

      return  {

        onClick: (event, elements) => {
          var index = undefined

          if (elements[0]) index = elements[0]["index"]

          var item = this.chartData["item"]

          this.$emit('click-barchart', index, item); // , index
        },

        layout: {
            padding: layoutPadding,
        },

        maintainAspectRatio: false,
        aspectRatio: 1,

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,

            titleFont:{
              weight: 'normal',
            },

            callbacks: {
              footer: (context) => {
                let sDate = prepareDate(context[0].label)

                let curLabel = context[0].dataset.label
                let curValue = context[0].raw
                let stacks = context[0]["parsed"]["_stacks"]["y"]["_visualValues"]

                let totalValue = 0

                let quantityStacks = 0

                for (let key in stacks){
                  totalValue += stacks[key]
                  quantityStacks += 1
                }

                totalValue = prepareNumber(totalValue)
                curValue = prepareNumber(curValue)

                let res = []

                res.push(`${sDate}: ${totalValue}`)

                return res
              },

              title: (context) => {
                return "";
              },

              // label: (context) => {
              //   return ""
              // },

              // labelPointStyle: function(context) {
              //     return {
              //         pointStyle: false,
              //     };
              // },
            }
          },
        },

        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            padding: 0,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: false
            }

          },
          y: {
            stacked: true,
            padding: 0,
            beginAtZero: true,

            border: {
              display: false
            },

            grid: {
              display: false
            },

            title: {
              display: false
            },

            ticks: {
              display: ticksDisplay
            }
          }
        }
      }

    },

  },

  created() {
  },

  methods:{
  }
}
</script>

<style scoped>

</style>